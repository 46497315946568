/**
 * @Desc Wrapper for all saga API calls for user management functionality.
 */

import { call, put, takeLatest } from "redux-saga/effects";
import { userConstants } from "../_constants/user.constant";
import { userApi } from "../_apiCall/user.api";
import { request } from "../_helpers/request";
import { toaster } from "../_common/toaster/toaster";
import { exceptionHandlerService } from "../_helpers/exceptionHandler";

export function* userSaga() {
  yield takeLatest(userConstants.GET_USER_LIST, getUserList);
  yield takeLatest(userConstants.GET_USER_DETAILS, getUserData);
  yield takeLatest(userConstants.CHANGE_USER_STATUS, changeUserStatus);
  yield takeLatest(userConstants.GET_USER_ROLE_LIST, getUserRoleList);
  yield takeLatest(userConstants.GET_UM_CLIENT_LIST, getClientList);
  yield takeLatest(userConstants.GET_UM_LOCATION_LIST, getLocationList);
  yield takeLatest(userConstants.TOGGLE_USER_STATUS, toggleUserStatus);
  yield takeLatest(userConstants.CREATE_USER, createUser);
  yield takeLatest(userConstants.UPDATE_USER, updateUser);
  yield takeLatest(userConstants.UPLOAD_IMAGE_TEMP, uploadImage);
  yield takeLatest(userConstants.CHANGE_PASSWORD, changePassword);
  yield takeLatest(
    userConstants.FORGOT_PASSWORD_SEND_EMAIL,
    forgotPasswordSendEmail
  );
  yield takeLatest(
    userConstants.FORGOT_PASSWORD_VERIFY_TOKEN,
    forgotPasswordTokenVerify
  );
  yield takeLatest(
    userConstants.FORGOT_PASSWORD_UPDATE_PASSWORD,
    forgotPasswordPasswordUpdate
  );
  yield takeLatest(userConstants.GET_CHANGE_ROLE_LIST, getChangeRoleListSaga);
}

/**
 * @Desc Wrapper for all saga function call with the common functionality being defined here.
 */
/* for all request flag= true */
function* sagaFunc(api, action, failureConstant, successConstant, flag, msg) {
  yield put(request.isFetching(userConstants.REQUEST));
  try {
    const { response, error } =
      flag === true ? yield call(api, action.payload) : yield call(api);
    if (response !== undefined) {
      if (response.success === true) {
        yield put(request.success(successConstant, response));
      } else if (response.success === false) {
        toaster.notifyError(msg);
        yield put(request.failure(failureConstant, msg));
      }
    } else if (error) {
      exceptionHandlerService.exceptionService(error, msg);
      yield put(request.failure(failureConstant, error));
    }
  } catch (error) {
    exceptionHandlerService.exceptionService(error, msg);
    yield put(request.failure(failureConstant, error.toString()));
  }
}

// worker Saga: will be fired on GET_Search_Devices actions
function* getUserList(action) {
  const api = userApi.getUserList;
  const failureConstant = userConstants.GET_USER_LIST_FAILURE;
  const successConstant = userConstants.GET_USER_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get user list. Please try again.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getUserData(action) {
  const api = userApi.getUserDetails;
  const failureConstant = userConstants.GET_USER_DETAILS_FAILURE;
  const successConstant = userConstants.GET_USER_DETAILS_SUCCESS;
  const flag = true;
  const msg = "unable to get user data. Please try again.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* changeUserStatus(action) {
  const api = userApi.changeUserStatus;
  const failureConstant = userConstants.CHANGE_USER_STATUS_FAILURE;
  const successConstant = userConstants.CHANGE_USER_STATUS_SUCCESS;
  const flag = true;
  const msg = "unable to change user status. Please try again.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getUserRoleList(action) {
  const api = userApi.getUserRoleList;
  const failureConstant = userConstants.GET_USER_ROLE_LIST_FAILURE;
  const successConstant = userConstants.GET_USER_ROLE_LIST_SUCCESS;
  const flag = false;
  const msg = "unable to get user role list. Please try again.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getClientList(action) {
  const api = userApi.getClientList;
  const failureConstant = userConstants.GET_UM_CLIENT_LIST_FAILURE;
  const successConstant = userConstants.GET_UM_CLIENT_LIST_SUCCESS;
  const flag = false;
  const msg = "unable to get client list";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getLocationList(action) {
  const api = userApi.getLocationList;
  const failureConstant = userConstants.GET_UM_LOCATION_LIST_FAILURE;
  const successConstant = userConstants.GET_UM_LOCATION_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get location list";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* toggleUserStatus(action) {
  const api = userApi.toggleUserStatus;
  const failureConstant = userConstants.TOGGLE_USER_STATUS_FAILURE;
  const successConstant = userConstants.TOGGLE_USER_STATUS_SUCCESS;
  const flag = true;
  const msg = "unable to toggle user status. Please try again.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* createUser(action) {
  const api = userApi.createUserApi;
  const failureConstant = userConstants.CREATE_USER_FAILURE;
  const successConstant = userConstants.CREATE_USER_SUCCESS;
  const flag = true;
  const msg = "unable to create user. Please try again.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* updateUser(action) {
  const api = userApi.updateUserApi;
  const failureConstant = userConstants.UPDATE_USER_FAILURE;
  const successConstant = userConstants.UPDATE_USER_SUCCESS;
  const flag = true;
  const msg = "unable to update user. Please try again.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* uploadImage(action) {
  const api = userApi.uploadImageApi;
  const failureConstant = userConstants.UPLOAD_IMAGE_TEMP_FAILURE;
  const successConstant = userConstants.UPLOAD_IMAGE_TEMP_SUCCESS;
  const flag = true;
  const msg = "unable to upload user profile image. Please try again.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* changePassword(action) {
  const api = userApi.changePasswordApi;
  const failureConstant = userConstants.CHANGE_PASSWORD_FAILURE;
  const successConstant = userConstants.CHANGE_PASSWORD_SUCCESS;
  const flag = true;
  const msg = "unable to update password. Please try again.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* forgotPasswordSendEmail(action) {
  const api = userApi.forgotPasswordSendEmailRequest;
  const failureConstant = userConstants.FORGOT_PASSWORD_SEND_EMAIL_FAILURE;
  const successConstant = userConstants.FORGOT_PASSWORD_SEND_EMAIL_SUCCESS;
  const flag = true;
  const msg = "unable to send mail. Please try again.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* forgotPasswordTokenVerify(action) {
  const api = userApi.forgotPasswordVerifyTokenRequest;
  const failureConstant = userConstants.FORGOT_PASSWORD_VERIFY_TOKEN_FAILURE;
  const successConstant = userConstants.FORGOT_PASSWORD_VERIFY_TOKEN_SUCCESS;
  const flag = true;
  const msg = "unable to verify token. Please try again.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* forgotPasswordPasswordUpdate(action) {
  const api = userApi.forgotPasswordUpdatePasswordRequest;
  const failureConstant = userConstants.FORGOT_PASSWORD_UPDATE_PASSWORD_FAILURE;
  const successConstant = userConstants.FORGOT_PASSWORD_UPDATE_PASSWORD_SUCCESS;
  const flag = true;
  const msg = "unable to update password. Please try again.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getChangeRoleListSaga(action) {
  const api = userApi.getChangeRoleListApi;
  const failureConstant = userConstants.GET_CHANGE_ROLE_LIST_FAILURE;
  const successConstant = userConstants.GET_CHANGE_ROLE_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get user role list. Please try again.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}
