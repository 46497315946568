/**
 * @Desc Wrapper for all saga API calls for user management functionality.
 */

import { call, put, takeLatest } from "redux-saga/effects";
import { emailNotificationConstants } from "../_constants/email_notification.constant";
import { emailNotificationApi } from "../_apiCall/email_notification.api";
import { request } from "../_helpers/request";
import { toaster } from "../_common/toaster/toaster";
import { exceptionHandlerService } from "../_helpers/exceptionHandler";

export function* emailNotificationSaga() {
  yield takeLatest(emailNotificationConstants.GET_EMAIL_NOTIFICATION_USER_LIST, getEmailNotificationUserList);
  yield takeLatest(emailNotificationConstants.TOGGLE_EMAIL_NOTIFICATION_STATUS, toggleEmailNotificationStatus);
  yield takeLatest(emailNotificationConstants.GET_EMAIL_NOTIFICATION_CLIENT_LIST, getEmailNotificationClientList);
  yield takeLatest(emailNotificationConstants.GET_EMAIL_NOTIFICATION_STATUS_LIST, getEmailNotificationStatusList);
}

/**
 * @Desc Wrapper for all saga function call with the common functionality being defined here.
 */
/* for all request flag= true */
function* sagaFunc(api, action, failureConstant, successConstant, flag, msg) {
  console.log("api success values",api,action,failureConstant,successConstant,msg);
  yield put(request.isFetching(emailNotificationConstants.REQUEST));
  try {
    const { response, error } =
      flag === true ? yield call(api, action.payload) : yield call(api);
    if (response !== undefined) {
      if (response.success === true) {
        yield put(request.success(successConstant, response));
      } else if (response.success === false) {
        toaster.notifyError(msg);
        yield put(request.failure(failureConstant, msg));
      }
    } else if (error) {
      exceptionHandlerService.exceptionService(error, msg);
      yield put(request.failure(failureConstant, error));
    }
  } catch (error) {
    exceptionHandlerService.exceptionService(error, msg);
    yield put(request.failure(failureConstant, error.toString()));
  }
}

// worker Saga: will be fired on GET_Email_Notification_User_List actions
function* getEmailNotificationUserList(action) {
  const api = emailNotificationApi.getEmailNotificationUserList;
  const failureConstant = emailNotificationConstants.GET_EMAIL_NOTIFICATION_USER_LIST_FAILURE;
  const successConstant = emailNotificationConstants.GET_EMAIL_NOTIFICATION_USER_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get user list. Please try again.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}


// worker Saga: will be fired on GET_Email_Notification_Client_List actions
function* getEmailNotificationClientList(action) {
  const api = emailNotificationApi.getEmailNotificationClientList;
  const failureConstant = emailNotificationConstants.GET_EMAIL_NOTIFICATION_CLIENT_LIST_FAILURE;
  const successConstant = emailNotificationConstants.GET_EMAIL_NOTIFICATION_CLIENT_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get client list. Please try again.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}


function* toggleEmailNotificationStatus(action) {
  console.log(action,"saga action inside toggle")
  const api = emailNotificationApi.toggleEmailNotificationStatus;
  console.log("toggle api inside saga",api);

  const failureConstant = emailNotificationConstants.TOGGLE_EMAIL_NOTIFICATION_STATUS_FAILURE;
  const successConstant = emailNotificationConstants.TOGGLE_EMAIL_NOTIFICATION_STATUS_SUCCESS;
  const flag = true;
  const msg = "unable to toggle user status. Please try again.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}
function* getEmailNotificationStatusList(action) {
  console.log(action,"saga action inside email notification");
  const api = emailNotificationApi.getEmailNotificationStatusList;
  console.log("api inside saga",api);
  const failureConstant = emailNotificationConstants.GET_EMAIL_NOTIFICATION_STATUS_LIST_FAILURE;
  const successConstant = emailNotificationConstants.GET_EMAIL_NOTIFICATION_STATUS_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get status list. Please try again.";
  console.log("msg unable to get status list",msg);

  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}