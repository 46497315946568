// import axios from "axios";
import {
  DashboardResources
} from "../_resources/dashboard.resources";
import {
  requestHandler
} from "../_helpers/requestHandler";

export const dashboardApi = {
  getSupplyLevelStatusList,
  getDashboardClientList,
  getDashboardLocationList,
  getLastUpdatedDateApi,
  getDeviceStatusList,
  getDeviceColorProportionList,
  getServiceStatusList,
  getMonthlyPageUsageByClientList,
  getMonthlyPageUsageByLocationList,
  getGreenReportList
};

function getSupplyLevelStatusList(data) {
  let url = DashboardResources.getSupplyLevelStatusListUrl(data).url;
  console.log("supply level api", url);

  return requestHandler.getsupplyLevelService(url);
}

function getDeviceStatusList(data) {

  let url = DashboardResources.getDeviceStatusListUrl(data).url;
  return requestHandler.getDeviceStatusService(url);
}

function getDeviceColorProportionList(data) {
  let url = DashboardResources.getDeviceColorProportionListUrl(data).url;
  return requestHandler.getDeviceColorProportionService(url);

}

function getServiceStatusList(data) {

  let url = DashboardResources.getServiceStatusListUrl(data).url;
  console.log("service url", url);
  return requestHandler.getServiceStatusService(url);
}

function getMonthlyPageUsageByClientList(data) {
  let url = DashboardResources.getMonthlyPageUsageByClientListUrl(data).url;
  return requestHandler.getMonthlyPageUsageByClientService(url);
}

function getMonthlyPageUsageByLocationList(data) {
  let url = DashboardResources.getMonthlyPageUsageByLocationListUrl(data).url;
  return requestHandler.getMonthlyPageUsageByLocationService(url);
}

function getDashboardClientList() {
  let url = DashboardResources.getDashboardClientListUrl().url;
  return requestHandler.getService(url);
}

function getDashboardLocationList(data) {
  let url = DashboardResources.getDashboardLocationListUrl().url;
  return requestHandler.postService(url, data);
}

function getLastUpdatedDateApi() {
  let url = DashboardResources.getLastUpdatedDateUrl().url;
  return requestHandler.getService(url);
}

function getGreenReportList(data) {
  console.log("green url");

  let url = DashboardResources.getGreenReportDetailsUrl().url;
  console.log("green url", url);
  return requestHandler.postService(url, data);
}