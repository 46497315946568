// import axios from "axios";
import { ClientResources } from "../_resources/client.resources";
import { requestHandler } from "../_helpers/requestHandler";

export const clientApi = {
  getClientLocationList,
  getClientDetails,
  getCMClientList,
  getCMLocationList,
  updateCMContractInfo,
  uploadClientLogoApi
};

function getClientLocationList(data) {
  let url = ClientResources.getClientLocationListUrl().url;
  return requestHandler.postService(url, data);
}

function getClientDetails(clientLocationId) {
  let url = ClientResources.getClientDetailsUrl(clientLocationId).url;
  return requestHandler.getService(url);
}

function getCMClientList() {
  let url = ClientResources.getCMClientListUrl().url;
  return requestHandler.getService(url);
}

function getCMLocationList(data) {
  let url = ClientResources.getCMLocationListUrl().url;
  return requestHandler.postService(url, data);
}

function updateCMContractInfo(data) {
  let url = ClientResources.updateCMContractInfoUrl().url;
  return requestHandler.postService(url, data);
}

function uploadClientLogoApi(data) {
  let url = ClientResources.getUploadClientLogoUrl().url;
  return requestHandler.postService(url, data);
}
