/**
 * @Desc Wrapper for storing API urls for user management.
 */

import { GlobalVariable } from "../_constants/url.constants";
const baseUrl = GlobalVariable.baseUrl;
const projectName = GlobalVariable.projectName;
const ssl = GlobalVariable.ssl;

export const UserResources = {
  getUserLoginUrl,
  getUserListUrl,
  getUserDetailsUrl,
  getChangeUserStatusUrl,
  getUserRoleListUrl,
  getClientListUrl,
  getLocationListUrl,
  getCreateUserUrl,
  getUpdateUserUrl,
  getUploadImageUrl,
  getChangePasswordUrl,
  getForgotPasswordSendEmailUrl,
  getForgotPasswordVerifyTokenUrl,
  getForgotPasswordUpdatePasswordUrl,
  getRefreshToken,
  getChangeRoleListUrl,
};

function getUserLoginUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/user/login",
    // url:"http://192.168.0.17:8090/kbt/bridge/api/v1/user/login"
  };
}

function getUserListUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/user/listAll",
  };
}

function getUserDetailsUrl(userId) {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/user/detail?userId=" +
      userId,
  };
}

function getChangeUserStatusUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/user/enable",
  };
}

function getUserRoleListUrl() {
  return {
    url: ssl + "://" + baseUrl + "/" + projectName + "/kbt/v1/api/list/roles",
  };
}

function getClientListUrl() {
  return {
    url:
      ssl + "://" + baseUrl + "/" + projectName + "/kbt/bridge/api/v1/clients",
  };
}

function getLocationListUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/locations",
  };
}

function getCreateUserUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/user/create",
  };
}

function getUpdateUserUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/user/update",
  };
}

function getUploadImageUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/upload/img/temp",
  };
}

function getChangePasswordUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/user/password/change",
  };
}

function getForgotPasswordSendEmailUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/user/password/reset",
  };
}

function getForgotPasswordVerifyTokenUrl(token) {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/user/password/reset/validate/" +
      token,
  };
}

function getForgotPasswordUpdatePasswordUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/user/password/set/new",
  };
}

function getRefreshToken() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      `/kbt/bridge/refresh/token?token=${localStorage.getItem("token")}`,
  };
}

function getChangeRoleListUrl(userId) {
  console.log("form resoures", userId);
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/v1/api/list/roles/change?userId=" +
      userId,
  };
}
