import {
    FloorResources
} from '../_resources/floor.resources';
import {
    requestHandler
} from '../_helpers/requestHandler';

export const floorApi = {
    getClientList,
    getLocationList,
    getFloorList,
    getFloorMappingDetailsApi,
    getAllocatedUnallocatedDeviceListApi,
    getDeviceModelInfoApi,
    createNewFloorApi,
    uploadFloorMapApi,
    updateDeviceAllocationListApi,
    saveDeviceMapingApi,
    floorDetailsApi,
    updateFloorDetailsApi,
    checkAvailabilityApi,
    getFloorMapDetailsToExportApi
}

function getClientList() {
    let url = FloorResources.getFMClientListUrl().url;
    return requestHandler.getService(url);
}

function getLocationList(data) {
    let url = FloorResources.getFMLocationListUrl().url;
    return requestHandler.postService(url, data)
}

function getFloorList(data) {
    let url = FloorResources.getFMFloorListUrl(data.locationId).url;
    return requestHandler.getService(url)
}

function getFloorMappingDetailsApi(data){
    let url = FloorResources.getFloorMappingDetailsUrl(data).url;
    return requestHandler.getService(url);
}

function getAllocatedUnallocatedDeviceListApi(data){
    let url = FloorResources.getAllocatedUnallocatedDeviceListUrl(data).url;
    return requestHandler.getService(url);
}

function getDeviceModelInfoApi(modelName) {
    let url = FloorResources.getDeviceModelInfoUrl(modelName).url;
    return requestHandler.getService(url);
}

function createNewFloorApi(req) {
    let url = FloorResources.getCreateNewFloorUrl().url;
    return requestHandler.postService(url, req);
}

function uploadFloorMapApi(req) {
    let url = FloorResources.getUploadFloorMapUrl().url;
    return requestHandler.postService(url, req);
}

function updateDeviceAllocationListApi(req) {
    let url = FloorResources.getUpdateDeviceAllocationList().url;
    return requestHandler.postService(url, req);
}

function saveDeviceMapingApi(req){
    let url = FloorResources.getSaveDeviceMappingUrl().url;
    return requestHandler.postService(url, req);
}

function floorDetailsApi(floorId){
    let url = FloorResources.getFloorDetailsUrl(floorId).url;
    return requestHandler.getService(url);
}

function updateFloorDetailsApi(req){
    let url = FloorResources.getUpdateFloorDetailsUrl().url;
    return requestHandler.postService(url, req);
}

function checkAvailabilityApi(req){
    let url = FloorResources.getAvailabilityUrl().url;
    return requestHandler.postService(url, req);
}

function getFloorMapDetailsToExportApi(device){
    let url = FloorResources.getFloorMapDetailsToExportUrl(device).url;
    return requestHandler.getService(url);
}