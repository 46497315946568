/**
 * @Desc Wrapper for home conatiner & all global level functionality data manipulation & pasisng it to the store.
 */

import {
    deviceConstants
} from '../_constants/device.constant';

const initialState = {
    searchDeviceList: [],
    globalDeviceData: {},
    loading: false
}

export function homeReducer(state = initialState, action) {
    switch (action.type) {
        case deviceConstants.REQUEST:
            return {
                ...state,
                loading: true
            };
        case deviceConstants.GET_DEVICE_SEARCH_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                searchDeviceList: action.payload.payload.data
            };
        case deviceConstants.GET_DEVICE_SEARCH_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        // case deviceConstants.GET_GLOBAL_CLIENT_DEVICE_DETAILS_SUCCESS:
        //     return {
        //         ...state,
        //         loading: false,
        //         globalDeviceData: action.payload.payload
        //     };
        // case deviceConstants.GET_GLOBAL_CLIENT_DEVICE_DETAILS_FAILURE:
        //     return {
        //         ...state,
        //         loading: false,
        //         error: action.error
        //     };
        case deviceConstants.RESET_DEVICE_DATA:
              return {
                  ...state,
                  globalDeviceData: {},
              };

        default:
            return state
    }
}
