import { FaqResources } from "../_resources/faq.resources";
import { requestHandler } from "../_helpers/requestHandler";

export const faqApi = {
  getFaqCategoryListApi,
  setFaqCategoryApi,
  removeFaqCategoryApi,
  updateCategoryApi,
  addFaqToCategoryApi,
  getFaqListApi,
  updateFaqApi,
  removeFaqApi,
  getAllQuestionAnswerApi,
};

function getFaqCategoryListApi() {
  let url = FaqResources.getFaqCategoryListUrl().url;
  return requestHandler.getService(url);
}

function setFaqCategoryApi(faqCategory) {
  let url = FaqResources.setFaqCategoryUrl().url;
  return requestHandler.postService(url, faqCategory);
}

function removeFaqCategoryApi(categoryId) {
  let url = FaqResources.removeFaqCategoryUrl(categoryId).url;
  return requestHandler.getService(url);
}

function updateCategoryApi(req) {
  let url = FaqResources.updateCategoryUrl().url;
  return requestHandler.postService(url, req);
}

function addFaqToCategoryApi(req) {
  let url = FaqResources.addFaqToCategoryUrl().url;
  return requestHandler.postService(url, req);
}

function getFaqListApi(categoryId) {
  let url = FaqResources.getFaqListUrl(categoryId).url;
  return requestHandler.getService(url);
}

function updateFaqApi(req) {
  let url = FaqResources.updateFaqUrl().url;
  return requestHandler.postService(url, req);
}

function removeFaqApi(faqId) {
  let url = FaqResources.removeFaqUrl(faqId).url;
  return requestHandler.getService(url);
}

function getAllQuestionAnswerApi() {
  let url = FaqResources.getAllQuestionAnswerUrl().url;
  return requestHandler.getService(url);
}
