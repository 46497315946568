/**
 * @Desc Wrapper for site view & device management data manipulation & pasisng it to the store.
 */

import {
    emailNotificationConstants
} from "../_constants/email_notification.constant";

let initialState = {
    clientTotalPages: 0,
    userTotalPages: 0,
    userList: [],
    clientList: [],
    enableStatus: undefined,
};

export function emailNotificationReducer(state = initialState, action) {
    switch (action.type) {
        case emailNotificationConstants.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case emailNotificationConstants.GET_EMAIL_NOTIFICATION_USER_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                    enableStatus: undefined,

                    userTotalPages: action.payload.payload.totalPages,
                    userList: action.payload.payload.user,
            };
        case emailNotificationConstants.GET_EMAIL_NOTIFICATION_USER_LIST_FAILURE:
            return {
                ...state,
                loading: false,

                    error: action.error,
            };
        case emailNotificationConstants.GET_EMAIL_NOTIFICATION_CLIENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                    clientTotalPages: action.payload.payload.totalPages,
                    enableStatus: undefined,

                    clientList: action.payload.payload.client,
            };
        case emailNotificationConstants.GET_EMAIL_NOTIFICATION_CLIENT_LIST_FAILURE:
            return {
                ...state,
                loading: false,

                    error: action.error,
            };
        case emailNotificationConstants.TOGGLE_EMAIL_NOTIFICATION_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,

                    // enableStatus: action.payload,
            };
        case emailNotificationConstants.TOGGLE_EMAIL_NOTIFICATION_STATUS_FAILURE:
            return {
                ...state,
                loading: false,

                    error: action.error
            };
        case emailNotificationConstants.UNMOUNT_EMAIL_NOTIFICATION_USER_LIST:
            return {
                ...state,
                enableStatus: undefined,

                    userList: undefined
            };
        case emailNotificationConstants.UNMOUNT_EMAIL_NOTIFICATION_CLIENT_LIST:
            return {
                ...state,
                enableStatus: undefined,

                    clientList: undefined
            };
            case emailNotificationConstants.GET_EMAIL_NOTIFICATION_STATUS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                    enableStatus: action.payload.payload,

            };
        case emailNotificationConstants.GET_EMAIL_NOTIFICATION_STATUS_LIST_FAILURE:
            return {
                ...state,
                loading: false,

                    error: action.error,
            };
        default:
            return state;
    }
}