export const faqConstants = {
  REQUEST: "DEVICE_REQUEST",
  SUCCESS: "DEVICE_SUCCESS",
  FAILURE: "DEVICE_FAILURE",

  UNMOUNT_FAQ_LIST: "UNMOUNT_FAQ_LIST",

  GET_FAQ_CATEGORY_LIST: "GET_FAQ_CATEGORY_LIST",
  GET_FAQ_CATEGORY_LIST_SUCCESS: "GET_FAQ_CATEGORY_LIST_SUCCESS",
  GET_FAQ_CATEGORY_LIST_FAILURE: "GET_FAQ_CATEGORY_LIST_FAILURE",

  SET_FAQ_CATEGORY: "SET_FAQ_CATEGORY",
  SET_FAQ_CATEGORY_FAILURE: "SET_FAQ_CATEGORY_FAILURE",
  SET_FAQ_CATEGORY_SUCCESS: "SET_FAQ_CATEGORY_SUCCESS",

  REMOVE_FAQ_CATEGORY: "REMOVE_FAQ_CATEGORY",
  REMOVE_FAQ_CATEGORY_FAILURE: "REMOVE_FAQ_CATEGORY_FAILURE",
  REMOVE_FAQ_CATEGORY_SUCCESS: "REMOVE_FAQ_CATEGORY_SUCCESS",

  UPDATE_FAQ_CATEGORY: "UPDATE_FAQ_CATEGORY",
  UPDATE_FAQ_CATEGORY_FAILURE: "UPDATE_FAQ_CATEGORY_FAILURE",
  UPDATE_FAQ_CATEGORY_SUCCESS: "UPDATE_FAQ_CATEGORY_SUCCESS",

  ADD_FAQ_TO_CATEGORY: "ADD_FAQ_TO_CATEGORY",
  ADD_FAQ_TO_CATEGORY_FAILURE: "ADD_FAQ_TO_CATEGORY_FAILURE",
  ADD_FAQ_TO_CATEGORY_SUCCESS: "ADD_FAQ_TO_CATEGORY_SUCCESS",

  GET_FAQ_LIST: "GET_FAQ_LIST",
  GET_FAQ_LIST_FAILURE: "GET_FAQ_LIST_FAILURE",
  GET_FAQ_LIST_SUCCESS: "GET_FAQ_LIST_SUCCESS",

  UPDATE_FAQ: "UPDATE_FAQ",
  UPDATE_FAQ_FAILURE: "UPDATE_FAQ_FAILURE",
  UPDATE_FAQ_SUCCESS: "UPDATE_FAQ_SUCCESS",

  REMOVE_FAQ: "REMOVE_FAQ",
  REMOVE_FAQ_FAILURE: "REMOVE_FAQ_FAILURE",
  REMOVE_FAQ_SUCCESS: "REMOVE_FAQ_SUCCESS",

  GET_ALL_QUESTION_ANSWER: "GET_ALL_QUESTION_ANSWER",
  GET_ALL_QUESTION_ANSWER_FAILURE: "GET_ALL_QUESTION_ANSWER_FAILURE",
  GET_ALL_QUESTION_ANSWER_SUCCESS: "GET_ALL_QUESTION_ANSWER_SUCCESS",
};
