import { GlobalVariable } from "../_constants/url.constants";

const baseUrl = GlobalVariable.baseUrl;
const projectName = GlobalVariable.projectName;
const ssl = GlobalVariable.ssl;

export const FaqResources = {
  getFaqCategoryListUrl,
  setFaqCategoryUrl,
  removeFaqCategoryUrl,
  updateCategoryUrl,

  addFaqToCategoryUrl,
  getFaqListUrl,
  updateFaqUrl,
  removeFaqUrl,
  getAllQuestionAnswerUrl,
};

function getFaqCategoryListUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/faq/category/list",
  };
}

function setFaqCategoryUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/faq/category/save",
  };
}

function removeFaqCategoryUrl(categoryId) {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/faq/category/delete?categoryId=" +
      categoryId,
  };
}

function updateCategoryUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/faq/category/update",
  };
}

function addFaqToCategoryUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/faq/question/save",
  };
}

function getFaqListUrl(categoryId) {
  console.log("from resource", categoryId);
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/faq/question/list?categoryId=" +
      categoryId,
  };
}

function updateFaqUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/faq/question/update",
  };
}

function removeFaqUrl(faqId) {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/faq/question/delete?faqId=" +
      faqId,
  };
}

function getAllQuestionAnswerUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/faq/question/list/all",
  };
}
