/**
 * @Desc Wrapper for storing the API urls for world view.
 */

import {
    GlobalVariable
} from "../_constants/url.constants";
const
    baseUrl = GlobalVariable.baseUrl;
const projectName =
    GlobalVariable.projectName;
const ssl = GlobalVariable.ssl;

export const WorldViewResources = {
    getClientGeoJSONListUrl,
    getClientLocationMachinesUrl,
    getClientListUrl,
    getLocationListUrl
};

function getClientGeoJSONListUrl() {
    return {
        url: ssl + "://" + baseUrl + "/" + projectName + "/kbt/bridge/clients/locations/geocode"
        // url: "http://demo7866882.mockable.io/get_geocodes"
    }
}

function getClientLocationMachinesUrl(locationId, clientId) {
    return {
        url: ssl + "://" + baseUrl + "/" + projectName + "/kbt/bridge/clients/locations/map/info?locationId="+ locationId+ "&clientId="+clientId
    }
}

function getClientListUrl() {
    return {
        url: ssl + "://" + baseUrl + "/" + projectName + "/kbt/bridge/api/v1/clients"
    };
}

function getLocationListUrl() {
    return {
        url: ssl + "://" + baseUrl + "/" + projectName + "/kbt/bridge/api/v1/locations"
    };
}