/**
 * @Desc Wrapper for API call intercpetor. Used to intercept request & response being made and perform any action before being forward to server or being handled by saga.
 *
 * Used to create entry and exit point for all API calls.
 */

import axios from 'axios';
import {
    GlobalVariable
} from '../_constants/url.constants';
import {
    SecurityService
} from '../_helpers/security';
import { SessionService } from '../_helpers/session';

/**
 *
 * parse error response
 */
function parseError(error) {
    // error
    if (error) {
        if (error.response === undefined) {
            return Promise.reject(error)
        } else {
            return Promise.reject(error.response)
        }
    }
}

/**
 * parse response
 */
function parseBody(response) {
    // use custom status code
    return response
}

/**
 * axios instance
 */
let instance = axios.create();

// request header
instance
    .interceptors
    .request
    .use((config) => {
        // Do something before request is sent api token などを利用して headerに載せる場合 const
        // apiToken = sessionStorage.getItem('token') config.headers = {
        // 'Custom-Header-IF-Exist': apiToken }

        let token = localStorage.getItem('token');
        let isLoggedIn = SecurityService.get('isLoggedIn');
        let api_key = GlobalVariable.apiKey;

        if (token !== null && isLoggedIn !== null) {
            SessionService.refreshToken();

            config.headers = {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
                'x-api-key': api_key,
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true'
            }
            return config;
        }else if (token === null && isLoggedIn === null) {
            SessionService.refreshToken();
 
            config.headers = {
                'Content-Type': 'application/json',
                'x-api-key': api_key,
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true'
            }

            return config
        }else{ 
            return config;
        }
    }, error => {
        return Promise.reject(error)
    });

// response parse
instance
    .interceptors
    .response
    .use((response) => {
        return parseBody(response);
    }, error => {
        return parseError(error);
    });


/**
 * return the instance of axios being created using the interceptor.
 */
export const http = instance;
