/**
 * @Desc Wrapper for storing the API urls for client management.
 */

import { GlobalVariable } from "../_constants/url.constants";
const baseUrl = GlobalVariable.baseUrl;
const projectName = GlobalVariable.projectName;
const ssl = GlobalVariable.ssl;

export const ClientResources = {
  getClientLocationListUrl,
  getClientDetailsUrl,
  getCMClientListUrl,
  getCMLocationListUrl,
  updateCMContractInfoUrl,
  getUploadClientLogoUrl
};

function getClientLocationListUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/clients/locations"
    // url: "http://www.mocky.io/v2/5c0a390c350000d11ea85fda"
  };
}

function getClientDetailsUrl(clientLocationId) {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/clients/information?locationId=" +
      clientLocationId
    // url: "http://www.mocky.io/v2/5c0a41813500004d00a8601d?locationId=" + clientLocationId
  };
}

function getCMClientListUrl() {
  return {
    url:
      ssl + "://" + baseUrl + "/" + projectName + "/kbt/bridge/api/v1/clients"
    // url: 'http://www.mocky.io/v2/5c068fc93300007600ef2af5'
  };
}

function getCMLocationListUrl() {
  return {
    url:
      ssl + "://" + baseUrl + "/" + projectName + "/kbt/bridge/api/v1/locations"
    // url: 'http://www.mocky.io/v2/5c00f3933200002b00b28918'
  };
}

function updateCMContractInfoUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/clients/locations/contract"
  };
}

function getUploadClientLogoUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/upload/img/temp"
  };
}
