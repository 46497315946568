/**
 * @Desc Saga to make all API calls related to the dashboard.
 */

import {
  call,
  put,
  takeLatest
} from "redux-saga/effects";
import {
  dashboardConstants
} from "../_constants/dashboard.constant";

import {
  dashboardApi
} from "../_apiCall/dashboard.api";

import {
  request
} from "../_helpers/request";
import {
  toaster
} from "../_common/toaster/toaster";
import {
  exceptionHandlerService
} from "../_helpers/exceptionHandler";

export function* dashboardSaga() {
  yield takeLatest(
    dashboardConstants.GET_SUPPLY_LEVEL_STATUS_LIST,
    getSupplyLevelStatusList
  );
  yield takeLatest(
    dashboardConstants.GET_DEVICE_STATUS_LIST,
    getDeviceStatusList
  );
  yield takeLatest(
    dashboardConstants.GET_MONTHLY_PAGE_USAGE_BY_CLIENT_LIST,
    getMonthlyPageUsageByClientList
  );
  yield takeLatest(
    dashboardConstants.GET_SERVICE_STATUS_LIST,
    getServiceStatusList
  );

  yield takeLatest(
    dashboardConstants.GET_MONTHLY_PAGE_USAGE_BY_LOCATION_LIST,
    getMonthlyPageUsageByLocationList
  );
  yield takeLatest(
    dashboardConstants.GET_DEVICE_COLOR_PROPORTION_LIST,
    getDeviceColorProportionList
  );
  yield takeLatest(
    dashboardConstants.GET_DASHBOARD_CLIENT_LIST,
    getDashboardClientList
  );
  yield takeLatest(
    dashboardConstants.GET_DASHBOARD_LOCATION_LIST,
    getDashbaordLocationList
  );
  yield takeLatest(
    dashboardConstants.GET_LAST_UPDATED_DATE,
    getLastUpdatedDateSaga
  );
  yield takeLatest(dashboardConstants.GET_GREEN_REPORT_LIST, getGreenReportList);


}

/**
 * @Desc Wrapper for all saga function call with the common functionality being defined here.
 */
function* sagaFunc(api, action, failureConstant, successConstant, flag, msg) {
  yield put(request.isFetching(dashboardConstants.REQUEST));
  try {
    const {
      response,
      error
    } =
    flag === true ? yield call(api, action.payload): yield call(api);
    console.log("action", action);
    console.log("response", response);
    if (response !== undefined) {
      if (response.success === true || response.status === 200) {
        console.log("if condition true", response);
        yield put(request.success(successConstant, response));
      } else if (response.success === false) {
        //console.log("if condition false");

        toaster.notifyError(msg);
        yield put(request.failure(failureConstant, msg));
      }
    } else if (error) {
      console.log("error");
      exceptionHandlerService.exceptionService(error, msg);
      yield put(request.failure(failureConstant, error));
    }
  } catch (error) {
    exceptionHandlerService.exceptionService(error, msg);
    yield put(request.failure(failureConstant, error.toString()));
  }
}

function* getSupplyLevelStatusList(action) {
  console.log("supply level saga function", action);
  const api = dashboardApi.getSupplyLevelStatusList;
  const failureConstant =
    dashboardConstants.GET_SUPPLY_LEVEL_STATUS_LIST_FAILURE;
  const successConstant =
    dashboardConstants.GET_SUPPLY_LEVEL_STATUS_LIST_SUCCESS;
  const flag = true;
  const msg =
    "unable to get supply level status list. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getDeviceStatusList(action) {
  console.log("saga function", action);
  const api = dashboardApi.getDeviceStatusList;
  const failureConstant =
    dashboardConstants.GET_DEVICE_STATUS_LIST_FAILURE;
  const successConstant =
    dashboardConstants.GET_DEVICE_STATUS_LIST_SUCCESS;
  const flag = true;
  const msg =
    "unable to get device  status list. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getDeviceColorProportionList(action) {
  console.log("saga function", action);
  const api = dashboardApi.getDeviceColorProportionList;
  const failureConstant =
    dashboardConstants.GET_DEVICE_COLOR_PROPORTION_LIST_FAILURE;
  const successConstant =
    dashboardConstants.GET_DEVICE_COLOR_PROPORTION_LIST_SUCCESS;
  const flag = true;
  const msg =
    "unable to get device color proportion list. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getServiceStatusList(action) {
  console.log("saga service function", action);
  const api = dashboardApi.getServiceStatusList;
  const failureConstant =
    dashboardConstants.GET_SERVICE_STATUS_LIST_FAILURE;
  const successConstant =
    dashboardConstants.GET_SERVICE_STATUS_LIST_SUCCESS;
  const flag = true;
  const msg =
    "unable to get service status list. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getDashboardClientList(action) {
  console.log("action saga", action);
  const api = dashboardApi.getDashboardClientList;
  const failureConstant = dashboardConstants.GET_DASHBOARD_CLIENT_LIST_FAILURE;
  const successConstant = dashboardConstants.GET_DASHBOARD_CLIENT_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get clients list. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getDashbaordLocationList(action) {
  const api = dashboardApi.getDashboardLocationList;
  const failureConstant =
    dashboardConstants.GET_DASHBOARD_LOCATION_LIST_FAILURE;
  const successConstant =
    dashboardConstants.GET_DASHBOARD_LOCATION_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get location list. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getLastUpdatedDateSaga(action) {
  console.log("action saga", action);

  const api = dashboardApi.getLastUpdatedDateApi;
  const failureConstant = dashboardConstants.GET_LAST_UPDATED_DATE_FAILURE;
  const successConstant = dashboardConstants.GET_LAST_UPDATED_DATE_SUCCESS;
  const flag = true;
  const msg = "unable to get last updated date";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getMonthlyPageUsageByClientList(action) {
  console.log("action saga", action);

  const api = dashboardApi.getMonthlyPageUsageByClientList;
  const failureConstant = dashboardConstants.GET_MONTHLY_PAGE_USAGE_BY_CLIENT_LIST_FAILURE;
  const successConstant = dashboardConstants.GET_MONTHLY_PAGE_USAGE_BY_CLIENT_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get monthly page usage client list";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getMonthlyPageUsageByLocationList(action) {
  console.log("action saga", action);

  const api = dashboardApi.getMonthlyPageUsageByLocationList
  const failureConstant = dashboardConstants.GET_MONTHLY_PAGE_USAGE_BY_LOCATION_LIST_FAILURE;
  const successConstant = dashboardConstants.GET_MONTHLY_PAGE_USAGE_BY_LOCATION_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get monthly page usage location list";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getGreenReportList(action) {
  console.log("action saga", action);

  const api = dashboardApi.getGreenReportList
  const failureConstant = dashboardConstants.GET_GREEN_REPORT_LIST_FAILURE;
  const successConstant = dashboardConstants.GET_GREEN_REPORT_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get green report list";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}