/**
 * @Desc Saga to make all API calls related to the cost center view.
 */

import {
  call,
  put,
  takeLatest
} from "redux-saga/effects";
import {
  costCenterConstants
} from "../_constants/costCenter.constants";

import {
  costCenterApi
} from "../_apiCall/costCenter.api";

import {
  request
} from "../_helpers/request";
import {
  toaster
} from "../_common/toaster/toaster";
import {
  exceptionHandlerService
} from "../_helpers/exceptionHandler";

export function* costCenterSaga() {
  yield takeLatest(
    costCenterConstants.GET_COST_CENTER_LIST,
    getCostCenterList
  );

  yield takeLatest(
    costCenterConstants.GET_COST_CENTER_UNALLOCATED_DEVICE_LIST,
    getCostCenterUnAllocatedDeviceList
  );

  yield takeLatest(
    costCenterConstants.CREATE_COST_CENTER,
    createCostCenter
  );

  yield takeLatest(
    costCenterConstants.VIEW_COST_CENTER,
    viewCostCenter
  );
  yield takeLatest(
    costCenterConstants.UPDATE_COST_CENTER,
    updateCostCenter
  );
}


/**
 * @Desc Wrapper for all saga function call with the common functionality being defined here.
 */

function* sagaFunc(api, action, failureConstant, successConstant, flag, msg) {
  yield put(request.isFetching(costCenterConstants.REQUEST));
  try {
    const {
      response,
      error
    } =
    flag === true ? yield call(api, action.payload): yield call(api);

    if (response !== undefined) {
      console.log("saga value", response.success);

      if (response.success === true) {
        console.log("saga truew")
        yield put(request.success(successConstant, response));
      } else if (response.success === false) {
        console.log("saga false")

        toaster.notifyError(msg);
        yield put(request.failure(failureConstant, msg));
      }
    } else if (error) {
      exceptionHandlerService.exceptionService(error, msg);
      yield put(request.failure(failureConstant, error));
    }

  } catch (error) {
    exceptionHandlerService.exceptionService(error, msg);
    yield put(request.failure(failureConstant, error.toString()));
  }
}

function* getCostCenterList(action) {
  const api = costCenterApi.getCostCenterList;
  const failureConstant = costCenterConstants.GET_COST_CENTER_LIST_FAILURE;
  const successConstant = costCenterConstants.GET_COST_CENTER_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get cost center list. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getCostCenterUnAllocatedDeviceList(action) {
  const api = costCenterApi.getCostCenterUnAllocatedDeviceList;
  const failureConstant = costCenterConstants.GET_COST_CENTER_UNALLOCATED_DEVICE_LIST_FAILURE;
  const successConstant = costCenterConstants.GET_COST_CENTER_UNALLOCATED_DEVICE_LIST_SUCCESS;
  const flag = true;
  // const msg = "unable to get cost center unAllocated Device list. Please try after some time.";
  const msg = "No unAllocated Device list is available for selected client.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    // msg 
  );
}

function* createCostCenter(action) {
  const api = costCenterApi.createCostCenter;
  const failureConstant = costCenterConstants.CREATE_COST_CENTER_FAILURE;
  const successConstant = costCenterConstants.CREATE_COST_CENTER_SUCCESS;
  const flag = true;
  const msg = "unable to create cost center list. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* viewCostCenter(action) {
  const api = costCenterApi.viewCostCenter;
  const failureConstant = costCenterConstants.VIEW_COST_CENTER_FAILURE;
  const successConstant = costCenterConstants.VIEW_COST_CENTER_SUCCESS;
  const flag = true;
  const msg = "unable to get cost center list. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* updateCostCenter(action) {
  const api = costCenterApi.updateCostCenter;
  const failureConstant = costCenterConstants.UPDATE_COST_CENTER_FAILURE;
  const successConstant = costCenterConstants.UPDATE_COST_CENTER_SUCCESS;
  const flag = true;
  const msg = "unable to Update cost center list. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}