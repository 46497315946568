import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./toaster.scss";

// minified version is also included import
// 'react-toastify/dist/ReactToastify.min.css';

export const toaster = {
  notifyError,
  notifySuccess,
  notifyWarn,
  notifyInternetFail,
  notifyInternetSuccess,
  notify
};

export const ToasterView = props => {
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable={false}
        pauseOnHover={false}
      />
    </div>
  );
};

function notifyError(msg) {
  toast.dismiss();
  toast.error(msg, {
    position: "top-right",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    draggablePercent: 0
  });
}

function notifySuccess(msg) {
  toast.dismiss();
  toast.success(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    draggablePercent: 0
  });
}

function notifyWarn(msg) {
  toast.dismiss();
  toast.warn(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    draggablePercent: 0
  });
}

function notifyInternetFail() {
  toast.dismiss();
  return toast.error(
    <div className="toaster-offline">
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
      &nbsp;
      <p>You seems to be offline. Please connect & try again.</p>
    </div>,
    {
      position: "top-center",
      autoClose: 20000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      draggablePercent: 0
    }
  );
}

function notifyInternetSuccess() {
  return toast.dismiss();
}

function notify(msg) {
  toast.dismiss();
  toast.error(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    draggablePercent: 0
  });
}
