/**
 * @Desc Saga to make all API calls related to the asset management view.
 */

import {
  call,
  put,
  takeLatest
} from "redux-saga/effects";
import {
  assetConstants
} from "../_constants/asset.constant";

import {
  assetApi
} from "../_apiCall/asset.api";

import {
  request
} from "../_helpers/request";
import {
  toaster
} from "../_common/toaster/toaster";
import {
  exceptionHandlerService
} from "../_helpers/exceptionHandler";

export function* assetSaga() {
  console.log("inside assetsaga method");
  yield takeLatest(assetConstants.GET_ASSET_DEVICE_LIST, getAssetDeviceList);
  yield takeLatest(assetConstants.GET_ASSET_CATEGORY_LIST, getAssetCategoryList);
  yield takeLatest(assetConstants.GET_ASSET_CATEGORY_INFO, getAssetCategoryInfo);
  yield takeLatest(assetConstants.GET_ASSET_TAG_LIST, getAssetTagList);
  yield takeLatest(assetConstants.CREATE_ASSET, createAsset);
  yield takeLatest(assetConstants.GET_VIEW_ASSET_INFO, getViewAssetInfo);
  yield takeLatest(assetConstants.UPDATE_ASSET, updateAsset);

}

/**
 * @Desc Wrapper for all saga function call with the common functionality being defined here.
 */
function* sagaFunc(api, action, failureConstant, successConstant, flag, msg) {
  yield put(request.isFetching(assetConstants.REQUEST));
  console.log("inside asset saga");

  try {
    const {
      response,
      error
    } =
    flag === true ? yield call(api, action.payload): yield call(api);
    console.log("action", action);
    console.log("api", api);
    if (response !== undefined) {
      if (response.success === true) {
        console.log("saga response value", response)
        yield put(request.success(successConstant, response));
      } else if (response.success === false) {
        toaster.notifyError(msg);
        yield put(request.failure(failureConstant, msg));
      }
    } else if (error) {
      exceptionHandlerService.exceptionService(error, msg);
      yield put(request.failure(failureConstant, error));
    }
  } catch (error) {
    exceptionHandlerService.exceptionService(error, msg);
    yield put(request.failure(failureConstant, error.toString()));
  }
}

function* getAssetDeviceList(action) {
  console.log("action value inside printer method", action);
  const api = assetApi.getAssetDeviceList;
  const successConstant = assetConstants.GET_ASSET_DEVICE_LIST_SUCCESS;
  const failureConstant = assetConstants.GET_ASSET_DEVICE_LIST_FAILURE;
  const flag = true;
  const msg = "unable to get printers device list. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getAssetCategoryList(action) {
  console.log("action value inside asset category method", action);
  const api = assetApi.getAssetCategoryList;
  console.log("asset api", api);
  const failureConstant = assetConstants.GET_ASSET_CATEGORY_LIST_FAILURE;
  const successConstant = assetConstants.GET_ASSET_CATEGORY_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get category device list. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getAssetCategoryInfo(action) {
  console.log("action value inside asset categoryinfo method", action);
  const api = assetApi.getAssetCategoryInfo;
  const failureConstant = assetConstants.GET_ASSET_CATEGORY_INFO_FAILURE;
  const successConstant = assetConstants.GET_ASSET_CATEGORY_INFO_SUCCESS;
  const flag = true;
  const msg = "unable to get category info list. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getAssetTagList(action) {
  console.log("action value inside asset tag method", action);
  const api = assetApi.getAssetTagList;
  const failureConstant = assetConstants.GET_ASSET_TAG_LIST_FAILURE;
  const successConstant = assetConstants.GET_ASSET_TAG_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get tag list. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* createAsset(action) {
  const api = assetApi.createAssetApi;
  const failureConstant = assetConstants.CREATE_ASSET_FAILURE;
  const successConstant = assetConstants.CREATE_ASSET_SUCCESS;
  const flag = true;
  const msg = "unable to create user. Please try again.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getViewAssetInfo(action) {
  console.log("action value inside asseo method", action);
  const api = assetApi.getViewAssetInfo;
  const failureConstant = assetConstants.GET_VIEW_ASSET_INFO_FAILURE;
  const successConstant = assetConstants.GET_VIEW_ASSET_INFO_SUCCESS;
  const flag = true;
  const msg = "unable to get view asset info. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* updateAsset(action) {
  const api = assetApi.updateAssetApi;
  const failureConstant = assetConstants.UPDATE_ASSET_FAILURE;
  const successConstant = assetConstants.UPDATE_ASSET_SUCCESS;
  const flag = true;
  const msg = "unable to update asset. Please try again.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}