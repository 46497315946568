import { call, put, takeLatest } from 'redux-saga/effects';
import { categoryConstants } from "../_constants/category.constant";
import { categoryApi } from '../_apiCall/category.api';
import { request } from "../_helpers/request";
import { toaster } from "../_common/toaster/toaster";
import { exceptionHandlerService } from "../_helpers/exceptionHandler";

export function* categorySaga() {
    console.log("from category saga")
    yield takeLatest(categoryConstants.GET_CATEGORY_LIST, getCategoryList);
    yield takeLatest(categoryConstants.CREATE_CATEGORY, createCategory);
    yield takeLatest(categoryConstants.GET_CATEGORY_DETAILS, getCategoryDetails);
    yield takeLatest(categoryConstants.EDIT_CATEGORY, editCategoryDetails);
}

function* sagaFunc(api, action, failureConstant, successConstant, flag, msg){
    yield put(request.isFetching(categoryConstants.REQUEST))
    try {
        const { response, error } =
          flag === true ? yield call(api, action.payload) : yield call(api);
          console.log("from categorySaga reponse", response)
        if (response !== undefined) {
          if (response.success === true) {
            yield put(request.success(successConstant, response));
          } else if (response.success === false) {
            toaster.notifyError(msg);
            yield put(request.failure(failureConstant, msg));
          }
        } else if (error) {
          exceptionHandlerService.exceptionService(error, msg);
          yield put(request.failure(failureConstant, error));
        }
      } catch (error) {
        exceptionHandlerService.exceptionService(error, msg);
        yield put(request.failure(failureConstant, error.toString()));
      }
}

function* getCategoryList(action) {
  const api = categoryApi.getCategoryList;
  const failureConstant = categoryConstants.GET_CATEGORY_LIST_FAILURE;
  const successConstant = categoryConstants.GET_CATEGORY_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get clients list. Please try after some time.";
  yield call(
      sagaFunc,
      api,
      action,
      failureConstant,
      successConstant,
      flag,
      msg
  )
}

function* createCategory(action) {
  const api = categoryApi.createCategoryApi;
  const failureConstant = categoryConstants.CREATE_CATEGORY_FAILURE;
  const successConstant = categoryConstants.CREATE_CATEGORY_SUCCESS;
  const flag = true;
  const msg = "unable to update";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  ) 
}

function* getCategoryDetails(action) {
  const api = categoryApi.getCategoryDetails;
  const failureConstant = categoryConstants.GET_CATEGORY_DETAILS_FAILURE;
  const successConstant = categoryConstants.GET_CATEGORY_DETAILS_SUCCESS;
  const flag = true;
  const msg = "unable to get categoryDetails details. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg,
  )
}

function* editCategoryDetails(action) {
  const api = categoryApi.editCategoryDetails;
  const failureConstant = categoryConstants.EDIT_CATEGORY_FAILURE;
  const successConstant = categoryConstants.EDIT_CATEGORY_SUCCESS;
  const flag = true;
  const msg = "unable to update";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg,
  )
}

