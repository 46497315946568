/**
 * @Desc Wrapper for maintaining Session for the system.
*/

import axios from 'axios';
import { GlobalVariable } from '../_constants/url.constants';
import { UserResources } from "../_resources/user.resources";
import { exceptionHandlerService } from "../_helpers/exceptionHandler";
import { toaster } from "../_common/toaster/toaster";
import { history } from "../_helpers/history";
import { store } from '../_helpers/store';
import { SecurityService } from './security';
import { authActions } from "../_actions/auth.action";

export const SessionService = {
    refreshToken
}

// function to refresh token.
function refreshToken() {
    let refreshTimer, refreshCallTimer;
    let token = localStorage.getItem('token');
    let url = UserResources.getRefreshToken(token).url;

    clearTimeout(SecurityService.getFromSession('session'));
    clearTimeout(SecurityService.getFromSession('sessionCallTimer'));
    refreshTimer = setTimeout(()=>{
        store.dispatch(authActions.logout());
        toaster.notifyError("Session Expired");
        localStorage.clear();
        sessionStorage.clear();
        history.push("/login");
    }, GlobalVariable.sessionTimeout);
    SecurityService.storeToSession('session', refreshTimer );
    if(token !== null){
        refreshCallTimer = setTimeout(()=>{
            axios
            .get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': GlobalVariable.apiKey,
                },
                timeout: 30000
            })
            .then(response => {
                if(response.data.success === true){
                    localStorage.setItem("token", response.data.payload.refreshToken);
                }
            })
            .catch(error => {
                exceptionHandlerService.exceptionService(
                    error,
                    "Session Expired."
                );
                return Promise.reject(error);
            });
        }, GlobalVariable.sessionCallTimeout);
        SecurityService.storeToSession('sessionCallTimer', refreshCallTimer );
    }
}