import React from "react";
import {Spinner} from '../_common/spinner/spinner';

const LoadingComponent = ({isLoading, error}) => {
    // Handle the loading state
    if (isLoading) {
      return < Spinner />;
    }
    // Handle the error state
    else if (error) {
      return <div>Sorry, there was a problem loading the page. Please refresh the page once.</div>;
    }
    else {
      return null;
    }
};

export default LoadingComponent;