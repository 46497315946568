/**
 * @Desc Wrapper for floor management data manipulation & passing it to the store.
 */

import {
    floorConstants
} from '../_constants/floor.constant';
import _ from 'lodash';

const initialState = {
    clientList: [],
    locationList: [],
    floorList: [],
    tempImageData: undefined,
    floorMapping: undefined,
    allocationDeviceList: undefined,
    FloorMapDetailsToExport:[],
    loading: false
}

export function floorReducer(state = initialState, action) {
    switch (action.type) {
        case floorConstants.REQUEST:
            return {
                ...state,
                tempImageData: undefined,
                loading: true
            };
        case floorConstants.UNMOUNT_FLOORMAP_VIEW:
            return {
                clientList: [],
                locationList: [],
                floorList: [],
                tempImageData: undefined,
                floorMapping: undefined,
                allocationDeviceList: undefined,
                saveDeviceMapping: undefined,
                updateManageDevices: undefined,
                updateFloorDetailsRes: undefined,
                loading: false
            };
        case floorConstants.UNMOUNT_EDIT_FLOOR:
            return {
                ...state,
                floorDetails: undefined,
                tempImageData: undefined,
                updateFloorDetailsRes: undefined,
                checkAvailabilityRes: undefined,
                saveDeviceMapping: undefined,
                createFloorRes: undefined,
                updateManageDevices: undefined
            };
        case floorConstants.CHECK_AVAILABILITY_RESET:
            return {
                ...state,
                checkAvailabilityRes: undefined
            };
        case floorConstants.UNMOUNT_CREATE_FLOOR:
            return {
                ...state,
                tempImageData: undefined,
                createFloorRes: undefined,
                updateFloorDetailsRes: undefined,
                checkAvailabilityRes: undefined,
                saveDeviceMapping: undefined,
                updateManageDevices: undefined
            };
        case floorConstants.GET_FM_CLIENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                clientList: formatClientList(action.payload),
                floorMapping: undefined,
                allocationDeviceList: undefined,
                tempImageData: undefined
            };
        case floorConstants.GET_FM_CLIENT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case floorConstants.GET_FM_LOCATION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                locationList: formatLocationList(action.payload),
                floorMapping: undefined,
                allocationDeviceList: undefined,
                tempImageData: undefined
            };
        case floorConstants.GET_FM_LOCATION_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case floorConstants.GET_FM_FLOOR_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                floorList: formatFloorList(action.payload),
                floorMapping: undefined,
                allocationDeviceList: undefined,
                checkAvailabilityRes: undefined,
                updateFloorDetailsRes: undefined,
            };
        case floorConstants.GET_FM_FLOOR_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case floorConstants.GET_FLOOR_MAPPING_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                floorMapping: action.payload,
                allocationDeviceList: undefined,
                saveDeviceMapping: undefined,
                modelData: undefined
            };
        case floorConstants.GET_FLOOR_MAPPING_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case floorConstants.GET_ALLOCATED_UNALLOCATED_DEVICE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                allocationDeviceList: action.payload,
                updateManageDevices: undefined,
                saveDeviceMapping: undefined,
                modelData: undefined
            };
        case floorConstants.GET_ALLOCATED_UNALLOCATED_DEVICE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case floorConstants.GET_FM_DEVICE_MODEL_INFO_SUCCESS:
            return {
              ...state,
              loading: false,
              modelData: action.payload
            };
        case floorConstants.GET_FM_DEVICE_MODEL_INFO_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };
        case floorConstants.CREATE_NEW_FLOOR_SUCCESS:
            return {
              ...state,
              loading: false,
              createFloorRes: action.payload,
              updateManageDevices: undefined
            };
        case floorConstants.CREATE_NEW_FLOOR_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };
        case floorConstants.UPLOAD_FLOOR_MAP_TEMP_SUCCESS:
            return {
              ...state,
              loading: false,
              tempImageData: action.payload,
              updateManageDevices: undefined,
              createFloorRes: undefined,
              checkAvailabilityRes: undefined
            };
        case floorConstants.UPLOAD_FLOOR_MAP_TEMP_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };
        case floorConstants.UPDATE_DEVICE_ALLOCATION_LIST_SUCCESS:
            return {
              ...state,
              loading: false,
              updateManageDevices: action.payload,
              createFloorRes: undefined
            };
        case floorConstants.UPDATE_DEVICE_ALLOCATION_LIST_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };
        case floorConstants.SAVE_DEVICE_MAPPING_SUCCESS:
            return {
              ...state,
              loading: false,
              saveDeviceMapping: action.payload,
              createFloorRes: undefined,
              updateManageDevices: undefined
            };
        case floorConstants.SAVE_DEVICE_MAPPING_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };
        case floorConstants.GET_FLOOR_DETAILS_SUCCESS:
            return {
              ...state,
              loading: false,
              floorDetails: action.payload,
              tempImageData: undefined,
              saveDeviceMapping: undefined,
              createFloorRes: undefined,
              updateManageDevices: undefined,
              checkAvailabilityRes: undefined,
              updateFloorDetailsRes: undefined,
            };
        case floorConstants.GET_FLOOR_DETAILS_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };
        case floorConstants.UPDATE_FLOOR_DETAILS_SUCCESS:
            return {
              ...state,
              loading: false,
              updateFloorDetailsRes: action.payload,
              saveDeviceMapping: undefined,
              createFloorRes: undefined,
              updateManageDevices: undefined
            };
        case floorConstants.UPDATE_FLOOR_DETAILS_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };
        case floorConstants.CHECK_AVAILABILITY_SUCCESS:
            return {
              ...state,
              loading: false,
              checkAvailabilityRes: action.payload,
              updateFloorDetailsRes: undefined,
              saveDeviceMapping: undefined,
              createFloorRes: undefined,
              updateManageDevices: undefined
            };
        case floorConstants.CHECK_AVAILABILITY_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };
         case floorConstants.GET_FLOOR_DETAILS_TO_EXPORT_SUCCESS:
            return {
              ...state,
              loading: false,
              FloorMapDetailsToExport: action.payload.payload,
            };
        case floorConstants.GET_FLOOR_MAPPING_DETAILS_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };
        default:
            return state
    }
}


function formatClientList(clientList) {
    let clients = clientList.payload.client;

    _.map(clients, (client) => {
        client['value'] = client['clientId'];
        client['text'] = client['clientName'];
        client['clientid'] = client['clientId'];
        client['clientname'] = client['clientName'];
        delete client['clientId'];
        delete client['clientName'];
        delete client['managed'];
    });
    return clients;
}

function formatLocationList(locationList) {
    let locations = locationList.payload.client;

    _.map(locations, (location) => {
        location['value'] = location['locationId'];
        if (location["location"]["addressLine2"] === "") {
            location["text"] =
              location["location"]["addressLine1"] +
              " " +
              location["location"]["city"] +
              " " +
              location["location"]["state"] +
              "," +
              location["location"]["country"] +
              " " +
              location["location"]["zipCode"];
          } else {
            location["text"] =
              location["location"]["addressLine1"] +
              " " +
              location["location"]["addressLine2"] +
              " " +
              location["location"]["city"] +
              " " +
              location["location"]["state"] +
              "," +
              location["location"]["country"] +
              " " +
              location["location"]["zipCode"];
          }
        delete location['locationId'];
        delete location['location'];
    });
    return locations;
}

function formatFloorList(floorList){
    let floors = floorList.payload.floor;

    _.map(floors, (floor) => {
        floor['value'] = floor['floorId'];
        floor['text'] = floor['floorName'];
        delete floor['floorId'];
        delete floor['floorName'];
        delete floor['floorIndex'];
    });
    return floors;
}
