/**
 * @Desc Wrapper for login authentication data manipulation & pasisng it to the store.
 */

import { authConstants } from "../_constants/auth.constant";
import { SecurityService } from "../_helpers/security";

let user = {
  loggedInUser: SecurityService.get("loggedInUser"),
  role: SecurityService.get("role"),
  email: SecurityService.get("loggedInUserEmail"),
  userProfileUrl: SecurityService.get("userProfileUrl")
};

const initialState = SecurityService.get("isLoggedIn")
  ? {
      isLoggedIn: true,
      user
    }
  : {};

export function authenticationReducer(state = initialState, action) {
  switch (action.type) {
    case authConstants.REQUEST:
      return {
        ...state,
        loading: true
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        loading: false,
        user: action.payload
      };
    case authConstants.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        user: {},
        error: action.error
      };
    case authConstants.LOGOUT:
      return {
        ...state,
        user: {},
        loading: false
      };
    default:
      return state;
  }
}
