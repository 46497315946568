/**
 * @Desc Wrapper for green report constans. For all green report management API calls related contants being defiend here.
 */
export const greenReportConstants = {
    REQUEST: "GREEN_REPORT_REQUEST",
    SUCCESS: "GREEN_REPORT_SUCCESS",
    FAILURE: "GREEN_REPORT_FAILURE",

    GET_GREEN_REPORT_LIST: "GET_GREEN_REPORT_LIST",
    GET_GREEN_REPORT_LIST_SUCCESS: "GET_GREEN_REPORT_LIST_SUCCESS",
    GET_GREEN_REPORT_LIST_FAILURE: "GET_GREEN_REPORT_LIST_FAILURE",
}