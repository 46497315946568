/**
 * @Desc For all auth related (login, registration, logout) API calls contants being defiend here.
 */
export const authConstants = {
  LOCATION_CHANGE: "LOCATION_CHANGE",
  REQUEST: "AUTH_REQUEST",

  REFRESH_TOKEN: "REFRESH_TOKEN",

  REGISTER_REQUEST: "USER_REGISTER_REQUEST",

  LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USER_LOGIN_FAILURE",

  LOGOUT: "USER_LOGOUT"
};
