/**
 * @Desc Wrapper for all saga API call for floor management view.
 */
import {
    call,
    put,
    takeLatest
} from 'redux-saga/effects';
import {
    toaster
} from '../_common/toaster/toaster';
import {
    exceptionHandlerService
} from '../_helpers/exceptionHandler';
import {
    floorConstants
} from '../_constants/floor.constant';
import {
    floorApi
} from '../_apiCall/floor.api';
import {
    request
} from '../_helpers/request';

export function* floorSaga() {
    yield takeLatest(floorConstants.GET_FM_CLIENT_LIST, getClientList);
    yield takeLatest(floorConstants.GET_FM_LOCATION_LIST, getLocationList);
    yield takeLatest(floorConstants.GET_FM_FLOOR_LIST, getFloorList);
    yield takeLatest(floorConstants.GET_FLOOR_MAPPING_DETAILS, getFloorMappingDetails);
    yield takeLatest(floorConstants.GET_ALLOCATED_UNALLOCATED_DEVICE_LIST, getAllocatedUnallocatedDeviceList);
    yield takeLatest(floorConstants.GET_FM_DEVICE_MODEL_INFO, getDeviceModelInfo);
    yield takeLatest(floorConstants.CREATE_NEW_FLOOR, createNewFloor);
    yield takeLatest(floorConstants.UPLOAD_FLOOR_MAP_TEMP, uploadFloorMapTemp);
    yield takeLatest(floorConstants.UPDATE_DEVICE_ALLOCATION_LIST, updateDeviceAllocationList);
    yield takeLatest(floorConstants.SAVE_DEVICE_MAPPING, saveDeviceMappingSaga);
    yield takeLatest(floorConstants.GET_FLOOR_DETAILS, floorDetailsSaga);
    yield takeLatest(floorConstants.UPDATE_FLOOR_DETAILS, updateFloorDetailsSaga);
    yield takeLatest(floorConstants.CHECK_AVAILABILITY, checkAvailabilitySaga);
    yield takeLatest(floorConstants.GET_FLOOR_DETAILS_TO_EXPORT, FloorMapDetailsToExportSaga);

}

/**
 * @Desc Wrapper for all saga function call with the common functionality being defined here.
 */
function* sagaFunc(api, action, failureConstant, successConstant, flag, msg) {
    yield put(request.isFetching(floorConstants.REQUEST));
    try {
        const {
            response,
            error
        } =
        flag === true ? yield call(api, action.payload): yield call(api);

        if (response !== undefined) {
            if (response.success === true) {
                yield put(request.success(successConstant, response));
            } else if (response.success === false) {
                toaster.notifyError(msg);
                yield put(request.failure(failureConstant, msg));
            }
        } else if (error) {
            exceptionHandlerService.exceptionService(error, msg);
            yield put(request.failure(failureConstant, error));
        }
    } catch (error) {
        exceptionHandlerService.exceptionService(error, msg);
        yield put(request.failure(failureConstant, error.toString()));
    }
}

function* getClientList(action) {
    const api = floorApi.getClientList;
    const failureConstant = floorConstants.GET_FM_CLIENT_LIST_FAILURE;
    const successConstant = floorConstants.GET_FM_CLIENT_LIST_SUCCESS;
    const flag = false;
    const msg = "unable to get client list.";
    yield call(sagaFunc, api, action, failureConstant, successConstant, flag, msg);
}

function* getLocationList(action) {
    const api = floorApi.getLocationList;
    const failureConstant = floorConstants.GET_FM_LOCATION_LIST_FAILURE;
    const successConstant = floorConstants.GET_FM_LOCATION_LIST_SUCCESS;
    const flag = true;
    const msg = "unable to get location list.";
    yield call(sagaFunc, api, action, failureConstant, successConstant, flag, msg);
}

function* getFloorList(action) {
    const api = floorApi.getFloorList;
    const failureConstant = floorConstants.GET_FM_FLOOR_LIST_FAILURE;
    const successConstant = floorConstants.GET_FM_FLOOR_LIST_SUCCESS;
    const flag = true;
    const msg = "unable to get floor list.";
    yield call(sagaFunc, api, action, failureConstant, successConstant, flag, msg);
}

function* getFloorMappingDetails(action) {
    const api = floorApi.getFloorMappingDetailsApi;
    const failureConstant = floorConstants.GET_FLOOR_MAPPING_DETAILS_FAILURE;
    const successConstant = floorConstants.GET_FLOOR_MAPPING_DETAILS_SUCCESS;
    const flag = true;
    const msg = "unable to get floor mapping details.";
    yield call(sagaFunc, api, action, failureConstant, successConstant, flag, msg);
}

function* getAllocatedUnallocatedDeviceList(action) {
    const api = floorApi.getAllocatedUnallocatedDeviceListApi;
    const failureConstant = floorConstants.GET_ALLOCATED_UNALLOCATED_DEVICE_LIST_FAILURE;
    const successConstant = floorConstants.GET_ALLOCATED_UNALLOCATED_DEVICE_LIST_SUCCESS;
    const flag = true;
    const msg = "unable to get allocated device list.";
    yield call(sagaFunc, api, action, failureConstant, successConstant, flag, msg);
}

function* getDeviceModelInfo(action) {
    const api = floorApi.getDeviceModelInfoApi;
    const failureConstant = floorConstants.GET_FM_DEVICE_MODEL_INFO_FAILURE;
    const successConstant = floorConstants.GET_FM_DEVICE_MODEL_INFO_SUCCESS;
    const flag = true;
    const msg = "unable to get device model information";
    yield call(
      sagaFunc,
      api,
      action,
      failureConstant,
      successConstant,
      flag,
      msg
    );
}

function* createNewFloor(action) {
    const api = floorApi.createNewFloorApi;
    const failureConstant = floorConstants.CREATE_NEW_FLOOR_FAILURE;
    const successConstant = floorConstants.CREATE_NEW_FLOOR_SUCCESS;
    const flag = true;
    const msg = "unable to create floor. Please try again after some time.";
    yield call(
      sagaFunc,
      api,
      action,
      failureConstant,
      successConstant,
      flag,
      msg
    );
}

function* uploadFloorMapTemp(action) {
    const api = floorApi.uploadFloorMapApi;
    const failureConstant = floorConstants.UPLOAD_FLOOR_MAP_TEMP_FAILURE;
    const successConstant = floorConstants.UPLOAD_FLOOR_MAP_TEMP_SUCCESS;
    const flag = true;
    const msg = "unable to upload floor map. Please try again after some time.";
    yield call(
      sagaFunc,
      api,
      action,
      failureConstant,
      successConstant,
      flag,
      msg
    );
}

function* updateDeviceAllocationList(action) {
    const api = floorApi.updateDeviceAllocationListApi;
    const failureConstant = floorConstants.UPDATE_DEVICE_ALLOCATION_LIST_FAILURE;
    const successConstant = floorConstants.UPDATE_DEVICE_ALLOCATION_LIST_SUCCESS;
    const flag = true;
    const msg = "unable to update device allocation list. Please try again after some time.";
    yield call(
      sagaFunc,
      api,
      action,
      failureConstant,
      successConstant,
      flag,
      msg
    );
}

function* saveDeviceMappingSaga(action) {
    const api = floorApi.saveDeviceMapingApi;
    const failureConstant = floorConstants.SAVE_DEVICE_MAPPING_FAILURE;
    const successConstant = floorConstants.SAVE_DEVICE_MAPPING_SUCCESS;
    const flag = true;
    const msg = "unable to save device mapping data. Please try again after some time.";
    yield call(
      sagaFunc,
      api,
      action,
      failureConstant,
      successConstant,
      flag,
      msg
    );
}

function* floorDetailsSaga(action) {
    const api = floorApi.floorDetailsApi;
    const failureConstant = floorConstants.GET_FLOOR_DETAILS_FAILURE;
    const successConstant = floorConstants.GET_FLOOR_DETAILS_SUCCESS;
    const flag = true;
    const msg = "unable to get floor details. Please try again after some time.";
    yield call(
      sagaFunc,
      api,
      action,
      failureConstant,
      successConstant,
      flag,
      msg
    );
}

function* updateFloorDetailsSaga(action) {
    const api = floorApi.updateFloorDetailsApi;
    const failureConstant = floorConstants.UPDATE_FLOOR_DETAILS_FAILURE;
    const successConstant = floorConstants.UPDATE_FLOOR_DETAILS_SUCCESS;
    const flag = true;
    const msg = "unable to update floor details. Please try again after some time.";
    yield call(
      sagaFunc,
      api,
      action,
      failureConstant,
      successConstant,
      flag,
      msg
    );
}

function* checkAvailabilitySaga(action) {
    const api = floorApi.checkAvailabilityApi;
    const failureConstant = floorConstants.CHECK_AVAILABILITY_FAILURE;
    const successConstant = floorConstants.CHECK_AVAILABILITY_SUCCESS;
    const flag = true;
    const msg = "unable to check availability. Please try again after some time.";
    yield call(
      sagaFunc,
      api,
      action,
      failureConstant,
      successConstant,
      flag,
      msg
    );
}


function* FloorMapDetailsToExportSaga(action) {
    const api = floorApi.getFloorMappingDetailsApi;
    const failureConstant = floorConstants.GET_FLOOR_DETAILS_TO_EXPORT_FAILURE;
    const successConstant = floorConstants.GET_FLOOR_DETAILS_TO_EXPORT_SUCCESS;
    const flag = true;
    const msg = "unable to get floor details for export. Please try again after some time.";
    yield call(
      sagaFunc,
      api,
      action,
      failureConstant,
      successConstant,
      flag,
      msg
    );
}