/**
 * @Desc Wrapper for floor constants. For all floor management API calls related constants being defined here.
 */

export const floorConstants = {
    REQUEST: "FLOOR_REQUEST",
    SUCCESS: "FLOOR_SUCCESS",
    FAILURE: "FLOOR_FAILURE",

    UNMOUNT_FLOORMAP_VIEW: "UNMOUNT_FLOORMAP_VIEW",

    UNMOUNT_EDIT_FLOOR: "UNMOUNT_EDIT_FLOOR",

    UNMOUNT_CREATE_FLOOR: 'UNMOUNT_CREATE_FLOOR',

    CHECK_AVAILABILITY_RESET: 'CHECK_AVAILABILITY_RESET',

    GET_FM_CLIENT_LIST: "GET_FM_CLIENT_LIST",
    GET_FM_CLIENT_LIST_SUCCESS: "GET_FM_CLIENT_LIST_SUCCESS",
    GET_FM_CLIENT_LIST_FAILURE: "GET_FM_CLIENT_LIST_FAILURE",

    GET_FM_LOCATION_LIST: "GET_FM_LOCATION_LIST",
    GET_FM_LOCATION_LIST_SUCCESS: "GET_FM_LOCATION_LIST_SUCCESS",
    GET_FM_LOCATION_LIST_FAILURE: "GET_FM_LOCATION_LIST_FAILURE",

    GET_FM_FLOOR_LIST: "GET_FM_FLOOR_LIST",
    GET_FM_FLOOR_LIST_SUCCESS: "GET_FM_FLOOR_LIST_SUCCESS",
    GET_FM_FLOOR_LIST_FAILURE: "GET_FM_FLOOR_LIST_FAILURE",

    GET_FLOOR_MAPPING_DETAILS: "GET_FLOOR_MAPPING_DETAILS",
    GET_FLOOR_MAPPING_DETAILS_SUCCESS: "GET_FLOOR_MAPPING_DETAILS_SUCCESS",
    GET_FLOOR_MAPPING_DETAILS_FAILURE: "GET_FLOOR_MAPPING_DETAILS_FAILURE",

    GET_ALLOCATED_UNALLOCATED_DEVICE_LIST: "GET_ALLOCATED_UNALLOCATED_DEVICE_LIST",
    GET_ALLOCATED_UNALLOCATED_DEVICE_LIST_SUCCESS: "GET_ALLOCATED_UNALLOCATED_DEVICE_LIST_SUCCESS",
    GET_ALLOCATED_UNALLOCATED_DEVICE_LIST_FAILURE: "GET_ALLOCATED_UNALLOCATED_DEVICE_LIST_FAILURE",

    GET_FM_DEVICE_MODEL_INFO: "GET_FM_DEVICE_MODEL_INFO",
    GET_FM_DEVICE_MODEL_INFO_SUCCESS: "GET_FM_DEVICE_MODEL_INFO_SUCCESS",
    GET_FM_DEVICE_MODEL_INFO_FAILURE: "GET_FM_DEVICE_MODEL_INFO_FAILURE",

    CREATE_NEW_FLOOR: "CREATE_NEW_FLOOR",
    CREATE_NEW_FLOOR_SUCCESS: "CREATE_NEW_FLOOR_SUCCESS",
    CREATE_NEW_FLOOR_FAILURE: "CREATE_NEW_FLOOR_FAILURE",

    UPLOAD_FLOOR_MAP_TEMP: "UPLOAD_FLOOR_MAP_TEMP",
    UPLOAD_FLOOR_MAP_TEMP_SUCCESS: "UPLOAD_FLOOR_MAP_TEMP_SUCCESS",
    UPLOAD_FLOOR_MAP_TEMP_FAILURE: "UPLOAD_FLOOR_MAP_TEMP_FAILURE",

    UPDATE_DEVICE_ALLOCATION_LIST: 'UPDATE_DEVICE_ALLOCATION_LIST',
    UPDATE_DEVICE_ALLOCATION_LIST_SUCCESS: 'UPDATE_DEVICE_ALLOCATION_LIST_SUCCESS',
    UPDATE_DEVICE_ALLOCATION_LIST_FAILURE: 'UPDATE_DEVICE_ALLOCATION_LIST_FAILURE',

    SAVE_DEVICE_MAPPING : "SAVE_DEVICE_MAPPING",
    SAVE_DEVICE_MAPPING_SUCCESS : "SAVE_DEVICE_MAPPING_SUCCESS",
    SAVE_DEVICE_MAPPING_FAILURE : "SAVE_DEVICE_MAPPING_FAILURE",

    GET_FLOOR_DETAILS : "GET_FLOOR_DETAILS",
    GET_FLOOR_DETAILS_SUCCESS : "GET_FLOOR_DETAILS_SUCCESS",
    GET_FLOOR_DETAILS_FAILURE : "GET_FLOOR_DETAILS_FAILURE",

    UPDATE_FLOOR_DETAILS : "UPDATE_FLOOR_DETAILS",
    UPDATE_FLOOR_DETAILS_SUCCESS : "UPDATE_FLOOR_DETAILS_SUCCESS",
    UPDATE_FLOOR_DETAILS_FAILURE : "UPDATE_FLOOR_DETAILS_FAILURE",

    CHECK_AVAILABILITY : "CHECK_AVAILABILITY",
    CHECK_AVAILABILITY_SUCCESS : "CHECK_AVAILABILITY_SUCCESS",
    CHECK_AVAILABILITY_FAILURE : "CHECK_AVAILABILITY_FAILURE",

    GET_FLOOR_DETAILS_TO_EXPORT : "GET_FLOOR_DETAILS_TO_EXPORT",
    GET_FLOOR_DETAILS_TO_EXPORT_SUCCESS : "GET_FLOOR_DETAILS_TO_EXPORT_SUCCESS",
    GET_FLOOR_DETAILS_TO_EXPORT_FAILURE : "GET_FLOOR_DETAILS_TO_EXPORT_FAILURE",
}