/**
 * @Desc Wrapper for dashboard constans. For all dashboard management API calls related contants being defiend here.
 */

export const dashboardConstants = {
  REQUEST: "DASHBOARD_REQUEST",
  SUCCESS: "DASHBOARD_SUCCESS",
  FAILURE: "DASHBOARD_FAILURE",

  GET_SUPPLY_LEVEL_STATUS_LIST: "GET_SUPPLY_LEVEL_STATUS_LIST",
  GET_SUPPLY_LEVEL_STATUS_LIST_SUCCESS: " GET_SUPPLY_LEVEL_STATUS_LIST_SUCCESS",
  GET_SUPPLY_LEVEL_STATUS_LIST_FAILURE: "GET_SUPPLY_LEVEL_STATUS_LIST_FAILURE",

  GET_DEVICE_STATUS_LIST: "GET_DEVICE_STATUS_LIST",
  GET_DEVICE_STATUS_LIST_SUCCESS: "GET_DEVICE_STATUS_LIST_SUCCESS",
  GET_DEVICE_STATUS_LIST_FAILURE: "GET_DEVICE_STATUS_LIST_FAILURE",

  GET_DEVICE_COLOR_PROPORTION_LIST: " GET_DEVICE_COLOR_PROPORTION_LIST",
  GET_DEVICE_COLOR_PROPORTION_LIST_SUCCESS: " GET_DEVICE_COLOR_PROPORTION_LIST_SUCCESS",
  GET_DEVICE_COLOR_PROPORTION_LIST_FAILURE: "GET_DEVICE_COLOR_PROPORTION_LIST_FAILURE",

  GET_SERVICE_STATUS_LIST: "GET_SERVICE_STATUS_LIST",
  GET_SERVICE_STATUS_LIST_SUCCESS: "GET_SERVICE_STATUS_LIST_SUCCESS",
  GET_SERVICE_STATUS_LIST_FAILURE: "GET_SERVICE_STATUS_LIST_FAILURE",

  GET_MONTHLY_PAGE_USAGE_BY_CLIENT_LIST: "GET_MONTHLY_PAGE_USAGE_BY_CLIENT_LIST",
  GET_MONTHLY_PAGE_USAGE_BY_CLIENT_LIST_SUCCESS: " GET_MONTHLY_PAGE_USAGE_BY_CLIENT_LIST_SUCCESS",
  GET_MONTHLY_PAGE_USAGE_BY_CLIENT_LIST_FAILURE: "GET_MONTHLY_PAGE_USAGE_BY_CLIENT_LIST_FAILURE",

  GET_MONTHLY_PAGE_USAGE_BY_LOCATION_LIST: "GET_MONTHLY_PAGE_USAGE_BY_LOCATION_LIST",
  GET_MONTHLY_PAGE_USAGE_BY_LOCATION_LIST_SUCCESS: " GET_MONTHLY_PAGE_USAGE_BY_LOCATION_LIST_SUCCESS",
  GET_MONTHLY_PAGE_USAGE_BY_LOCATION_LIST_FAILURE: "GET_MONTHLY_PAGE_USAGE_BY_LOCATION_LIST_FAILURE",

  GET_LAST_UPDATED_DATE: "GET_LAST_UPDATED_DATE",
  GET_LAST_UPDATED_DATE_SUCCESS: "GET_LAST_UPDATED_DATE_SUCCESS",
  GET_LAST_UPDATED_DATE_FAILURE: "GET_LAST_UPDATED_DATE_FAILURE",

  GET_DASHBOARD_CLIENT_LIST: "GET_DASHBOARD_CLIENT_LIST",
  GET_DASHBOARD_CLIENT_LIST_SUCCESS: "GET_DASHBOARD_CLIENT_LIST_SUCCESS",
  GET_DASHBOARD_CLIENT_LIST_FAILURE: "GET_DASHBOARD_CLIENT_LIST_FAILURE",

  GET_DASHBOARD_LOCATION_LIST: "GET_DASHBOARD_LOCATION_LIST",
  GET_DASHBOARD_LOCATION_LIST_SUCCESS: "GET_DASHBOARD_LOCATION_LIST_SUCCESS",
  GET_DASHBOARD_LOCATION_LIST_FAILURE: "GET_DASHBOARD_LOCATION_LIST_FAILURE",

  GET_GREEN_REPORT_LIST: "GET_GREEN_REPORT_LIST",
  GET_GREEN_REPORT_LIST_SUCCESS: "GET_GREEN_REPORT_LIST_SUCCESS",
  GET_GREEN_REPORT_LIST_FAILURE: "GET_GREEN_REPORT_LIST_FAILURE",
};