/**
 * @Desc Saga to make all API calls related to the green report.
 */

import {
    call,
    put,
    takeLatest
} from "redux-saga/effects";
import {
    greenReportConstants
} from "../_constants/greenReport.constants";

import {
    greenReportApi
} from "../_apiCall/greenReport.api";

import {
    request
} from "../_helpers/request";
import {
    toaster
} from "../_common/toaster/toaster";
import {
    exceptionHandlerService
} from "../_helpers/exceptionHandler";

export function* greenReportSaga() {
    console.log("inside assetsaga method");
    // yield takeLatest(greenReportConstants.GET_GREEN_REPORT_LIST, getGreenReportList);

}

/**
 * @Desc Wrapper for all saga function call with the common functionality being defined here.
 */
function* sagaFunc(api, action, failureConstant, successConstant, flag, msg) {
    yield put(request.isFetching(greenReportConstants.REQUEST));
    console.log("inside green saga");
    try {
        const {
            response,
            error
        } =
        console.log("response", response);
        flag === true ? yield call(api, action.payload): yield call(api);
        console.log("action", action);
        console.log("api", api);
        if (response !== undefined) {
            if (response.success === true) {
                console.log("saga response value", response)
                yield put(request.success(successConstant, response));
            } else if (response.success === false) {
                console.log("inside false esponse");
                toaster.notifyError(msg);
                yield put(request.failure(failureConstant, msg));
            }
        } else if (error) {
            console.log("inside else  false esponse");

            exceptionHandlerService.exceptionService(error, msg);
            yield put(request.failure(failureConstant, error));
        }
    } catch (error) {
        console.log("inside cath block");
        exceptionHandlerService.exceptionService(error, msg);
        yield put(request.failure(failureConstant, error.toString()));
    }
}

function* getGreenReportList(action) {
    console.log("action value inside green report method", action);
    const api = greenReportApi.getGreenReportList;
    console.log("apiss", api);
    const successConstant = greenReportConstants.GET_GREEN_REPORT_LIST_SUCCESS;
    const failureConstant = greenReportConstants.GET_GREEN_REPORT_LIST_FAILURE;
    const flag = true;
    const msg = "unable to get green report list. Please try after some time.";
    yield call(
        sagaFunc,
        api,
        action,
        failureConstant,
        successConstant,
        flag,
        msg
    );
}