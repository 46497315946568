/**
 * @Desc Wrapper for green report data manipulation & pasisng it to the store.
 */

import {
    greenReportConstants
} from "../_constants/greenReport.constants";
//  import _ from "lodash";

let initialState = {
    greenReportList: [],

};

export function greenReportReducer(state = initialState, action) {
    console.log("action green type", action.type)

    switch (action.type) {
        case greenReportConstants.GET_GREEN_REPORT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                    greenReportList: action.payload,
            };
        case greenReportConstants.GET_GREEN_REPORT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
}