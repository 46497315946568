import { assetConstants } from "../_constants/asset.constant";

export const assetActions = {
  getAssetDeviceList,
  getAssetCategoryList,
  getAssetCategoryInfo,
  getAssetTagList,
  createAsset,
  getViewAssetInfo,
  updateAsset,
  unmountAssetCateoryList,
};

function getAssetDeviceList(data) {
  console.log("aaa", data);
  return {
    type: assetConstants.GET_ASSET_DEVICE_LIST,
    payload: data,
  };
}

function unmountAssetCateoryList() {
  return {
    type: assetConstants.UNMOUNT_ASSET_CATEGORY_LIST,
  };
}

function getAssetCategoryList(clientId) {
  return {
    type: assetConstants.GET_ASSET_CATEGORY_LIST,
    payload: clientId,
  };
}

function getAssetCategoryInfo(categoryId) {
  return {
    type: assetConstants.GET_ASSET_CATEGORY_INFO,
    payload: categoryId,
  };
}

function getAssetTagList() {
  return {
    type: assetConstants.GET_ASSET_TAG_LIST,
  };
}

function createAsset(data) {
  return {
    type: assetConstants.CREATE_ASSET,
    payload: data,
  };
}

function getViewAssetInfo(assetId) {
  return {
    type: assetConstants.GET_VIEW_ASSET_INFO,
    payload: assetId,
  };
}

function updateAsset(data) {
  return {
    type: assetConstants.UPDATE_ASSET,
    payload: data,
  };
}
