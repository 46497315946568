import { GlobalVariable } from "../_constants/url.constants";
const baseUrl = GlobalVariable.baseUrl;
const projectName = GlobalVariable.projectName;
const ssl = GlobalVariable.ssl;

export const categoryResources = {
    getCategoryListUrl,
    createCategoryApiUrl,
    getCategoryDetailsUrl,
    editCategoryDetailsUrl,
}

function getCategoryListUrl() {
    return {
    url:
        ssl +
            "://"+
            baseUrl +
                    "/" + 
                     projectName +
                        "/kbt/bridge/api/v1/assets/category/list"
};
};

function createCategoryApiUrl() {
    return {
        url:
        ssl+
        "://"+
           baseUrl+
              "/"+
               projectName+
               "/kbt/bridge//api/v1/asset/category/save"
    };
};

function getCategoryDetailsUrl(categoryId) {
    return {
        url:
        ssl+
         "://"+
           baseUrl+
            "/"+
             projectName +
               "/kbt/bridge/api/v1/asset/category/get?categoryId="+
               categoryId
    };
};

function editCategoryDetailsUrl() {
    return {
        url:
        ssl+
        "://"+
           baseUrl+
              "/"+
               projectName+
               "/kbt/bridge/api/v1/asset/category/update"
    };
};