// import axios from "axios";
import { EmailNotificationResources } from "../_resources/email_notification.resources";
import { requestHandler } from "../_helpers/requestHandler";

export const emailNotificationApi = {
  getEmailNotificationUserList,
  getEmailNotificationClientList,
  toggleEmailNotificationStatus,
  getEmailNotificationStatusList
};

function getEmailNotificationUserList(data) {
  let url = EmailNotificationResources.getEmailNotificationUserListUrl().url;
  return requestHandler.postService(url, data);
}

function getEmailNotificationClientList(data) {
  let url = EmailNotificationResources.getEmailNotificationClientListUrl().url;
  return requestHandler.postService(url, data);
}

function toggleEmailNotificationStatus(data) {
  let url = EmailNotificationResources.getEmailNotificationStatusUrl().url;
  return requestHandler.postService(url, data);
}

function getEmailNotificationStatusList(data) {
  let url = EmailNotificationResources.getEmailNotificationStatusListUrl().url;
  return requestHandler.postService(url,data);
}
