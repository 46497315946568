/**
 * @Desc Wrapper for all saga API call for home component functionality, global search By Serial.
 */

import {
    call,
    put,
    takeLatest
} from 'redux-saga/effects';
import {
    toaster
} from '../_common/toaster/toaster';
import {
    exceptionHandlerService
} from '../_helpers/exceptionHandler';
import {
    deviceConstants
} from '../_constants/device.constant';
import {
    homeConstants
} from '../_constants/home.constant';
import {
    deviceApi
} from '../_apiCall/device.api';
import {
    request
} from '../_helpers/request';

export function* homeSaga() {
    yield takeLatest(deviceConstants.GET_DEVICE_SEARCH_LIST, getDeviceSearchList);
}

/**
 * @Desc Wrapper for all saga function call with the common functionality being defined here.
 */
function* sagaFunc(api, action, failureConstant, successConstant, flag, msg) {
    yield put(request.isFetching(homeConstants.REQUEST));
    try {
        const {
            response,
            error
        } =
        flag === true ? yield call(api, action.payload): yield call(api);
        if (response !== undefined) {
            if (response.success === true) {
                yield put(request.success(successConstant, response));
            } else if (response.success === false) {
                toaster.notifyError(msg);
                yield put(request.failure(failureConstant, msg));
            }
        } else if (error) {
            exceptionHandlerService.exceptionService(error, msg);
            yield put(request.failure(failureConstant, error));
        }
    } catch (error) {
        exceptionHandlerService.exceptionService(error, msg);
        yield put(request.failure(failureConstant, error.toString()));
    }
}

// worker Saga: will be fired on GET_Search_Devices actions
function* getDeviceSearchList(action) {
    const api = deviceApi.getSearchDeviceList;
    const failureConstant = deviceConstants.GET_DEVICE_SEARCH_LIST_FAILURE;
    const successConstant = deviceConstants.GET_DEVICE_SEARCH_LIST_SUCCESS;
    const flag = true;
    const msg = "unable to get device list. Please try after some time.";
    yield call(
        sagaFunc,
        api,
        action,
        failureConstant,
        successConstant,
        flag,
        msg
    );
}
