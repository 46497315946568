/**
 * @Desc Wrapper for device constants. For all SiteView & device API calls related contants being defined here.
 */

export const reportConstants = {
  REQUEST: "REPORT_REQUEST",
  SUCCESS: "REPORT_SUCCESS",
  FAILURE: "REPORT_FAILURE",

  UNMOUNT_GENERATE_QB: "UNMOUNT_GENERATE_QB",

  UNMOUNT_CONTRACT_UPLOAD: "UNMOUNT_CONTRACT_UPLOAD",

  UNMOUNT_MONTHLY_USAGE_REPORT: "UNMOUNT_MONTHLY_USAGE_REPORT",

  UNMOUNT_FULL_MISSING_DEVICE_DETAILS: "UNMOUNT_FULL_MISSING_DEVICE_DETAILS",

  UNMOUNT_FULL_SUPPLY_ORDER_DEVICE_DETAILS:
    "UNMOUNT_FULL_SUPPLY_ORDER_DEVICE_DETAILS",

  CHECK_TCO_REPORT: "CHECK_TCO_REPORT",
  CHECK_TCO_REPORT_SUCCESS: "CHECK_TCO_REPORT_SUCCESS",
  CHECK_TCO_REPORT_FAILURE: "CHECK_TCO_REPORT_FAILURE",

  SAVE_POWER_USAGE: "SAVE_POWER_USAGE",
  SAVE_POWER_USAGE_SUCCESS: "SAVE_POWER_USAGE_SUCCESS",
  SAVE_POWER_USAGE_FAILURE: "SAVE_POWER_USAGE_FAILURE",

  GET_MISSING_DEVICE_DETAILS: "GET_MISSING_DEVICE_DETAILS",
  GET_MISSING_DEVICE_DETAILS_SUCCESS: "GET_MISSING_DEVICE_DETAILS_SUCCESS",
  GET_MISSING_DEVICE_DETAILS_FAILURE: "GET_MISSING_DEVICE_DETAILS_FAILURE",

  GET_FULL_MISSING_DEVICE_DETAILS: "GET_FULL_MISSING_DEVICE_DETAILS",
  GET_FULL_MISSING_DEVICE_DETAILS_SUCCESS:
    "GET_FULL_MISSING_DEVICE_DETAILS_SUCCESS",
  GET_FULL_MISSING_DEVICE_DETAILS_FAILURE:
    "GET_FULL_MISSING_DEVICE_DETAILS_FAILURE",

  GET_SUPPLY_ORDER_DEVICE_DETAILS: "GET_SUPPLY_ORDER_DEVICE_DETAILS",
  GET_SUPPLY_ORDER_DEVICE_DETAILS_SUCCESS:
    "GET_SUPPLY_ORDER_DEVICE_DETAILS_SUCCESS",
  GET_SUPPLY_ORDER_DEVICE_DETAILS_FAILURE:
    "GET_SUPPLY_ORDER_DEVICE_DETAILS_FAILURE",

  //contants for view  supply orders
  VIEW_SUPPLY_ORDER_DEVICE_DETAILS: "VIEW_SUPPLY_ORDER_DEVICE_DETAILS",
  VIEW_SUPPLY_ORDER_DEVICE_DETAILS_SUCCESS:
    "VIEW_SUPPLY_ORDER_DEVICE_DETAILS_SUCCESS",
  VIEW_SUPPLY_ORDER_DEVICE_DETAILS_FAILURE:
    "VIEW_SUPPLY_ORDER_DEVICE_DETAILS_FAILURE",

  GET_FULL_SUPPLY_ORDER_DEVICE_DETAILS: "GET_FULL_SUPPLY_ORDER_DEVICE_DETAILS",
  GET_FULL_SUPPLY_ORDER_DEVICE_DETAILS_SUCCESS:
    "GET_FULL_SUPPLY_ORDER_DEVICE_DETAILS_SUCCESS",
  GET_FULL_SUPPLY_ORDER_DEVICE_DETAILS_FAILURE:
    "GET_FULL_SUPPLY_ORDER_DEVICE_DETAILS_FAILURE",

  GET_DEVICE_DETAILS: "GET_DEVICE_DETAILS",
  GET_DEVICE_DETAILS_SUCCESS: "GET_DEVICE_DETAILS_SUCCESS",
  GET_DEVICE_DETAILS_FAILURE: "GET_DEVICE_DETAILS_FAILURE",

  GENERATE_TCO_REPORT: "GENERATE_TCO_REPORT",
  GENERATE_TCO_REPORT_FAILURE: "GENERATE_TCO_REPORT_FAILURE",
  GENERATE_TCO_REPORT_SUCCESS: "GENERATE_TCO_REPORT_SUCCESS",

  CHANGE_TCO_REPORT_STATUS: "CHANGE_TCO_REPORT_STATUS",

  GENERATE_QB_REPORT: "GENERATE_QB_REPORT",
  GENERATE_QB_REPORT_SUCCESS: "GENERATE_QB_REPORT_SUCCESS",
  GENERATE_QB_REPORT_FAILURE: "GENERATE_QB_REPORT_FAILURE",

  UPLOAD_CONTRACT_SHEET: "UPLOAD_CONTRACT_SHEET",
  UPLOAD_CONTRACT_SHEET_SUCCESS: "UPLOAD_CONTRACT_SHEET_SUCCESS",
  UPLOAD_CONTRACT_SHEET_FAILURE: "UPLOAD_CONTRACT_SHEET_FAILURE",

  GENERATE_MONTHLY_USAGE_REPORT: "GENERATE_MONTHLY_USAGE_REPORT",
  GENERATE_MONTHLY_USAGE_REPORT_SUCCESS:
    "GENERATE_MONTHLY_USAGE_REPORT_SUCCESS",
  GENERATE_MONTHLY_USAGE_REPORT_FAILURE:
    "GENERATE_MONTHLY_USAGE_REPORT_FAILURE",

  GET_CUSTOM_REPORTS: "GET_CUSTOM_REPORTS",
  GET_CUSTOM_REPORTS_SUCCESS: "GET_CUSTOM_REPORTS_SUCCESS",
  GET_CUSTOM_REPORTS_FAILURE: "GET_CUSTOM_REPORTS_SUCCESS",

  CREATE_CUSTOM_REPORT: "CREATE_CUSTOM_REPORT",
  CREATE_CUSTOM_REPORT_SUCCESS: "CREATE_CUSTOM_REPORT_SUCCESS",
  CREATE_CUSTOM_REPORT_FAILURE: "CREATE_CUSTOM_REPORT_FAILURE",

  SAVE_CUSTOM_REPORT: "SAVE_CUSTOM_REPORT",
  SAVE_CUSTOM_REPORT_SUCCESS: "SAVE_CUSTOM_REPORT_SUCCESS",
  SAVE_CUSTOM_REPORT_FAILURE: "SAVE_CUSTOM_REPORT_FAILURE",

  UPDATE_CUSTOM_REPORT: "UPDATE_CUSTOM_REPORT",
  UPDATE_CUSTOM_REPORT_SUCCESS: "UPDATE_CUSTOM_REPORT_SUCCESS",
  UPDATE_CUSTOM_REPORT_FAILURE: "UPDATE_CUSTOM_REPORT_FAILURE",

  DELETE_CUSTOM_REPORT: "DELETE_CUSTOM_REPORT",
  DELETE_CUSTOM_REPORT_SUCCESS: "DELETE_CUSTOM_REPORT_SUCCESS",
  DELETE_CUSTOM_REPORT_FAILURE: "DELETE_CUSTOM_REPORT_FAILURE",

  GET_REPORT_TYPE: "GET_REPORT_TYPE",
  GET_REPORT_TYPE_SUCCESS: "GET_REPORT_TYPE_SUCCESS",
  GET_REPORT_TYPE_FAILURE: "GET_REPORT_TYPE_FAILURE",

  GET_MEASURES_DIMENSIONS: "GET_MEASURES_DIMENSIONS",
  GET_MEASURES_DIMENSIONS_SUCCESS: "GET_MEASURES_DIMENSIONS_SUCCESS",
  GET_MEASURES_DIMENSIONS_FAILURE: "GET_MEASURES_DIMENSIONS_FAILURE",

  VIEW_CUSTOM_REPORT: "VIEW_CUSTOM_REPORT",
  VIEW_CUSTOM_REPORT_SUCCESS: "VIEW_CUSTOM_REPORT_SUCCESS",
  VIEW_CUSTOM_REPORT_FAILURE: "VIEW_CUSTOM_REPORT_FAILURE",

  SERVICE_HISTORY_REPORT: "SERVICE_HISTORY_REPORT",
  SERVICE_HISTORY_REPORT_FAILURE: "SERVICE_HISTORY_REPORT_FAILURE",
  SERVICE_HISTORY_REPORT_SUCCESS: "SERVICE_HISTORY_REPORT_SUCCESS",

  FULL_SERVICE_HISTORY_REPORT: "FULL_SERVICE_HISTORY_REPORT",
  FULL_SERVICE_HISTORY_REPORT_FAILURE: "FULL_SERVICE_HISTORY_REPORT_FAILURE",
  FULL_SERVICE_HISTORY_REPORT_SUCCESS: "FULL_SERVICE_HISTORY_REPORT_SUCCESS",

  GET_SERVICE_HISTORY_DETAILS: "GET_SERVICE_HISTORY_DETAILS",
  GET_SERVICE_HISTORY_DETAILS_FAILURE: "GET_SERVICE_HISTORY_DETAILS_FAILURE",
  GET_SERVICE_HISTORY_DETAILS_SUCCESS: "GET_SERVICE_HISTORY_DETAILS_SUCCESS",

  GET_BASIC_TCO_REPORT: "GET_BASIC_TCO_REPORT",
  GET_BASIC_TCO_REPORT_SUCCESS: "GET_BASIC_TCO_REPORT_SUCCESS",
  GET_BASIC_TCO_REPORT_FAILURE: "GET_BASIC_TCO_REPORT_FAILURE",

  GET_ADVANCE_TCO_REPORT: "GET_ADVANCE_TCO_REPORT",
  GET_ADVANCE_TCO_REPORT_SUCCESS: "GET_ADVANCE_TCO_REPORT_SUCCESS",
  GET_ADVANCE_TCO_REPORT_FAILURE: "GET_ADVANCE_TCO_REPORT_FAILURE",

  GET_TCO_CHART: "GET_TCO_CHART",
  GET_TCO_CHART_SUCCESS: "GET_TCO_CHART_SUCCESS",
  GET_TCO_CHART_FAILURE: "GET_TCO_CHART_FAILURE",

  GET_FULL_BASIC_TCO_REPORT: "GET_FULL_BASIC_TCO_REPORT",
  GET_FULL_BASIC_TCO_REPORT_SUCCESS: "GET_FULL_BASIC_TCO_REPORT_SUCCESS",
  GET_FULL_BASIC_TCO_REPORT_FAILURE: "GET_FULL_BASIC_TCO_REPORT",

  IMPORT_MACD_CSV_FILE: "IMPORT_MACD_CSV_FILE",
  IMPORT_MACD_CSV_FILE_SUCCESS: "IMPORT_MACD_CSV_FILE_SUCCESS",
  IMPORT_MACD_CSV_FILE_FAILURE: "IMPORT_MACD_CSV_FILE_FAILURE",

  GET_PAGE_USAGE_REPORT: "GET_PAGE_USAGE_REPORT",
  GET_PAGE_USAGE_REPORT_SUCCESS: "GET_PAGE_USAGE_REPORT_SUCCESS",
  GET_PAGE_USAGE_REPORT_FAILURE: "GET_PAGE_USAGE_REPORT_FAILURE",

  GET_FULL_PAGE_USAGE_REPORT: "GET_FULL_PAGE_USAGE_REPORT",
  GET_FULL_PAGE_USAGE_REPORT_SUCCESS: "GET_FULL_PAGE_USAGE_REPORT_SUCCESS",
  GET_FULL_PAGE_USAGE_REPORT_FAILURE: "GET_FULL_PAGE_USAGE_REPORT_FAILURE",

  // GET_GREEN_REPORT_LIST: "GET_GREEN_REPORT_LIST",
  // GET_GREEN_REPORT_LIST_SUCCESS: "GET_GREEN_REPORT_LIST_SUCCESS",
  // GET_GREEN_REPORT_LIST_FAILURE: "GET_GREEN_REPORT_LIST_FAILURE",

  GET_QBR_REPORT: "GET_QBR_REPORT",
  GET_QBR_REPORT_SUCCESS: "GET_QBR_REPORT_SUCCESS",
  GET_QBR_REPORT_FAILURE: "GET_QBR_REPORT_FAILURE",

  GET_QBR_CHART: "GET_QBR_CHART",
  GET_QBR_CHART_SUCCESS: "GET_QBR_CHART_SUCCESS",
  GET_QBR_CHART_FAILURE: "GET_QBR_CHART_FAILURE",

  GET_FULL_QBR_REPORT: "GET_FULL_QBR_REPORT",
  GET_FULL_QBR_REPORT_SUCCESS: "GET_FULL_QBR_REPORT_SUCCESS",
  GET_FULL_QBR_REPORT_FAILURE: "GET_FULL_QBR_REPORT_FAILURE",
};
 