/**
 * @Desc Wrapper for storing API urls for device management & site view
 */

import { GlobalVariable } from "../_constants/url.constants";

const baseUrl = GlobalVariable.baseUrl;
const projectName = GlobalVariable.projectName;
const ssl = GlobalVariable.ssl;

export const ReportResources = {
  getPageUsageReportUrl,
  checkTcoReport,
  getDeviceDetails,
  generateTcoReport,
  savePowerUsage,
  generateQBReportUrl,
  downloadQBReportUrl,
  uploadContractSheetUrl,
  generateMonthlyUsageReportUrl,
  getMissingDeviceDetailsUrl,
  getSupplyOrderDeviceDetailsUrl,
  viewSupplyOrderDeviceDetailsUrl,
  getCustomReportsUrl,
  saveCustomReportsUrl,
  updateCustomReportsUrl,
  deleteCustomReportsUrl,
  getReportTypeUrl,
  getMeasuresDimensionsUrl,
  viewCustomReportsUrl,
  getServiceHistoryListUrl,
  getServiceHistoryDetailsUrl,
  getBasicTCOReportUrl,
  getTCOChartUrl,
  importMacdFileUrl,
  getGreenReportDetailsUrl,
  getQBRReportUrl,
  getQBRChartUrl,
  getFullQBRReportUrl,
};

function checkTcoReport() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/tco/getinfo",
  };
}

function savePowerUsage() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/clients/location/devices",
  };
}

function getDeviceDetails() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/tco/fetch/device/details",
  };
}

function generateTcoReport() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/tco/save/generate",
  };
}

function generateQBReportUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/parse/data",
  };
}

function getMissingDeviceDetailsUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/missing/devices",
  };
}

function getSupplyOrderDeviceDetailsUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/supply/level/reports",
  };
}

//view supply orders url
function viewSupplyOrderDeviceDetailsUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/supply/orders/view",
  };
}

function downloadQBReportUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/server/download",
  };
}

function uploadContractSheetUrl() {
  return {
    url:
      ssl + "://" + baseUrl + "/" + projectName + "/kbt/bridge/update/contract",
  };
}

function generateMonthlyUsageReportUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/print/usage/report",
  };
}

//Custom Report Url

function getCustomReportsUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v2/custom/report/lists",
  };
}

function saveCustomReportsUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v2/custom/report/save",
  };
}

function viewCustomReportsUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v2/custom/report/view",
  };
}

function updateCustomReportsUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v2/custom/report/update",
  };
}

function deleteCustomReportsUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v2/custom/report/list/remove",
  };
}

function getReportTypeUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/custom/reports/cubes/list",
  };
}

function getMeasuresDimensionsUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/custom/report/measures/dimensions",
  };
}

function getServiceHistoryListUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/service/history/list",
  };
}

function getServiceHistoryDetailsUrl(ticketId) {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/service/history/view?ticket_id=" +
      ticketId,
  };
}

function getBasicTCOReportUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/advance/tco/report",
  };
}

function getTCOChartUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/advance/tco/chart",
  };
}

function importMacdFileUrl() {
  return {
    url: ssl + "://" + baseUrl + "/" + projectName + "/kbt/bridge/macd/import",
  };
}

function getPageUsageReportUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/usage/report",
  };
}

function getGreenReportDetailsUrl() {
  console.log("greenreport resource url");
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v2/greenreport/get",
    // url: "https://run.mocky.io/v3/3fc29ebc-ce0e-4dbd-82aa-d7614483de2e"
    // url: ssl + "://" + baseUrl + "/" + projectName + "/kbt/bridge/api/v1/clients"
  };
}

function getQBRReportUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/qbr/report",
  };
}

function getFullQBRReportUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/qbr/report",
  };
}

function getQBRChartUrl() {
  return {
    url: ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/qbr/chart",
  };
}
