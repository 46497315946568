// import axios from "axios";
import { UserResources } from "../_resources/user.resources";
import { requestHandler } from "../_helpers/requestHandler";

export const userApi = {
  getUserList,
  getUserDetails,
  changeUserStatus,
  getUserRoleList,
  getClientList,
  getLocationList,
  toggleUserStatus,
  createUserApi,
  updateUserApi,
  uploadImageApi,
  changePasswordApi,
  forgotPasswordSendEmailRequest,
  forgotPasswordVerifyTokenRequest,
  forgotPasswordUpdatePasswordRequest,
  getChangeRoleListApi,
};

function getUserList(data) {
  let url = UserResources.getUserListUrl().url;
  return requestHandler.postService(url, data);
}

function getUserDetails(userId) {
  let url = UserResources.getUserDetailsUrl(userId).url;
  return requestHandler.getService(url);
}

function changeUserStatus() {
  let url = UserResources.getChangeUserStatusUrl();
  return requestHandler.postService(url);
}

function getUserRoleList() {
  let url = UserResources.getUserRoleListUrl().url;
  return requestHandler.getService(url);
}

function getClientList() {
  let url = UserResources.getClientListUrl().url;
  return requestHandler.getService(url);
}

function getLocationList(data) {
  let url = UserResources.getLocationListUrl().url;
  return requestHandler.postService(url, data);
}

function toggleUserStatus(data) {
  let url = UserResources.getChangeUserStatusUrl().url;
  return requestHandler.postService(url, data);
}

function createUserApi(data) {
  let url = UserResources.getCreateUserUrl().url;
  return requestHandler.postService(url, data);
}

function updateUserApi(data) {
  let url = UserResources.getUpdateUserUrl().url;
  return requestHandler.postService(url, data);
}

function uploadImageApi(data) {
  let url = UserResources.getUploadImageUrl().url;
  return requestHandler.postService(url, data);
}

function changePasswordApi(data) {
  let url = UserResources.getChangePasswordUrl().url;
  return requestHandler.postService(url, data);
}

function forgotPasswordSendEmailRequest(email) {
  let url = UserResources.getForgotPasswordSendEmailUrl().url;
  return requestHandler.postService(url, email);
}

function forgotPasswordVerifyTokenRequest(token) {
  let url = UserResources.getForgotPasswordVerifyTokenUrl(token).url;
  return requestHandler.getService(url);
}

function forgotPasswordUpdatePasswordRequest(request) {
  let url = UserResources.getForgotPasswordUpdatePasswordUrl().url;
  return requestHandler.postService(url, request);
}

function getChangeRoleListApi(userId) {
  console.log("userid from api file", userId);
  let url = UserResources.getChangeRoleListUrl(userId).url;
  return requestHandler.getService(url);
}
