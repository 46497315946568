import { categoryResources } from '../_resources/category.resources';
import { requestHandler } from "../_helpers/requestHandler";

export const categoryApi = {
    getCategoryList,
    createCategoryApi,
    getCategoryDetails,
    editCategoryDetails,
}

function getCategoryList(data) {
    let url = categoryResources.getCategoryListUrl().url;
    return requestHandler.postService(url, data)
}

function createCategoryApi(data) {
    let url = categoryResources.createCategoryApiUrl().url;
    return requestHandler.postService(url, data)
}

function getCategoryDetails(categoryId) {
    let url = categoryResources.getCategoryDetailsUrl(categoryId).url;
    return requestHandler.getService(url);
}

function editCategoryDetails(data) {
    let url = categoryResources.editCategoryDetailsUrl().url;
    return requestHandler.postService(url, data);
}