/**
 * @Desc Wrapper for site view & device management data manipulation & pasisng it to the store.
 */

import {
  reportConstants
} from "../_constants/report.constant";

let initialState = {
  finalTcoReportData: "",
  deviceDetails: "",
  loading: false,
  missingDeviceDetails: "",
  powerUsageData: "",
  supplyOrderDeviceDetails: "",
  viewSupplyOrderDeviceDetails: {},
  tcoData: "",
  tcoReportSuccessStatus: false,
  finalTcoReportStatus: false,
  generatedQBReportRes: undefined,
  contractUploadRes: undefined,
  customReports: [],
  measures: [],
  dimensions: [],
  createCustomReport: [],
  reportType: [],
  dimensionsMeasuresList: [],
  viewCustomReport: [],
  serviceHistoryList: [],
  fullServiceHistoryList: [],
  serviceHistoryDetail: [],
  serviceHistoryActivityDetail: [],
  totalPages: "",
  basicTcoReport: [],
  advanceTcoReport: [],
  tcoChartData: [],
  fullBasicTcoReport: [],
  pageUsageReport: [],
  fullPageUsageReport: [],
  qbrReport: [],
  qbrChartData: [],
  fullBasicTcoReportLoader: false,
};

export function reportReducer(state = initialState, action) {
  console.log("from reducer", action.type);
  switch (action.type) {
    case reportConstants.REQUEST:
      return {
        ...state,
        loading: true,
          fullBasicTcoReportLoader: true
      };
    case reportConstants.CHECK_TCO_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
          deviceDetails: "",
          tcoData: action.payload,
          tcoReportSuccessStatus: true,
          generatedQBReportRes: undefined,
      };
    case reportConstants.CHECK_TCO_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
          error: action.error,
          generatedQBReportRes: undefined,
      };
    case reportConstants.SAVE_POWER_USAGE_SUCCESS:
      return {
        ...state,
        loading: false,
          powerUsageData: action.payload,
          generatedQBReportRes: undefined,
      };
    case reportConstants.SAVE_POWER_USAGE_FAILURE:
      return {
        ...state,
        loading: false,
          error: action.error,
          generatedQBReportRes: undefined,
      };
    case reportConstants.GET_DEVICE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
          deviceDetails: action.payload,
          generatedQBReportRes: undefined,
      };
    case reportConstants.GET_DEVICE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
          error: action.error,
          generatedQBReportRes: undefined,
      };

    case reportConstants.GET_MISSING_DEVICE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
          missingDeviceDetails: action.payload,
      };
    case reportConstants.GET_MISSING_DEVICE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
          error: action.error,
          missingDeviceDetails: [],
      };

    case reportConstants.GET_FULL_MISSING_DEVICE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
          missingFullDeviceDetails: action.payload.payload.device,
          exportTo: action.payload.exportTo,
      };

    case reportConstants.GET_FULL_MISSING_DEVICE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
          exportTo: undefined,
          error: action.error,
          missingFullDeviceDetails: [],
      };

    case reportConstants.UNMOUNT_FULL_MISSING_DEVICE_DETAILS:
      return {
        ...state,
        loading: false,
          missingFullDeviceDetails: undefined,
          exportTo: undefined,
      };

    case reportConstants.GET_SUPPLY_ORDER_DEVICE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
          supplyOrderDeviceDetails: action.payload,
      };
    case reportConstants.GET_SUPPLY_ORDER_DEVICE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
          error: action.error,
          supplyOrderDeviceDetails: [],
      };

    case reportConstants.GET_FULL_SUPPLY_ORDER_DEVICE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
          supplyOrderFullDeviceDetails: action.payload.payload.device,
          exportTo: action.payload.exportTo,
      };

    case reportConstants.GET_FULL_SUPPLY_ORDER_DEVICE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
          exportTo: undefined,
          error: action.error,
          supplyOrderFullDeviceDetails: [],
      };

    case reportConstants.UNMOUNT_FULL_SUPPLY_ORDER_DEVICE_DETAILS:
      return {
        ...state,
        loading: false,
          supplyOrderFullDeviceDetails: undefined,
          exportTo: undefined,
      };

    case reportConstants.VIEW_SUPPLY_ORDER_DEVICE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
          viewSupplyOrderDeviceDetails: action.payload,
      };
    case reportConstants.VIEW_SUPPLY_ORDER_DEVICE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
          error: action.error,
          viewSupplyOrderDeviceDetails: {},
      };

    case reportConstants.GENERATE_TCO_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
          finalTcoReportStatus: true,
          finalTcoReportData: action.payload,
          generatedQBReportRes: undefined,
      };
    case reportConstants.GENERATE_TCO_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
          error: action.error,
          generatedQBReportRes: undefined,
      };
    case reportConstants.CHANGE_TCO_REPORT_STATUS:
      if (action.payload === "tcoReportSuccessStatus") {
        return {
          ...state,
          tcoReportSuccessStatus: false,
        };
      } else if (action.payload === "finalTcoReportSuccessStatus") {
        return {
          ...state,
          finalTcoReportStatus: false,
        };
      } else if (action.payload === "resetDeviceDetails") {
        return {
          ...state,
          deviceDetails: "",
        };
      } else if (action.payload === "resetMissingDeviceDetails") {
        return {
          ...state,
          missingDeviceDetails: "",
        };
      } else if (action.payload === "resetSupplyOrderDeviceDetails") {
        return {
          ...state,
          supplyOrderDeviceDetails: "",
        };
      } else {
        return {
          ...state,
        };
      }
      case reportConstants.GENERATE_QB_REPORT_SUCCESS:
        return {
          ...state,
          loading: false,
            generatedQBReportRes: action.payload,
        };
      case reportConstants.GENERATE_QB_REPORT_FAILURE:
        return {
          ...state,
          loading: false,
            error: action.error,
        };

      case reportConstants.UNMOUNT_GENERATE_QB:
        return {
          ...state,
          generatedQBReportRes: undefined,
            contractUploadRes: undefined,
            error: undefined,
        };
      case reportConstants.UPLOAD_CONTRACT_SHEET_SUCCESS:
        return {
          ...state,
          loading: false,
            contractUploadRes: action.payload,
        };
      case reportConstants.UPLOAD_CONTRACT_SHEET_FAILURE:
        return {
          ...state,
          loading: false,
            error: action.error,
        };
      case reportConstants.UNMOUNT_CONTRACT_UPLOAD:
        return {
          ...state,
          generatedQBReportRes: undefined,
            contractUploadRes: undefined,
            error: undefined,
        };
      case reportConstants.GENERATE_MONTHLY_USAGE_REPORT_SUCCESS:
        return {
          ...state,
          loading: false,
            monthlyUsageReport: action.payload,
        };
      case reportConstants.GENERATE_MONTHLY_USAGE_REPORT_FAILURE:
        return {
          ...state,
          loading: false,
            error: action.error,
        };
      case reportConstants.UNMOUNT_MONTHLY_USAGE_REPORT:
        return {
          ...state,
          loading: false,
            monthlyUsageReport: undefined,
        };
      case reportConstants.GET_CUSTOM_REPORTS_SUCCESS:
        return {
          ...state,
          loading: false,
            customReports: action.payload.payload,
        };
      case reportConstants.GET_CUSTOM_REPORTS_FAILURE:
        return {
          ...state,
          loading: false,
            error: action.error,
        };
      case reportConstants.CREATE_CUSTOM_REPORT_SUCCESS:
        return {
          ...state,
          loading: false,
            createCustomReport: action.payload.data,
        };
      case reportConstants.CREATE_CUSTOM_REPORT_FAILURE:
        return {
          ...state,
          loading: false,
            error: action.error,
        };
      case reportConstants.SAVE_CUSTOM_REPORT_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      case reportConstants.SAVE_POWER_USAGE_FAILURE:
        return {
          ...state,
          loading: false,
            error: action.error,
        };
      case reportConstants.UPDATE_CUSTOM_REPORT_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      case reportConstants.UPDATE_CUSTOM_REPORT_FAILURE:
        return {
          ...state,
          loading: false,
            error: action.error,
        };
      case reportConstants.DELETE_CUSTOM_REPORT_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      case reportConstants.DELETE_CUSTOM_REPORT_FAILURE:
        return {
          ...state,
          loading: false,
            error: action.error,
        };
      case reportConstants.GET_REPORT_TYPE_SUCCESS:
        return {
          ...state,
          loading: false,
            reportType: action.payload.payload,
        };
      case reportConstants.GET_CUSTOM_REPORTS_FAILURE:
        return {
          ...state,
          loading: false,
            error: action.error,
        };
      case reportConstants.GET_MEASURES_DIMENSIONS_SUCCESS:
        return {
          ...state,
          loading: false,
            dimensionsMeasuresList: action.payload.payload,
        };
      case reportConstants.GET_MEASURES_DIMENSIONS_FAILURE:
        return {
          ...state,
          loading: false,
            error: action.error,
        };
      case reportConstants.VIEW_CUSTOM_REPORT_SUCCESS:
        return {
          ...state,
          loading: false,
            viewCustomReport: action.payload.payload,
        };
      case reportConstants.VIEW_CUSTOM_REPORT_FAILURE:
        return {
          ...state,
          loading: false,
            error: action.error,
        };
      case reportConstants.SERVICE_HISTORY_REPORT_FAILURE:
        return {
          ...state,
          loading: false,
            error: action.error,
        };
      case reportConstants.SERVICE_HISTORY_REPORT_SUCCESS:
        console.log("form service sistory", action.payload);
        return {
          ...state,
          loading: false,
            serviceHistoryList: action.payload.payload.serviceHistoryReport,
            totalPages: action.payload.payload.totalPages,
        };
      case reportConstants.FULL_SERVICE_HISTORY_REPORT_FAILURE:
        return {
          ...state,
          loading: false,
            error: action.error,
        };
      case reportConstants.FULL_SERVICE_HISTORY_REPORT_SUCCESS:
        return {
          ...state,
          loading: false,
            fullServiceHistoryList: action.payload.payload.serviceHistoryReport,
            //totalPages: action.payload.payload.totalPages,
        };
      case reportConstants.GET_SERVICE_HISTORY_DETAILS_FAILURE:
        console.log("from reducer failure", action.payload);
        return {
          ...state,
          loading: false,
            error: action,
        };
      case reportConstants.GET_SERVICE_HISTORY_DETAILS_SUCCESS:
        console.log("from reducer success", action.payload);
        return {
          ...state,
          loading: false,
            serviceHistoryActivityDetail:
            action.payload.payload.ServiceHistoryActivityDetail,
            serviceHistoryDetail: action.payload.payload,
        };
      case reportConstants.GET_BASIC_TCO_REPORT_SUCCESS:
        return {
          ...state,
          loading: false,
            basicTcoReport: action.payload.payload,
        };
      case reportConstants.GET_BASIC_TCO_REPORT_FAILURE:
        return {
          ...state,
          loading: false,
            error: action.error,
        };
      case reportConstants.GET_TCO_CHART_SUCCESS:
        return {
          ...state,
          loading: false,
            tcoChartData: action.payload.payload,
        };
      case reportConstants.GET_TCO_CHART_FAILURE:
        return {
          ...state,
          loading: false,
            error: action.error,
        };
      case reportConstants.GET_FULL_BASIC_TCO_REPORT_SUCCESS:
        console.log("full basic tcodetails", action.payload);
        return {
          ...state,
          loading: false,
            fullBasicTcoReportLoader: false,
            fullBasicTcoReport: action.payload.payload.tcoReport,

        };

      case reportConstants.GET_FULL_BASIC_TCO_REPORT_FAILURE:
        return {
          ...state,
          loading: false,
            fullBasicTcoReportLoader: false,

            error: action.error,
        };

      case reportConstants.IMPORT_MACD_CSV_FILE_SUCCESS:
        return {
          ...state,
          loading: false,
            contractUploadRes: action.payload,
        };
      case reportConstants.IMPORT_MACD_CSV_FILE_FAILURE:
        return {
          ...state,
          loading: false,
            error: action.error,
        };
      case reportConstants.GET_PAGE_USAGE_REPORT_SUCCESS:
        return {
          ...state,
          loading: false,
            pageUsageReport: action.payload.payload,
        };
      case reportConstants.GET_PAGE_USAGE_REPORT_FAILURE:
        return {
          ...state,
          loading: false,
            error: action.error,
        };
      case reportConstants.GET_FULL_PAGE_USAGE_REPORT_SUCCESS:
        return {
          ...state,
          loading: false,
            fullPageUsageReport: action.payload.payload.pageUsageReport
        };
      case reportConstants.GET_FULL_PAGE_USAGE_REPORT_FAILURE:
        return {
          ...state,
          loading: false,
            error: action.error,
        };
      case reportConstants.GET_QBR_REPORT_SUCCESS:
        return {
          ...state,
          loading: false,
            qbrReport: action.payload.payload,
        };
      case reportConstants.GET_QBR_REPORT_FAILURE:
        return {
          ...state,
          loading: false,
            error: action.error,
        };

      case reportConstants.GET_QBR_CHART_SUCCESS:
        return {
          ...state,
          loading: false,
            qbrChartData: action.payload.payload,
        };
      case reportConstants.GET_QBR_CHART_FAILURE:
        return {
          ...state,
          loading: false,
            error: action.error,
        };
      case reportConstants.GET_FULL_QBR_REPORT_SUCCESS:
        return {
          ...state,
          loading: false,
            qbrFullReport: action.payload.payload.qbrReport,
        };
      case reportConstants.GET_FULL_QBR_REPORT_FAILURE:
        return {
          ...state,
          loading: false,
            error: action.error,
        };

      default:
        return state;
  }
}