import React from "react";
import "./spinner.scss";

export const Spinner = props => {
  const { processing, tcoExportProcessing } = props;
  return (
    <div
      style={{
        display: "flex"
      }}
    >
      <div className="spinner_background"></div>
      <div className="spinner spinner_container">
        <div className="rect1"></div>
        <div className="rect2"></div>
        <div className="rect3"></div>
        <div className="rect1"></div>
        <div className="rect2"></div>
        <div className="rect3"></div>
        <div className="rect4"></div>
        <div className="rect5"></div>
        <div className="rect3"></div>
        <div className="rect4"></div>
        <div className="rect5"></div>
      </div>
      {processing && (
        <div className="processing-text">
          Please wait, while we process your request.........
        </div>
      )}
      {tcoExportProcessing && (
        <div className="tcoExportProcessing-text">
          Please wait ... It may take up to 3 min to generate a report
        </div>
      )}
    </div>
  );
};
