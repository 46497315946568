/**
 * @Desc Wrapper for storing API urls for floor management.
 */

import {GlobalVariable } from "../_constants/url.constants"; 

const baseUrl = GlobalVariable.baseUrl; 
const projectName = GlobalVariable.projectName; 
const ssl = GlobalVariable.ssl;

export const FloorResources = {
    getFMClientListUrl,
    getFMLocationListUrl,
    getFMFloorListUrl,
    getFloorMappingDetailsUrl,
    getAllocatedUnallocatedDeviceListUrl,
    getDeviceModelInfoUrl,
    getCreateNewFloorUrl,
    getUploadFloorMapUrl,
    getUpdateDeviceAllocationList,
    getSaveDeviceMappingUrl,
    getFloorDetailsUrl,
    getUpdateFloorDetailsUrl,
    getAvailabilityUrl,
    getFloorMapDetailsToExportUrl
};

function getFMClientListUrl() {
    return {
        url: ssl + "://" + baseUrl + "/" + projectName + "/kbt/bridge/api/v1/clients"
    };
}

function getFMLocationListUrl() {
    return {
        url: ssl + "://" + baseUrl + "/" + projectName + "/kbt/bridge/api/v1/locations"
    };
}

function getFMFloorListUrl(locationId) {
    return {
       url: ssl + "://" + baseUrl + "/" + projectName + `/kbt/bridge/clients/locations/floor?locationId=${locationId}`
    };
}

function getUploadFloorMapUrl() {
    return {
      url:
        ssl +
        "://" +
        baseUrl +
        "/" +
        projectName +
        "/kbt/bridge/api/v1/upload/img/temp"
    };
}

function getCreateNewFloorUrl() {
    return {
        url: ssl + "://" + baseUrl + "/" + projectName + "/kbt/bridge/clients/locations/floor"
    }
}

function getFloorMappingDetailsUrl(data) {
    let locationId= data.locationId, floorId = data.floorId;

        return {
            url: ssl + "://" + baseUrl + "/" + projectName + `/kbt/bridge/clients/locations/mappings?locationId=${locationId}&floorId=${floorId}`
        };
}

function getAllocatedUnallocatedDeviceListUrl(data) {
    let locationId = data.locationId, floorId = data.floorId;
    return {
        url: ssl + "://" + baseUrl + "/" + projectName + `/kbt/bridge/clients/locations/floor/device/allocation?locationId=${locationId}&floorId=${floorId}`
        // url : "http://www.mocky.io/v2/5cb051523100006c00e13348"
        // url : "http://www.mocky.io/v2/5cb06c253100004c00e13430"
    };
}

function getUpdateDeviceAllocationList() {
    return {
      url:
        ssl +
        "://" +
        baseUrl +
        "/" +
        projectName +
        "/kbt/bridge/clients/locations/floor/device/allocation"
    };
}

function getDeviceModelInfoUrl(modelName) {
    return {
      url:
        ssl +
        "://" +
        baseUrl +
        "/" +
        projectName +
        "/kbt/bridge/clients/locations/model/info?modelName=" +
        modelName

    //  url : "http://www.mocky.io/v2/5cb0444c3100006c00e132df"
    };
}

function getSaveDeviceMappingUrl(){
    return {
        url: ssl +
        "://" +
        baseUrl +
        "/" +
        projectName +
        "/kbt/bridge/clients/locations/floor/device/mapping"
    }
}

function getFloorDetailsUrl(floorId){
    return {
        url : ssl +
                "://" +
            baseUrl +
            "/" +
            projectName + `/kbt/bridge/clients/locations/floor/info?id=${floorId}`
    }
}

function getUpdateFloorDetailsUrl(){
    return {
        url :  ssl +
            "://" +
            baseUrl +
            "/" +
            projectName + "/kbt/bridge/clients/locations/floor/update"
    }
}

function getAvailabilityUrl(){
    return {
        url :  ssl +
        "://" +
        baseUrl +
        "/" +
        projectName + "/kbt/bridge/clients/locations/floor/check/availability"
    }
}

function getFloorMapDetailsToExportUrl(device){
    return {
        url :  ssl +
        "://" +
        baseUrl +
        "/" +
        projectName + `/kbt/bridge//api/v1/assets/category/list?locationId=${device.locationId}&floorId=${device.floorId}`
    }
}