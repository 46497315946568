/**
 * @Desc Wrapper for client management data manipulation & pasisng it to the store.
 */

import {
  dashboardConstants
} from "../_constants/dashboard.constant";
import _ from "lodash";

const initialState = {
  clientList: [],
  locationList: [],
  loading: false,
  suppLoading: false,
  deviceLoading: false,
  deviceProportionLoading: false,
  serviceLoading: false,
  clientLoading: false,
  locationLoading: false,
  supplyLevelList: [],
  deviceStatusList: [],
  deviceColorProportionList: [],
  serviceStatusList: [],
  monthlyPageUsageByClientList: [],
  monthlyPageUsageByLocationList: [],
  greenReportList: []
  // loading: true

};

export function dashboardReducer(state = initialState, action) {
  console.log("dashboard reducer action", action);
  switch (action.type) {
    case dashboardConstants.REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_DASHBOARD_CLIENT_LIST_SUCCESS:

      return {
        ...state,
        loading: false,
          clientList: formatClientList(action.payload)
      };
    case dashboardConstants.GET_DASHBOARD_CLIENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
          error: action.error
      };
    case dashboardConstants.GET_SUPPLY_LEVEL_STATUS_LIST_SUCCESS:
      console.log("supply level action", action);
      return {
        ...state,
        suppLoading: false,
          supplyLevelList: action.payload.data
      }
      case dashboardConstants.GET_SUPPLY_LEVEL_STATUS_LIST_FAILURE:
        return {
          ...state,
          suppLoading: false,
            error: action.error
        }
        case dashboardConstants.GET_DEVICE_STATUS_LIST_SUCCESS:
          return {
            ...state,
            deviceLoading: false,
              deviceStatusList: action.payload.data
          }
          case dashboardConstants.GET_DEVICE_STATUS_LIST_FAILURE:
            return {
              ...state,
              deviceLoading: false,
                error: action.error
            }
            case dashboardConstants.GET_DEVICE_COLOR_PROPORTION_LIST_SUCCESS:
              return {
                ...state,
                deviceProportionLoading: false,
                  deviceColorProportionList: action.payload.data
              }
              case dashboardConstants.GET_DEVICE_COLOR_PROPORTION_LIST_FAILURE:
                return {
                  ...state,
                  deviceProportionLoading: false,
                    error: action.error
                }

                case dashboardConstants.GET_SERVICE_STATUS_LIST_SUCCESS:
                  return {
                    ...state,
                    serviceLoading: false,
                      serviceStatusList: action.payload.data
                  }
                  case dashboardConstants.GET_SERVICE_STATUS_LIST_FAILURE:
                    return {
                      ...state,
                      serviceLoading: false,
                        error: action.error
                    }

                    case dashboardConstants.GET_DASHBOARD_LOCATION_LIST_SUCCESS:
                      return {
                        ...state,
                        loading: false,
                          locationList: formatLocationList(action.payload)
                      };
                    case dashboardConstants.GET_DASHBOARD_LOCATION_LIST_FAILURE:
                      return {
                        ...state,
                        loading: false,
                          error: action.error
                      };
                    case dashboardConstants.GET_MONTHLY_PAGE_USAGE_BY_CLIENT_LIST_SUCCESS:
                      console.log("actionnn", action);
                      return {
                        ...state,
                        clientLoading: false,
                          monthlyPageUsageByClientList: action.payload.data
                      }
                      case dashboardConstants.GET_MONTHLY_PAGE_USAGE_BY_CLIENT_LIST_FAILURE:
                        return {
                          ...state,
                          clientLoading: false,
                            error: action.error
                        }
                        case dashboardConstants.GET_MONTHLY_PAGE_USAGE_BY_LOCATION_LIST_SUCCESS:
                          return {
                            ...state,
                            locationLoading: false,
                              monthlyPageUsageByLocationList: action.payload.data
                          }
                          case dashboardConstants.GET_MONTHLY_PAGE_USAGE_BY_LOCATION_LIST_FAILURE:
                            return {
                              ...state,
                              locationLoading: false,
                                error: action.error
                            }
                            case dashboardConstants.GET_LAST_UPDATED_DATE_SUCCESS:
                              return {
                                ...state,
                                loading: false,
                                  lastUpdated: action.payload
                              };
                            case dashboardConstants.GET_LAST_UPDATED_DATE_FAILURE:
                              return {
                                ...state,
                                loading: false,
                                  error: action.error
                              };
                            case dashboardConstants.GET_GREEN_REPORT_LIST_SUCCESS:
                              return {
                                ...state,
                                // loading: false,
                                greenReportList: action.payload.payload
                              };
                            case dashboardConstants.GET_GREEN_REPORT_LIST_FAILURE:
                              return {
                                ...state,
                                loading: false,
                                  error: action.error
                              };

                            default:
                              return state;
  }
}

function formatClientList(clientList) {
  let clients = clientList.payload.client;

  _.map(clients, client => {
    client["value"] = client["clientId"];
    client["text"] = client["clientName"];
    client["clientid"] = client["clientId"];
    client["clientname"] = client["clientName"];
    delete client["clientId"];
    delete client["clientName"];
    delete client['managed'];
  });
  return clients;
}

function formatLocationList(locationList) {
  let locations = locationList.payload.client;

  _.map(locations, location => {
    location["value"] = location["locationId"];
    if (location["location"]["addressLine2"] === "") {
      location["text"] =
        location["location"]["addressLine1"] +
        " " +
        location["location"]["city"] +
        " " +
        location["location"]["state"] +
        "," +
        location["location"]["country"] +
        " " +
        location["location"]["zipCode"];
    } else {
      location["text"] =
        location["location"]["addressLine1"] +
        " " +
        location["location"]["addressLine2"] +
        " " +
        location["location"]["city"] +
        " " +
        location["location"]["state"] +
        "," +
        location["location"]["country"] +
        " " +
        location["location"]["zipCode"];
    }

    location["city"] = location["location"]["city"];

    delete location["locationId"];
    delete location["location"];
  });
  return locations;
}