// import axios from "axios";
import { requestHandler } from "../_helpers/requestHandler";
import { CostCenterResources } from "../_resources/costCenter.resources";

export const costCenterApi = {
    getCostCenterList,
    getCostCenterUnAllocatedDeviceList,
    createCostCenter,
    viewCostCenter,
    updateCostCenter
};

function getCostCenterList(data) {
  let url = CostCenterResources.getCostCenterListUrl().url;
  return requestHandler.postService(url, data);
}

function getCostCenterUnAllocatedDeviceList(data){
  let url = CostCenterResources.getCostCenterUnAllocatedDeviceListUrl().url;
  return requestHandler.postService(url, data);
}

function createCostCenter(data){
  let url = CostCenterResources.createCostCenterUrl().url;
  return requestHandler.postService(url, data);
}

function viewCostCenter(data){
  let url = CostCenterResources.viewCostCenterUrl().url;
  return requestHandler.postService(url, data);
}

function updateCostCenter(data){
  let url = CostCenterResources.updateCostCenterUrl().url;
  return requestHandler.postService(url, data);
}