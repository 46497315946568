/**
 * @Desc Wrapper for initial fetching, success, failure of request.
 */

export const request = {
    isFetching,
    success,
    failure
}

function isFetching(type) {
    return {
        type: type
    }
}

function success(type, payload) {
    return {
        type: type,
        payload
    }
}

function failure(type, error) {
    return {
        type: type,
        error
    }
}