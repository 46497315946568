/**
 * @Desc Wrapper for all auth related like login, logout.
 */

import { call, put, takeLatest } from "redux-saga/effects";

import { authConstants } from "../_constants/auth.constant";

import { authApi } from "../_apiCall/auth.api";

import { request } from "../_helpers/request";
import { history } from "../_helpers/history";
import { toaster } from "../_common/toaster/toaster";
import { exceptionHandlerService } from "../_helpers/exceptionHandler";
import { SecurityService } from "../_helpers/security";

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user.If "LOGIN_REQUEST" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
export function* authSaga() {
  yield takeLatest(authConstants.LOGIN_REQUEST, loginUser);
}

// worker Saga: will be fired on LOGIN_REQUEST actions
function* loginUser(action) {
  yield put(request.isFetching(authConstants.REQUEST));
  try {
    const { response, error } = yield call(
      authApi.loginRequest,
      action.payload
    );

    if (response !== undefined) {
      if (response.success === true) {
        if (response.payload instanceof Object) {
          if (response.payload.hasOwnProperty("message")) {
            if (response.payload.message.indexOf("user Disabled") !== -1) {
              yield put(request.success(authConstants.LOGIN_SUCCESS, response));
              toaster.notifyError(
                "Your account has been disabled. Please contact your administrator"
              );
            }
          } else {
            SecurityService.store("role", response.payload.role);
            SecurityService.store("isLoggedIn", true);
            SecurityService.store("loggedInUser", response.payload.name);
            SecurityService.store("loggedInUserEmail", response.payload.email);
            SecurityService.store("userId", response.payload.userId);
            SecurityService.store("clientId", response.payload.clientId);
            SecurityService.store("clientName", response.payload.clientName);
            localStorage.setItem("token", response.payload.jwtToken);
            SecurityService.store(
              "userProfileUrl",
              response.payload.userProfileImage
            );
            yield put(request.success(authConstants.LOGIN_SUCCESS, response));
            history.push("/");
          }
        }
      } else if (response.success === false) {
        let msg = "Invalid email or password.";
        toaster.notifyError(msg);
        yield put(request.failure(authConstants.LOGIN_FAILURE, msg));
      }
    } else if (error) {
      exceptionHandlerService.exceptionService(
        error,
        "Invalid email or password."
      );
      yield put(request.failure(authConstants.LOGIN_FAILURE, error));
    }
  } catch (error) {
    exceptionHandlerService.exceptionService(error, null);
    yield put(request.failure(authConstants.LOGIN_FAILURE, error));
  }
}
