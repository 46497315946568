/**
 * @Desc Wrapper for storing API urls for device management & site view
 */

import { GlobalVariable } from "../_constants/url.constants";

const baseUrl = GlobalVariable.baseUrl;
const projectName = GlobalVariable.projectName;
const ssl = GlobalVariable.ssl;

export const DeviceResources = {
  getSearchDeviceListUrl,
  getClientDevicesList,
  getClientListUrl,
  getLocationListUrl,
  getDeviceDetailsUrl,
  getDeviceModelListUrl,
  getDeviceModelInfoUrl,
  getUpdateDeviceModelInfoUrl,
  getUploadAssetImageUrl,
  getUpdateDeviceContractInfoUrl,
  getCostCenterListUrl,
  updateDeviceBasicInfoUrl,
  getDeviceUsageInfoUrl,
  setDeviceInStorageUrl,
  getContractDetailsUrl,
  postDeviceContractFileUrl,
  updateContractDetailsUrl,
  updateDeviceUsageInfoUrl,
  getDeviceImagesUrl,
  uploadDeviceImageUrl,
  getDeviceImagesUrl,
  downloadContractFileUrl,
  getDeviceUsageChartInfoUrl,
  removeDeviceImageUrl,
  saveFiltersLogOutUrl,
  getSaveFilterStoredUrl
};

function getSearchDeviceListUrl(query) {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/device/search?serialNumber=" +
      query,
  };
}

function getClientDevicesList() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/clients/location/devices",
  };
}

function getClientListUrl() {
  return {
    url:
      ssl + "://" + baseUrl + "/" + projectName + "/kbt/bridge/api/v1/clients",
  };
}

function getLocationListUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/locations",
  };
}

// function getDeviceDetailsUrl(serialNumber) {
//   return {
//     url:
//       ssl +
//       "://" +
//       baseUrl +
//       "/" +
//       projectName +
//       "/kbt/bridge/clients/location/device/info?serialNumber=" +
//       serialNumber,
//   };
// }

function getDeviceDetailsUrl(serialNumber) {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v2/device/basic/info?serialNumber=" +
      serialNumber,
  };
}

function getDeviceModelListUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/clients/locations/models",
  };
}

function getDeviceModelInfoUrl(modelName) {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/clients/locations/model/info?modelName=" +
      modelName,
  };
}

function getUpdateDeviceModelInfoUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/clients/locations/model/update",
  };
}

function getUploadAssetImageUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/upload/img/temp",
  };
}

function getUpdateDeviceContractInfoUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/update/device/contract",
  };
}

function getCostCenterListUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/v1/cost/centers/list",
  };
}

function updateDeviceBasicInfoUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v2/device/edit/basic/info",
  };
}

function getDeviceUsageInfoUrl(serialNumber) {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v2/device/usage/info?serialNumber=" +
      serialNumber,
  };
}

function setDeviceInStorageUrl(req) {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v2/device/status/instorage?serialNumber=" +
      req.serialNumber +
      "&inStorage=" +
      req.inStorage,
  };
}

function getContractDetailsUrl(serialNumber) {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v2/device/contract/info?serialNumber=" +
      serialNumber,
  };
}

function postDeviceContractFileUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v2/upload/contract/supplyservice",
  };
}

function updateContractDetailsUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v2/device/edit/contract/info",
  };
}

function updateDeviceUsageInfoUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v2/device/edit/usage/info",
  };
}

function getDeviceImagesUrl(serialNumber) {
  console.log("from resoures", serialNumber);
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v2/list/img/url",
  };
}

  function saveFiltersLogOutUrl(){
    return {
      url:
        ssl +
        "://" +
        baseUrl +
        "/" +
        projectName +
      "/kbt/bridge/api/v2/filter/save",
  };
}

  
function getDeviceUsageChartInfoUrl(serialNumber) {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v2/device/usage/chart?serialNumber=" +
      serialNumber,
  };
}

function uploadDeviceImageUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/upload/img/gallery",
  };
}

function downloadContractFileUrl(req) {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v2/download/contract/supplyservice?serialNumber=" +
      req.serialNumber +
      "&context=" +
      req.context,
  };
}

function removeDeviceImageUrl(imagUrl) {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v2/delete/img/gallery?imgUrl=" +
      imagUrl,
  };
}

function getSaveFilterStoredUrl(userId) {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v2/filter/get?userId=" +
      userId,
  };
}

