/**
 * @Desc Wrapper for storing the API urls for asset management.
 */

import {
  GlobalVariable
} from "../_constants/url.constants";
const baseUrl = GlobalVariable.baseUrl;
const projectName = GlobalVariable.projectName;
const ssl = GlobalVariable.ssl;

export const assetResources = {
  getAssetDeviceListUrl,
  getAssetCategoryListUrl,
  getAssetCategoryInfoUrl,
  getAssetTagListUrl,
  getCreateAssetUrl,
  getAssetInfoUrl,
  getUpdateAssetUrl,
};

function getAssetDeviceListUrl() {
  return {
    url: ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/assets/list",
    // url: "http://192.168.0.21:8090/kbt/bridge/api/v1/assets/list"
  };
}

function getAssetCategoryListUrl(clientId) {
  console.log("inside asset resource")
  return {
    url: ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/asset/list/category?clientId=" +
      clientId,
    //"/kbt/bridge/api/v1/asset/list/category"
    // url: "http://192.168.0.21:8090/kbt/bridge/api/v1/asset/list/category"
  };
}

function getAssetCategoryInfoUrl(categoryId) {
  return {
    url: ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      //"/kbt/bridge/api/v1/asset/list/category?clientId=" + categoryId
      "/kbt/bridge/api/v1/asset/category?categoryId=" +
      categoryId,
    // url: "http://192.168.0.21:8090/kbt/bridge/api/v1/asset/category?categoryId=" + categoryId
  };
}

function getAssetTagListUrl() {
  return {
    url: ssl + "://" + baseUrl + "/" + projectName + "/kbt/bridge/api/v1/list/tag",
    // url: "http://192.168.0.21:8090/kbt/bridge/api/v1/list/tag"
  };
}

function getCreateAssetUrl() {
  return {
    url: ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/asset/create",
    // url: "http://192.168.0.21:8090/kbt/bridge/api/v1/asset/create"
  };
}

function getAssetInfoUrl(assetId) {
  return {
    url: ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/asset/info?assetId=" +
      assetId,
    // url: "http://192.168.0.21:8090/kbt/bridge/api/v1/asset/info?assetId=" + assetId
  };
}

function getUpdateAssetUrl() {
  return {
    url: ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/assets/update",
  };
}