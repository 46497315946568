/**
 * @Desc For combining all reducers to create a single reducer to pass it to the store.
 */


import {
  combineReducers
} from "redux";
import {
  authenticationReducer
} from "./auth.reducer";
import {
  clientReducer
} from "./client.reducer";
import {
  userReducer
} from "./user.reducer";
import {
  deviceReducer
} from "./device.reducer";
import {
  homeReducer
} from "./home.reducer";
import {
  floorReducer
} from "./floor.reducer";
import {
  worldviewReducer
} from "./worldview.reducer";
import {
  dashboardReducer
} from "./dashboard.reducer";
import {
  reportReducer
} from "./report.reducer";
import {
  emailNotificationReducer
} from "./email_notification.reducer";
import {
  costCenterReducer
} from "./costCenter.reducer";
import {
  assetReducer
} from "./asset.reducer";
import {
  categoryReducer
} from "./category.reducer";
import {
  greenReportReducer
} from "./greenReport.reducer";
import { faqReducer } from "./faq.reducer";

// this two step process to create appReducer & then pass it to rootReducer is to
// clear out the state object once user logs out.
const appReducer = combineReducers({
  /* your app’s top-level reducers */
  home: homeReducer,
  auth: authenticationReducer,
  client: clientReducer,
  user: userReducer,
  device: deviceReducer,
  floor: floorReducer,
  world: worldviewReducer,
  dashboard: dashboardReducer,
  report: reportReducer,
  emailNotification: emailNotificationReducer,
  costCenter: costCenterReducer,
  asset: assetReducer,
  category: categoryReducer,
  greenReport: greenReportReducer,
  faq: faqReducer,
})


export const rootReducer = (state, action) => {
  // to catch user_logout action & clear out the state object
  if (action.type === "USER_LOGOUT") {
    setTimeout(() => {
      state = undefined;
      localStorage.clear();
      sessionStorage.clear();
    }, 500);
  }

  return appReducer(state, action)
}
