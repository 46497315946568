/**
 * @Desc To define project configuration global variables for production and development
 *      environment.
 */

import {
  GlobalEnvironment
} from './environment';

export let GlobalVariable;

if (GlobalEnvironment.serverName === 'DEVELOPMENT') {
  GlobalVariable = Object.freeze({
    baseUrl: "kbt-connect.3esofttech.com",
    projectName: "bridge",
    ssl: "http",
    key: "ece2bd37-deec-4068-8c41-bd32fd755722",
    apiKey: "c67642f5-b6e7-48b3-9806-6c25d901b9e4",
    version_no: "1.4.38",
    sessionTimeout: 1800000,
    sessionCallTimeout: 4000,
    getServiceTimeout: 30000,
    postServiceTimeout: 30000,
    fileUploaderPostServiceTimeout: 3600000,
    googleMapApiKey: 'AIzaSyDx12ep2ctT6oMuAbCwhxpBnKpjP7OwviE',
    pentahoVariables: {
      userId: "admin",
      password: "3Eadmin@3Esoft",
      path: "/public/bridge_dashboard/",
      authorization: "YWRtaW46M0VhZG1pbkAzRXNvZnQ=",
      supplyLevelKPIUrl: 'http://kbt-connect.3esofttech.com/pentaho/plugin/pentaho-cdf-dd/api/renderer/render?solution=public&path=bridge_dashboard&file=SupplyLevel.wcdf&bypassCache=true&root=http://kbt-connect.3esofttech.com',
      supplyLevelPNGExportUrl: 'http://kbt-connect.3esofttech.com/pentaho/plugin/cgg/api/services/draw?script=/public/bridge_dashboard/SupplyLevel_supply_level_above_50_piechart.js',
      deviceStatusKPIUrl: 'http://kbt-connect.3esofttech.com/pentaho/plugin/pentaho-cdf-dd/api/renderer/render?solution=public&path=bridge_dashboard&file=DeviceStatus.wcdf&bypassCache=true&root=http://kbt-connect.3esofttech.com',
      deviceStatusPNGExportUrl: 'http://kbt-connect.3esofttech.com/pentaho/plugin/cgg/api/services/draw?script=/public/bridge_dashboard/DeviceStatus_DeviceStatus.js',
      deviceCartridgesKPIUrl: 'http://kbt-connect.3esofttech.com/pentaho/plugin/pentaho-cdf-dd/api/renderer/render?solution=public&path=bridge_dashboard&file=ColorWiseSupplyLevel.wcdf&bypassCache=true&root=http://kbt-connect.3esofttech.com',
      deviceCartridgesPNGExportUrl: 'http://kbt-connect.3esofttech.com/pentaho/plugin/cgg/api/services/draw?script=/public/bridge_dashboard/ColorWiseSupplyLevel_LevelBelow30.js',
      wasteKPIUrl: 'http://kbt-connect.3esofttech.com/pentaho/plugin/pentaho-cdf-dd/api/renderer/render?solution=public&path=bridge_dashboard&file=WasteLevel.wcdf&bypassCache=true&root=http://kbt-connect.3esofttech.com',
      wastePNGExportUrl: 'http://kbt-connect.3esofttech.com/pentaho/plugin/cgg/api/services/draw?script=/public/bridge_dashboard/WasteLevel_wasteLevelBelow30.js',
      deviceProportionChartUrl: 'http://kbt-connect.3esofttech.com/pentaho/plugin/pentaho-cdf-dd/api/renderer/render?solution=public&path=bridge_dashboard&file=ColorCapableDeviceProportion.wcdf&bypassCache=true&root=kbt-connect.3esofttech.com',
      deviceProportionPNGExportUrl: 'http://kbt-connect.3esofttech.com/pentaho/plugin/cgg/api/services/draw?script=/public/bridge_dashboard/ColorCapableDeviceProportion_colorProportion.js',
      monthlyUsageByClientChartUrl: 'http://kbt-connect.3esofttech.com/pentaho/plugin/pentaho-cdf-dd/api/renderer/render?solution=public&path=bridge_dashboard&file=pageUsageClientLevel.wcdf&bypassCache=true&root=http://kbt-connect.3esofttech.com',
      monthlyUsageByClientPNGExportUrl: 'http://kbt-connect.3esofttech.com/pentaho/plugin/cgg/api/services/draw?script=/public/bridge_dashboard/pageUsageClientLevel_barchart.js',
      monthlyUsageByLocationChartUrl: 'http://kbt-connect.3esofttech.com/pentaho/plugin/pentaho-cdf-dd/api/renderer/render?solution=public&path=bridge_dashboard&file=pageUsage.wcdf&bypassCache=true&root=kbt-connect.3esofttech.com',
      monthlyUsageByLocationPNGExportUrl: 'http://kbt-connect.3esofttech.com/pentaho/plugin/cgg/api/services/draw?script=/public/bridge_dashboard/pageUsage.js',
      customReportsUrl: `http://kbt-connect.3esofttech.com/pentaho/plugin/saiku/api/api/query/execute?userid=admin&password=3Eadmin@3Esoft`
    },
    roles: {
      KBT_ADMIN: "KBT_ADMIN",
      SUPPORT_ADMIN: "KBT_SUPPORT_ADMIN",
      KBT_ACCOUNT_ADMIN: "KBT_ACCOUNT_ADMIN",
      CLIENT_ADMIN: "CLIENT_ADMIN",
      CLIENT_LOCATION_ADMIN: "CLIENT_LOCATION_ADMIN"
    }
  });
} else if (GlobalEnvironment.serverName === 'STAGING') {
  GlobalVariable = Object.freeze({
    baseUrl: "bridgeconnectstaging.kb.technology",
    projectName: "bridge",
    ssl: "http",
    key: "ece2bd37-deec-4068-8c41-bd32fd755722",
    apiKey: 'c67642f5-b6e7-48b3-9806-6c25d901b9e4',
    version_no: "1.4.94",
    sessionTimeout: 1800000,
    sessionCallTimeout: 4000,
    getServiceTimeout: 30000,
    postServiceTimeout: 30000,
    fileUploaderPostServiceTimeout: 3600000,
    googleMapApiKey: 'AIzaSyDx12ep2ctT6oMuAbCwhxpBnKpjP7OwviE',
    pentahoVariables: {
      userId: "admin",
      password: "password",
      path: "/home/kbt_user/maintenance/bridge_dashboard/",
      authorization: "YWRtaW46cGFzc3dvcmQ=",
      supplyLevelKPIUrl: 'http://bridgeconnectstaging.kb.technology/pentaho/plugin/pentaho-cdf-dd/api/renderer/render?solution=home&path=kbt_user/bridge-connect/bridge_dashboard&file=SupplyLevel.wcdf&bypassCache=true&root=http://bridgeconnectstaging.kb.technology',
      supplyLevelPNGExportUrl: 'http://bridgeconnectstaging.kb.technology/pentaho/plugin/cgg/api/services/draw?script=/home/kbt_user/bridge-connect/bridge_dashboard/SupplyLevel_supply_level_above_50_piechart.js',
      deviceStatusKPIUrl: 'http://bridgeconnectstaging.kb.technology/pentaho/plugin/pentaho-cdf-dd/api/renderer/render?solution=home&path=kbt_user/bridge-connect/bridge_dashboard&file=DeviceStatus.wcdf&bypassCache=true&root=http://bridgeconnectstaging.kb.technology',
      deviceStatusPNGExportUrl: 'http://bridgeconnectstaging.kb.technology/pentaho/plugin/cgg/api/services/draw?script=/home/kbt_user/bridge-connect/bridge_dashboard/DeviceStatus_DeviceStatus.js',
      deviceCartridgesKPIUrl: 'http://bridgeconnectstaging.kb.technology/pentaho/plugin/pentaho-cdf-dd/api/renderer/render?solution=home&path=kbt_user/bridge-connect/bridge_dashboard&file=ColorWiseSupplyLevel.wcdf&bypassCache=true&root=http://bridgeconnectstaging.kb.technology',
      deviceCartridgesPNGExportUrl: 'http://bridgeconnectstaging.kb.technology/pentaho/plugin/cgg/api/services/draw?script=/home/kbt_user/bridge-connect/bridge_dashboard/ColorWiseSupplyLevel_LevelBelow30.js',
      wasteKPIUrl: 'http://bridgeconnectstaging.kb.technology/pentaho/plugin/pentaho-cdf-dd/api/renderer/render?solution=home&path=kbt_user/bridge-connect/bridge_dashboard&file=WasteLevel.wcdf&bypassCache=true&root=http://bridgeconnectstaging.kb.technology',
      wastePNGExportUrl: 'http://bridgeconnectstaging.kb.technology/pentaho/plugin/cgg/api/services/draw?script=/home/kbt_user/bridge-connect/bridge_dashboard/WasteLevel_wasteLevelBelow30.js',
      deviceProportionChartUrl: 'http://bridgeconnectstaging.kb.technology/pentaho/plugin/pentaho-cdf-dd/api/renderer/render?solution=home&path=kbt_user/bridge-connect/bridge_dashboard&file=ColorCapableDeviceProportion.wcdf&bypassCache=true&root=bridgeconnectstaging.kb.technology',
      deviceProportionPNGExportUrl: 'http://bridgeconnectstaging.kb.technology/pentaho/plugin/cgg/api/services/draw?script=/home/kbt_user/bridge-connect/bridge_dashboard/ColorCapableDeviceProportion_colorProportion.js',
      monthlyUsageByClientChartUrl: 'http://bridgeconnectstaging.kb.technology/pentaho/plugin/pentaho-cdf-dd/api/renderer/render?solution=home&path=kbt_user/bridge-connect/bridge_dashboard&file=pageUsageClientLevel.wcdf&bypassCache=true&root=http://bridgeconnectstaging.kb.technology',
      monthlyUsageByClientPNGExportUrl: 'http://bridgeconnectstaging.kb.technology/pentaho/plugin/cgg/api/services/draw?script=/home/kbt_user/bridge-connect/bridge_dashboard/pageUsageClientLevel_barchart.js',
      monthlyUsageByLocationChartUrl: 'http://bridgeconnectstaging.kb.technology/pentaho/plugin/pentaho-cdf-dd/api/renderer/render?solution=home&path=kbt_user/bridge-connect/bridge_dashboard&file=pageUsage.wcdf&bypassCache=true&root=bridgeconnectstaging.kb.technology',
      monthlyUsageByLocationPNGExportUrl: 'http://bridgeconnectstaging.kb.technology/pentaho/plugin/cgg/api/services/draw?script=/home/kbt_user/bridge-connect/bridge_dashboard/pageUsage_barchart.js',
      customReportsUrl: 'http://bridgeconnectstaging.kb.technology/pentaho/plugin/saiku/api/api/query/execute?userid=admin&password=password'
    },
    roles: {
      KBT_ADMIN: "KBT_ADMIN",
      SUPPORT_ADMIN: "KBT_SUPPORT_ADMIN",
      KBT_ACCOUNT_ADMIN: "KBT_ACCOUNT_ADMIN",
      CLIENT_ADMIN: "CLIENT_ADMIN",
      CLIENT_LOCATION_ADMIN: "CLIENT_LOCATION_ADMIN"
    }
  });
} else if (GlobalEnvironment.serverName === 'PRODUCTION') {
  GlobalVariable = Object.freeze({
    baseUrl: "bridge.kb.technology",
    projectName: "bridge",
    ssl: "https",
    key: "ece2bd37-deec-4068-8c41-bd32fd755722",
    apiKey: 'c67642f5-b6e7-48b3-9806-6c25d901b9e4',
    version_no: 'v1.4.95',
    sessionTimeout: 1800000,
    sessionCallTimeout: 4000,
    getServiceTimeout: 30000,
    postServiceTimeout: 30000,
    fileUploaderPostServiceTimeout: 3600000,
    googleMapApiKey: 'AIzaSyDx12ep2ctT6oMuAbCwhxpBnKpjP7OwviE',
    pentahoVariables: {
      userId: "admin",
      password: "password",
      authorization: "YWRtaW46cGFzc3dvcmQ=",
      path: "/home/kbt_user/maintenance/bridge_dashboard/",
      supplyLevelKPIUrl: 'https://bridge.kb.technology/pentaho/plugin/pentaho-cdf-dd/api/renderer/render?solution=home&path=kbt_user/bridge-connect/bridge_dashboard&file=SupplyLevel.wcdf&bypassCache=true&root=https://bridge.kb.technology',
      supplyLevelPNGExportUrl: 'https://bridge.kb.technology/pentaho/plugin/cgg/api/services/draw?script=/home/kbt_user/bridge-connect/bridge_dashboard/SupplyLevel_supply_level_above_50_piechart.js',
      deviceStatusKPIUrl: 'https://bridge.kb.technology/pentaho/plugin/pentaho-cdf-dd/api/renderer/render?solution=home&path=kbt_user/bridge-connect/bridge_dashboard&file=DeviceStatus.wcdf&bypassCache=true&root=https://bridge.kb.technology',
      deviceStatusPNGExportUrl: 'https://bridge.kb.technology/pentaho/plugin/cgg/api/services/draw?script=/home/kbt_user/bridge-connect/bridge_dashboard/DeviceStatus_DeviceStatus.js',
      deviceCartridgesKPIUrl: 'https://bridge.kb.technology/pentaho/plugin/pentaho-cdf-dd/api/renderer/render?solution=home&path=kbt_user/bridge-connect/bridge_dashboard&file=ColorWiseSupplyLevel.wcdf&bypassCache=true&root=https://bridge.kb.technology',
      deviceCartridgesPNGExportUrl: 'https://bridge.kb.technology/pentaho/plugin/cgg/api/services/draw?script=/home/kbt_user/bridge-connect/bridge_dashboard/ColorWiseSupplyLevel_LevelBelow30.js',
      wasteKPIUrl: 'https://bridge.kb.technology/pentaho/plugin/pentaho-cdf-dd/api/renderer/render?solution=home&path=kbt_user/bridge-connect/bridge_dashboard&file=WasteLevel.wcdf&bypassCache=true&root=https://bridge.kb.technology',
      wastePNGExportUrl: 'https://bridge.kb.technology/pentaho/plugin/cgg/api/services/draw?script=/home/kbt_user/bridge-connect/bridge_dashboard/WasteLevel_wasteLevelBelow30.js',
      deviceProportionChartUrl: 'https://bridge.kb.technology/pentaho/plugin/pentaho-cdf-dd/api/renderer/render?solution=home&path=kbt_user/bridge-connect/bridge_dashboard&file=ColorCapableDeviceProportion.wcdf&bypassCache=true&root=bridge.kb.technology',
      deviceProportionPNGExportUrl: 'https://bridge.kb.technology/pentaho/plugin/cgg/api/services/draw?script=/home/kbt_user/bridge-connect/bridge_dashboard/ColorCapableDeviceProportion_colorProportion.js',
      monthlyUsageByClientChartUrl: 'https://bridge.kb.technology/pentaho/plugin/pentaho-cdf-dd/api/renderer/render?solution=home&path=kbt_user/bridge-connect/bridge_dashboard&file=pageUsageClientLevel.wcdf&bypassCache=true&root=https://bridge.kb.technology',
      monthlyUsageByClientPNGExportUrl: 'https://bridge.kb.technology/pentaho/plugin/cgg/api/services/draw?script=/home/kbt_user/bridge-connect/bridge_dashboard/pageUsageClientLevel_barchart.js',
      monthlyUsageByLocationChartUrl: 'https://bridge.kb.technology/pentaho/plugin/pentaho-cdf-dd/api/renderer/render?solution=home&path=kbt_user/bridge-connect/bridge_dashboard&file=pageUsage.wcdf&bypassCache=true&root=bridge.kb.technology',
      monthlyUsageByLocationPNGExportUrl: 'https://bridge.kb.technology/pentaho/plugin/cgg/api/services/draw?script=/home/kbt_user/bridge-connect/bridge_dashboard/pageUsage_barchart.js',
      customReportsUrl: 'https://bridge.kb.technology/pentaho/plugin/saiku/api/api/query/execute?userid=admin&password=password'
    },
    roles: {
      KBT_ADMIN: "KBT_ADMIN",
      SUPPORT_ADMIN: "KBT_SUPPORT_ADMIN",
      KBT_ACCOUNT_ADMIN: "KBT_ACCOUNT_ADMIN",
      CLIENT_ADMIN: "CLIENT_ADMIN",
      CLIENT_LOCATION_ADMIN: "CLIENT_LOCATION_ADMIN"
    }
  });
}