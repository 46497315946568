/**
 * @Desc Wrapper for combining all sagas in order to pass these sagas to the redux store as a single saga.
 */

import {
  all
} from "redux-saga/effects";
import {
  authSaga
} from "./auth.saga";
import {
  clientSaga
} from "./client.saga";
import {
  userSaga
} from "./user.saga";
import {
  deviceSaga
} from "./device.sagas";
import {
  homeSaga
} from "./home.saga";
import {
  floorSaga
} from "./floor.saga";
import {
  worldviewSaga
} from "./worldview.saga";
import {
  dashboardSaga
} from "./dashboard.saga";
import {
  reportSaga
} from "./report.saga";
import {
  emailNotificationSaga
} from "./email_notification.saga";
import {
  costCenterSaga
} from "./costCenter.saga";
import {
  assetSaga
} from "./asset.saga";
import {
  categorySaga
} from "./category.saga";
import {
  greenReportSaga
} from "./greenReport.saga";
import { faqSaga } from "./faq.saga";


// single entry point to start all Sagas at once
export function* rootSaga() {
  yield all([
    homeSaga(),
    authSaga(),
    clientSaga(),
    userSaga(),
    deviceSaga(),
    floorSaga(),
    worldviewSaga(),
    dashboardSaga(),
    reportSaga(),
    emailNotificationSaga(),
    costCenterSaga(),
    assetSaga(),
    categorySaga(),
    greenReportSaga(),
    faqSaga(),
  ]);
}