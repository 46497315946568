/**
 * @Desc Wrapper for saga API call for site view & device related.
 */
import { call, put, takeLatest } from "redux-saga/effects";
import { toaster } from "../_common/toaster/toaster";
import { exceptionHandlerService } from "../_helpers/exceptionHandler";
import { deviceConstants } from "../_constants/device.constant";
import { deviceApi } from "../_apiCall/device.api";
import { request } from "../_helpers/request";
import { take } from "lodash";

export function* deviceSaga() {
  yield takeLatest(deviceConstants.GET_CLIENT_DEVICE_LIST, getClientDeviceList);
  yield takeLatest(
    deviceConstants.GET_FULL_CLIENT_DEVICE_LIST,
    getFullClientDeviceList
  );
  yield takeLatest(deviceConstants.GET_SV_CLIENT_LIST, getClientList);
  yield takeLatest(deviceConstants.GET_SV_LOCATION_LIST, getLocationList);
  yield takeLatest(deviceConstants.GET_CLIENT_DEVICE_DETAILS, getDeviceDetails);
  yield takeLatest(deviceConstants.GET_DEVICE_MODEL_LIST, getDeviceModelList);
  yield takeLatest(
    deviceConstants.GET_FULL_DEVICE_MODEL_LIST,
    getFullDeviceModelList
  );
  yield takeLatest(deviceConstants.GET_DEVICE_MODEL_INFO, getDeviceModelInfo);
  yield takeLatest(
    deviceConstants.UPDATE_DEVICE_MODEL_INFO,
    updateDeviceModelInfo
  );
  yield takeLatest(deviceConstants.UPLOAD_ASSET_IMAGE_TEMP, uploadAssetImage);
  yield takeLatest(
    deviceConstants.UPDATE_DEVICE_CONTRACT_INFO,
    updateDeviceContractInfo
  );
  yield takeLatest(
    deviceConstants.GET_CLIENT_COST_CENTER_LIST,
    getClientCostCenterList
  );
  yield takeLatest(
    deviceConstants.UPDATE_DEVICE_BASIC_INFO,
    updateDeviceBasicInfo
  );
  yield takeLatest(deviceConstants.GET_DEVICE_USAGE_INFO, getDeviceUsageInfo);
  yield takeLatest(deviceConstants.SET_DEVICE_IN_STOAGE, setDeviceInStorage);
  yield takeLatest(deviceConstants.GET_CONTRACT_DETAILS, getContractDetails);
  yield takeLatest(
    deviceConstants.UPLOAD_DEVICE_CONTRACT_FILE,
    uploadDeviceContractFile
  );
  yield takeLatest(
    deviceConstants.UPDATE_DEVICE_USAGE_INFO,
    updateDeviceUsageInfo
  );
  yield takeLatest(
    deviceConstants.UPDATE_CONTRACT_DETAILS,
    updateDeviceContractDetails
  );
  yield takeLatest(deviceConstants.GET_DEVICE_IMAGES, getDeviceImagesUrl);
  yield takeLatest(deviceConstants.UPLOAD_DEVICE_IMAGE, uploadDeviceImage);
  yield takeLatest(
    deviceConstants.DOWNLOAD_CONTRACT_FILE,
    downloadContractFile
  );
  yield takeLatest(
    deviceConstants.GET_DEVICE_USAGE_CHART_INFO,
    getDeviceUsageChartInfo
  );
  yield takeLatest(deviceConstants.REMOVE_DEVICE_IMAGE, removeDeviceImage);
  yield takeLatest(deviceConstants.SAVE_FILTERS_LOG_OUT, saveFiltersLogOutSaga);
  yield takeLatest(deviceConstants.GET_SAVE_FILTER_STORED, getSaveFilterLogStoredSaga);
}


/**
 * @Desc Wrapper for all saga function call with the common functionality being defined here.
 */
function* sagaFunc(api, action, failureConstant, successConstant, flag, msg) {
  yield put(request.isFetching(deviceConstants.REQUEST));
  try {
    const { response, error } =
      flag === true ? yield call(api, action.payload) : yield call(api);
    if (response !== undefined) {
      if (response.success === true) {
        yield put(request.success(successConstant, response));
      } else if (response.success === false) {
        toaster.notifyError(msg);
        yield put(request.failure(failureConstant, msg));
      }
    } else if (error) {
      exceptionHandlerService.exceptionService(error, msg);
      yield put(request.failure(failureConstant, error));
    }
  } catch (error) {
    exceptionHandlerService.exceptionService(error, msg);
    yield put(request.failure(failureConstant, error.toString()));
  }
}

// worker Saga: will be fired on GET_Search_Devices actions
function* getClientDeviceList(action) {
  const api = deviceApi.getClientDeviceList;
  const failureConstant = deviceConstants.GET_CLIENT_DEVICE_LIST_FAILURE;
  const successConstant = deviceConstants.GET_CLIENT_DEVICE_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get client device list. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getFullClientDeviceList(action) {
  const api = deviceApi.getFullClientDeviceList;
  const failureConstant = deviceConstants.GET_FULL_CLIENT_DEVICE_LIST_FAILURE;
  const successConstant = deviceConstants.GET_FULL_CLIENT_DEVICE_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get client device list. Please try after some time.";
  yield put(request.isFetching(deviceConstants.REQUEST));
  try {
    const { response, error } =
      flag === true ? yield call(api, action.payload) : yield call(api);
    if (response !== undefined) {
      if (response.success === true) {
        response["exportTo"] = action.exportTo;
        yield put(request.success(successConstant, response));
      } else if (response.success === false) {
        toaster.notifyError(msg);
        yield put(request.failure(failureConstant, msg));
      }
    } else if (error) {
      exceptionHandlerService.exceptionService(error, msg);
      yield put(request.failure(failureConstant, error));
    }
  } catch (error) {
    exceptionHandlerService.exceptionService(error, msg);
    yield put(request.failure(failureConstant, error.toString()));
  }
}

function* getClientList(action) {
  const api = deviceApi.getClientList;
  const failureConstant = deviceConstants.GET_SV_CLIENT_LIST_FAILURE;
  const successConstant = deviceConstants.GET_SV_CLIENT_LIST_SUCCESS;
  const flag = false;
  const msg = "unable to get client list";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getLocationList(action) {
  const api = deviceApi.getLocationList;
  const failureConstant = deviceConstants.GET_SV_LOCATION_LIST_FAILURE;
  const successConstant = deviceConstants.GET_SV_LOCATION_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get location list";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getDeviceDetails(action) {
  const api = deviceApi.getDeviceDetails;
  const failureConstant = deviceConstants.GET_CLIENT_DEVICE_DETAILS_FAILURE;
  const successConstant = deviceConstants.GET_CLIENT_DEVICE_DETAILS_SUCCESS;
  const flag = true;
  const msg = "unable to get device details";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getDeviceModelList(action) {
  const api = deviceApi.getDeviceModelList;
  const failureConstant = deviceConstants.GET_DEVICE_MODEL_LIST_FAILURE;
  const successConstant = deviceConstants.GET_DEVICE_MODEL_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get device model list";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getFullDeviceModelList(action) {
  const api = deviceApi.getFullDeviceModelList;
  const failureConstant = deviceConstants.GET_FULL_DEVICE_MODEL_LIST_FAILURE;
  const successConstant = deviceConstants.GET_FULL_DEVICE_MODEL_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get device model list";
  yield put(request.isFetching(deviceConstants.REQUEST));
  try {
    const { response, error } =
      flag === true ? yield call(api, action.payload) : yield call(api);
    if (response !== undefined) {
      if (response.success === true) {
        response["exportTo"] = action.exportTo;
        yield put(request.success(successConstant, response));
      } else if (response.success === false) {
        toaster.notifyError(msg);
        yield put(request.failure(failureConstant, msg));
      }
    } else if (error) {
      exceptionHandlerService.exceptionService(error, msg);
      yield put(request.failure(failureConstant, error));
    }
  } catch (error) {
    exceptionHandlerService.exceptionService(error, msg);
    yield put(request.failure(failureConstant, error.toString()));
  }
}

function* getDeviceModelInfo(action) {
  const api = deviceApi.getDeviceModelInfo;
  const failureConstant = deviceConstants.GET_DEVICE_MODEL_INFO_FAILURE;
  const successConstant = deviceConstants.GET_DEVICE_MODEL_INFO_SUCCESS;
  const flag = true;
  const msg = "unable to get device model information";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* updateDeviceModelInfo(action) {
  const api = deviceApi.updateDeviceModelInfo;
  const failureConstant = deviceConstants.UPDATE_DEVICE_MODEL_INFO_FAILURE;
  const successConstant = deviceConstants.UPDATE_DEVICE_MODEL_INFO_SUCCESS;
  const flag = true;
  const msg = "unable to update device model information";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* uploadAssetImage(action) {
  const api = deviceApi.uploadAssetImageApi;
  const failureConstant = deviceConstants.UPLOAD_ASSET_IMAGE_TEMP_FAILURE;
  const successConstant = deviceConstants.UPLOAD_ASSET_IMAGE_TEMP_SUCCESS;
  const flag = true;
  const msg = "unable to upload user profile image. Please try again.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* updateDeviceContractInfo(action) {
  const api = deviceApi.updateDeviceContractInfoApi;
  const failureConstant = deviceConstants.UPDATE_DEVICE_CONTRACT_INFO_FAILURE;
  const successConstant = deviceConstants.UPDATE_DEVICE_CONTRACT_INFO_SUCCESS;
  const flag = true;
  const msg = "unable to update device contract info. Please try again.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getClientCostCenterList(action) {
  const api = deviceApi.getCostCenterList;
  const failureConstant = deviceConstants.GET_CLIENT_COST_CENTER_LIST_FAILURE;
  const successConstant = deviceConstants.GET_CLIENT_COST_CENTER_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get location list";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* updateDeviceBasicInfo(action) {
  const api = deviceApi.updateDeviceBasicInfo;
  const failureConstant = deviceConstants.UPDATE_DEVICE_BASIC_INFO_FAILURE;
  const successConstant = deviceConstants.UPDATE_DEVICE_BASIC_INFO_SUCCESS;
  const flag = true;
  const msg = "unable to update the Basic Information";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getDeviceUsageInfo(action) {
  const api = deviceApi.getDeviceUsageInfo;
  const failureConstant = deviceConstants.GET_DEVICE_USAGE_INFO_FAILURE;
  const successConstant = deviceConstants.GET_DEVICE_USAGE_INFO_SUCCESS;
  const flag = true;
  const msg = "unable to get Usage Info";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* setDeviceInStorage(action) {
  const api = deviceApi.setDeviceInStorage;
  const failureConstant = deviceConstants.SET_DEVICE_IN_STOAGE_FAILURE;
  const successConstant = deviceConstants.SET_DEVICE_IN_STOAGE_SUCCESS;
  const flag = true;
  const msg = "unable to add Device in Storage";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getContractDetails(action) {
  const api = deviceApi.getContractDetailsApi;
  const failureConstant = deviceConstants.GET_CONTRACT_DETAILS_FAILURE;
  const successConstant = deviceConstants.GET_CONTRACT_DETAILS_SUCCESS;
  const flag = true;
  const msg = "Unable to see the Contract Details";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* uploadDeviceContractFile(action) {
  const api = deviceApi.uploadDeviceContractFileApi;
  const failureConstant = deviceConstants.UPLOAD_DEVICE_CONTRACT_FILE_FAILURE;
  const successConstant = deviceConstants.UPLOAD_DEVICE_CONTRACT_FILE_SUCCESS;
  const flag = true;
  const msg = "Unable to upload the Contract Details file";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* updateDeviceUsageInfo(action) {
  const api = deviceApi.updateDeviceUsageInfoApi;
  const failureConstant = deviceConstants.UPDATE_DEVICE_USAGE_INFO_FAILURE;
  const successConstant = deviceConstants.UPDATE_DEVICE_USAGE_INFO_SUCCESS;
  const flag = true;
  const msg = "unable to update device usage info";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* updateDeviceContractDetails(action) {
  const api = deviceApi.updateContractDetailsApi;
  const failureConstant = deviceConstants.UPDATE_CONTRACT_DETAILS_FAILURE;
  const successConstant = deviceConstants.UPDATE_CONTRACT_DETAILS_SUCCESS;
  const flag = true;
  const msg = "unable to update device usage info";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getDeviceImagesUrl(action) {
  const api = deviceApi.getDeviceImagesApi;
  const failureConstant = deviceConstants.GET_DEVICE_IMAGES_FAILURE;
  const successConstant = deviceConstants.GET_DEVICE_IMAGES_SUCCESS;
  const flag = true;
  const msg = "unable to get Device Images";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* saveFiltersLogOutSaga(action) {
  const api = deviceApi.saveFiltersLogOutApi;
  const failureConstant = deviceConstants.SAVE_FILTERS_LOG_OUT_FAILURE;
  const successConstant = deviceConstants.SAVE_FILTERS_LOG_OUT_SUCCESS;
  const flag = true;
  const msg = "Unable to see the Contract Details";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* uploadDeviceImage(action) {
  const api = deviceApi.uploadDeviceImageApi;
  const failureConstant = deviceConstants.UPLOAD_DEVICE_IMAGE_FAILURE;
  const successConstant = deviceConstants.UPLOAD_DEVICE_IMAGE_SUCCESS;
  const flag = true;
  const msg = "unable to upload Device Image";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* downloadContractFile(action) {
  const api = deviceApi.downloadContratFileApi;
  const failureConstant = deviceConstants.DOWNLOAD_CONTRACT_FILE_FAILURE;
  const successConstant = deviceConstants.DOWNLOAD_CONTRACT_FILE_SUCCESS;
  const flag = true;
  const msg = "contract  file not found";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getDeviceUsageChartInfo(action) {
  const api = deviceApi.getDeviceUsageChartDetailsApi;
  const failureConstant = deviceConstants.GET_DEVICE_USAGE_CHART_INFO_FAILURE;
  const successConstant = deviceConstants.GET_DEVICE_USAGE_CHART_INFO_SUCCESS;
  const flag = true;
  const msg = "unable to update device usage chart info";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* removeDeviceImage(action) {
  const api = deviceApi.removeDeviceImageApi;
  const failureConstant = deviceConstants.REMOVE_DEVICE_IMAGE_FAILURE;
  const successConstant = deviceConstants.REMOVE_DEVICE_IMAGE_SUCCESS;
  const flag = true;
  const msg = "unable to remove device image";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getSaveFilterLogStoredSaga(action) {
  const api = deviceApi.getSaveFilterStoredApi;
  const failureConstant = deviceConstants.GET_SAVE_FILTER_STORED_FAILURE;
  const successConstant = deviceConstants.GET_SAVE_FILTER_STORED_SUCCESS;
  const flag = true;
  const msg = "Unable to see the Contract Details";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}
