import { call, put, takeLatest } from "redux-saga/effects";
import { toaster } from "../_common/toaster/toaster";
import { exceptionHandlerService } from "../_helpers/exceptionHandler";
import { faqConstants } from "../_constants/faq.constant";
import { faqApi } from "../_apiCall/faq.api";
import { request } from "../_helpers/request";
import { take } from "lodash";

export function* faqSaga() {
  yield takeLatest(faqConstants.GET_FAQ_CATEGORY_LIST, getFaqCategoryList);
  yield takeLatest(faqConstants.SET_FAQ_CATEGORY, setFaqCategory);
  yield takeLatest(faqConstants.REMOVE_FAQ_CATEGORY, removeFaqCategory);
  yield takeLatest(faqConstants.UPDATE_FAQ_CATEGORY, updateCategory);
  yield takeLatest(faqConstants.ADD_FAQ_TO_CATEGORY, addFaqToCategory);
  yield takeLatest(faqConstants.GET_FAQ_LIST, getFaqList);
  yield takeLatest(faqConstants.UPDATE_FAQ, updateFaq);
  yield takeLatest(faqConstants.REMOVE_FAQ, removeFaq);
  yield takeLatest(faqConstants.GET_ALL_QUESTION_ANSWER, getAllQuestionAnswer);
}

function* sagaFunc(api, action, failureConstant, successConstant, flag, msg) {
  yield put(request.isFetching(faqConstants.REQUEST));
  try {
    const { response, error } =
      flag === true ? yield call(api, action.payload) : yield call(api);
    if (response !== undefined) {
      if (response.success === true) {
        yield put(request.success(successConstant, response));
      } else if (response.success === false) {
        toaster.notifyError(msg);
        yield put(request.failure(failureConstant, msg));
      }
    } else if (error) {
      exceptionHandlerService.exceptionService(error, msg);
      yield put(request.failure(failureConstant, error));
    }
  } catch (error) {
    exceptionHandlerService.exceptionService(error, msg);
    yield put(request.failure(failureConstant, error.toString()));
  }
}

function* getFaqCategoryList(action) {
  const api = faqApi.getFaqCategoryListApi;
  const failureConstant = faqConstants.GET_FAQ_CATEGORY_LIST_FAILURE;
  const successConstant = faqConstants.GET_FAQ_CATEGORY_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get FAQ Category list. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* setFaqCategory(action) {
  const api = faqApi.setFaqCategoryApi;
  const failureConstant = faqConstants.SET_FAQ_CATEGORY_FAILURE;
  const successConstant = faqConstants.SET_FAQ_CATEGORY_SUCCESS;
  const flag = true;
  const msg = "unable to add FAQ Category. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* removeFaqCategory(action) {
  const api = faqApi.removeFaqCategoryApi;
  const failureConstant = faqConstants.REMOVE_FAQ_CATEGORY_FAILURE;
  const successConstant = faqConstants.REMOVE_FAQ_CATEGORY_SUCCESS;
  const flag = true;
  const msg = "unable to remove FAQ Category. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* updateCategory(action) {
  const api = faqApi.updateCategoryApi;
  const failureConstant = faqConstants.UPDATE_FAQ_CATEGORY_FAILURE;
  const successConstant = faqConstants.UPDATE_FAQ_CATEGORY_SUCCESS;
  const flag = true;
  const msg = "unable to update FAQ Category. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* addFaqToCategory(action) {
  const api = faqApi.addFaqToCategoryApi;
  const failureConstant = faqConstants.ADD_FAQ_TO_CATEGORY_FAILURE;
  const successConstant = faqConstants.ADD_FAQ_TO_CATEGORY_SUCCESS;
  const flag = true;
  const msg = "unable to add Question & Answer. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getFaqList(action) {
  const api = faqApi.getFaqListApi;
  const failureConstant = faqConstants.GET_FAQ_LIST_FAILURE;
  const successConstant = faqConstants.GET_FAQ_LIST_SUCCESS;
  const flag = true;
  //const msg = "unable to get FAQ List. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag
    //msg
  );
}

function* updateFaq(action) {
  const api = faqApi.updateFaqApi;
  const failureConstant = faqConstants.UPDATE_FAQ_FAILURE;
  const successConstant = faqConstants.UPDATE_FAQ_SUCCESS;
  const flag = true;
  const msg = "unable to update FAQ. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* removeFaq(action) {
  const api = faqApi.removeFaqApi;
  const failureConstant = faqConstants.REMOVE_FAQ_FAILURE;
  const successConstant = faqConstants.REMOVE_FAQ_SUCCESS;
  const flag = true;
  const msg = "unable to delete FAQ. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getAllQuestionAnswer(action) {
  const api = faqApi.getAllQuestionAnswerApi;
  const failureConstant = faqConstants.GET_ALL_QUESTION_ANSWER_FAILURE;
  const successConstant = faqConstants.GET_ALL_QUESTION_ANSWER_SUCCESS;
  const flag = true;
  // const msg =
  //   "unable to get All Question and Answer. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag
    //msg
  );
}
