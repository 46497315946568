/**
 * @Desc Wrapper for client management data manipulation & pasisng it to the store.
 */

import { clientConstants } from "../_constants/client.constant";
import _ from "lodash";

const initialState = {
  totalPages: 0,
  searchDeviceList: [],
  clientDetails:"",
  clientLocationList: [],
  clientList: [],
  locationList: [],
  updateRes: undefined,
  loading: false
};

export function clientReducer(state = initialState, action) {
  switch (action.type) {
    case clientConstants.REQUEST:
      return {
        ...state,
        updateRes: undefined,
        loading: true,
        clientDetails:"",
        tempClientLogoData:"",
      };
    case clientConstants.UNMOUNT_CLIENT_MANAGEMENT:
      return {
        ...state,
        clientDetails: undefined,
        clientLocationList: undefined,
        clientList: undefined,
        locationList: undefined,
        updateRes: undefined,
      };
    case clientConstants.UNMOUNT_VIEW_CLIENT:
      return {
        ...state,
        updateRes: undefined,
        clientDetails: action.payload
      };
    case clientConstants.GET_CLIENT_LOCATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        updateRes: undefined,
        totalPages: action.payload.payload.totalPages,
        clientLocationList: action.payload.payload.client
      };
    case clientConstants.GET_CLIENT_LOCATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case clientConstants.GET_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateRes: undefined,
        clientDetails: action.payload
      };
    case clientConstants.GET_CLIENT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case clientConstants.GET_CM_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        updateRes: undefined,
        clientList: formatClientList(action.payload)
      };
    case clientConstants.GET_CM_CLIENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case clientConstants.GET_CM_LOCATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        updateRes: undefined,
        locationList: formatLocationList(action.payload)
      };
    case clientConstants.GET_CM_LOCATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case clientConstants.UPLOAD_CLIENT_LOGO_TEMP_SUCCESS:
      return {
        ...state,
        loading: false,
        tempClientLogoData: action.payload,
        updateRes: undefined
      };
    case clientConstants.UPLOAD_CLIENT_LOGO_TEMP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case clientConstants.UPDATE_CM_CONTRACT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        updateRes: action.payload
      };
    case clientConstants.UPDATE_CM_CONTRACT_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}

function formatClientList(clientList) {
  let clients = clientList.payload.client;

  _.map(clients, client => {
    client["value"] = client["clientId"];
    client["text"] = client["clientName"];
    client["clientid"] = client["clientId"];
    client["clientname"] = client["clientName"];
    delete client["clientId"];
    delete client["clientName"];
    delete client['managed'];
  });
  return clients;
}

function formatLocationList(locationList) {
  let locations = locationList.payload.client;

  _.map(locations, location => {
    location["value"] = location["locationId"];
    if (location["location"]["addressLine2"] === "") {
      location["text"] =
        location["location"]["addressLine1"] +
        " " +
        location["location"]["city"] +
        " " +
        location["location"]["state"] +
        "," +
        location["location"]["country"] +
        " " +
        location["location"]["zipCode"];
    } else {
      location["text"] =
        location["location"]["addressLine1"] +
        " " +
        location["location"]["addressLine2"] +
        " " +
        location["location"]["city"] +
        " " +
        location["location"]["state"] +
        "," +
        location["location"]["country"] +
        " " +
        location["location"]["zipCode"];
    }

    delete location["locationId"];
    delete location["location"];
  });
  return locations;
}
