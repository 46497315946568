import { authConstants } from "../_constants/auth.constant";

export const authActions = {
  login,
  logout
};

function login(email, password) {
  return {
    type: authConstants.LOGIN_REQUEST,
    payload: {
      email,
      password
    }
  };
}

function logout() {
  return {
    type: authConstants.LOGOUT
  };
}
