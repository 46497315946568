import {
  costCenterConstants
} from "../_constants/costCenter.constants";


const initialState = {
  totalPages: 0,
  costCenterList: [],
  costCenterViewList: [],
  costCenterUnAllocatedDeviceList: [],
  updateRes: undefined,
  loading: false
};

export function costCenterReducer(state = initialState, action) {
  switch (action.type) {
    case costCenterConstants.GET_COST_CENTER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
          updateRes: undefined,
          totalPages: action.payload.payload.totalPages,
          costCenterList: action.payload.payload.costCenterList
      };
    case costCenterConstants.GET_COST_CENTER_UNALLOCATED_DEVICE_LIST_SUCCESS:
      console.log("sagacost suss", action.payload.payload.deviceList)
      return {
        ...state,
        loading: false,
          updateRes: undefined,
          // totalPages: action.payload.payload.totalPages,
          costCenterUnAllocatedDeviceList: action.payload.payload.deviceList
      };
    case costCenterConstants.GET_COST_CENTER_UNALLOCATED_DEVICE_LIST_FAILURE:
      console.log("sagacost fail")

      return {
        ...state,
        loading: false,
          costCenterUnAllocatedDeviceList: []
      };

    case costCenterConstants.CREATE_COST_CENTER_SUCCESS:
      return {
        ...state,
        loading: false,
          updateRes: undefined,
          // totalPages: action.payload.payload.totalPages,
          // costCenterList: action.payload.payload.
      };
    case costCenterConstants.VIEW_COST_CENTER_SUCCESS:
      return {
        ...state,
        loading: false,
          updateRes: undefined,
          // totalPages: action.payload.payload.totalPages,
          costCenterViewList: action.payload.payload
      };
    case costCenterConstants.UPDATE_COST_CENTER_SUCCESS:
      return {
        ...state,
        loading: false,
          updateRes: undefined,
          // totalPages: action.payload.payload.totalPages,
          // costCenterList: action.payload.payload.
      };
    default:
      return state;
  }
}