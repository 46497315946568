/**
 * @Desc Wrapper for auth related request, login request
 */

import { UserResources } from "../_resources/user.resources";
import { requestHandler } from "../_helpers/requestHandler";

export const authApi = {
  loginRequest
};
 
function loginRequest(request) {
  let url = UserResources.getUserLoginUrl().url;
  return requestHandler.postService(url, request);
}

// function logoutRequest() {
//   let url = UserResources.getUserLoginUrl().url;
//   return requestHandler.getService(url);
// }
