/**
 * @Desc Wrapper for app route history being used by the redux provider to route properly.
 */

import {
    createBrowserHistory
} from 'history';

export const history = createBrowserHistory();

// export const history = createBrowserHistory({baseUrl: '/baseUrl'}); this is used when we have deployed this build in a folder called baseUrl on server.