/**
 * @Desc Wrapper for asset constans. For all asset management API calls related contants being defiend here.
 */

export const assetConstants = {
  REQUEST: "ASSET_REQUEST",
  SUCCESS: "ASSET_SUCCESS",
  FAILURE: "ASSET_FAILURE",

  UNMOUNT_ASSET_CATEGORY_LIST: "UNMOUNT_ASSET_CATEGORY_LIST",

  GET_ASSET_DEVICE_LIST: "GET_ASSET_DEVICE_LIST",
  GET_ASSET_DEVICE_LIST_SUCCESS: "GET_ASSET_DEVICE_LIST_SUCCESS",
  GET_ASSET_DEVICE_LIST_FAILURE: "GET_ASSET_DEVICE_LIST_FAILURE",

  GET_ASSET_CATEGORY_LIST: "GET_ASSET_CATEGORY_LIST",
  GET_ASSET_CATEGORY_LIST_SUCCESS: "GET_ASSET_CATEGORY_LIST_SUCCESS",
  GET_ASSET_CATEGORY_LIST_FAILURE: "GET_ASSET_CATEGORY_LIST_FAILURE",

  GET_ASSET_CATEGORY_INFO: "GET_ASSET_CATEGORY_INFO",
  GET_ASSET_CATEGORY_INFO_SUCCESS: "GET_ASSET_CATEGORY_INFO_SUCCESS",
  GET_ASSET_CATEGORY_INFO_FAILURE: "GET_ASSET_CATEGORY_INFO_FAILURE",

  GET_ASSET_TAG_LIST: "GET_ASSET_TAG_LIST",
  GET_ASSET_TAG_LIST_SUCCESS: "GET_ASSET_TAG_LIST_SUCCESS",
  GET_ASSET_TAG_LIST_FAILURE: "GET_ASSET_TAG_LIST_FAILURE",

  CREATE_ASSET: "CREATE_ASSET",
  CREATE_ASSET_SUCCESS: "CREATE_ASSET_SUCCESS",
  CREATE_ASSET_FAILURE: "CREATE_ASSET_FAILURE",

  GET_VIEW_ASSET_INFO: " GET_VIEW_ASSET_INFO",
  GET_VIEW_ASSET_INFO_SUCCESS: "GET_VIEW_ASSET_INFO_SUCCESS",
  GET_VIEW_ASSET_INFO_FAILURE: "GET_VIEW_ASSET_INFO_FAILURE",

  UPDATE_ASSET: "UPDATE_ASSET",
  UPDATE_ASSET_SUCCESS: "UPDATE_ASSET_SUCCESS",
  UPDATE_ASSET_FAILURE: "UPDATE_ASSET_FAILURE",
};
