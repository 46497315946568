/**
 * @Desc Wrapper for device constans. For all SiteView & device API calls related contants being defined here.
 */

export const deviceConstants = {
  REQUEST: "DEVICE_REQUEST",
  SUCCESS: "DEVICE_SUCCESS",
  FAILURE: "DEVICE_FAILURE",

  UNMOUNT_SITE_VIEW: "UNMOUNT_SITE_VIEW",

  UNMOUNT_EDIT_MODEL_VIEW: "UNMOUNT_EDIT_MODEL_VIEW",

  UNMOUNT_DEVICE_DETAIL_VIEW: "UNMOUNT_DEVICE_DETAIL_VIEW",

  UNMOUNT_EDIT_DEVICE_VIEW: "UNMOUNT_EDIT_DEVICE_VIEW",

  UNMOUNT_FULL_DEVICE_LIST: "UNMOUNT_FULL_DEVICE_LIST",

  UNMOUNT_UPDATED_DEVICE_STATUS: "UNMOUNT_UPDATED_DEVICE_STATUS",

  UNMOUNT_FULL_DEVICE_MODEL_LIST: "UNMOUNT_FULL_DEVICE_MODEL_LIST",

  UNMOUNT_DEVICE_DETAIL_INFO: "UNMOUNT_DEVICE_DETAIL_INFO",

  GET_DEVICE_SEARCH_LIST: "GET_DEVICE_SEARCH_LIST",
  GET_DEVICE_SEARCH_LIST_SUCCESS: "GET_DEVICE_SEARCH_LIST_SUCCESS",
  GET_DEVICE_SEARCH_LIST_FAILURE: "GET_DEVICE_SEARCH_LIST_FAILURE",

  GET_CLIENT_DEVICE_DETAILS: "GET_CLIENT_DEVICE_DETAILS",
  GET_CLIENT_DEVICE_DETAILS_SUCCESS: "GET_CLIENT_DEVICE_DETAILS_SUCCESS",
  GET_CLIENT_DEVICE_DETAILS_FAILURE: "GET_CLIENT_DEVICE_DETAILS_FAILURE",

  GET_CLIENT_DEVICE_LIST: "GET_CLIENT_DEVICE_LIST",
  GET_CLIENT_DEVICE_LIST_SUCCESS: "GET_CLIENT_DEVICE_LIST_SUCCESS",
  GET_CLIENT_DEVICE_LIST_FAILURE: "GET_CLIENT_DEVICE_LIST_FAILURE",

  GET_FULL_CLIENT_DEVICE_LIST: "GET_FULL_CLIENT_DEVICE_LIST",
  GET_FULL_CLIENT_DEVICE_LIST_SUCCESS: "GET_FULL_CLIENT_DEVICE_LIST_SUCCESS",
  GET_FULL_CLIENT_DEVICE_LIST_FAILURE: "GET_FULL_CLIENT_DEVICE_LIST_FAILURE",

  GET_SV_CLIENT_LIST: "GET_SV_CLIENT_LIST",
  GET_SV_CLIENT_LIST_SUCCESS: "GET_SV_CLIENT_LIST_SUCCESS",
  GET_SV_CLIENT_LIST_FAILURE: "GET_SV_CLIENT_LIST_FAILURE",

  GET_SV_LOCATION_LIST: "GET_SV_LOCATION_LIST",
  GET_SV_LOCATION_LIST_SUCCESS: "GET_SV_LOCATION_LIST_SUCCESS",
  GET_SV_LOCATION_LIST_FAILURE: "GET_SV_LOCATION_LIST_FAILURE",

  GET_DEVICE_MODEL_LIST: "GET_DEVICE_MODEL_LIST",
  GET_DEVICE_MODEL_LIST_SUCCESS: "GET_DEVICE_MODEL_LIST_SUCCESS",
  GET_DEVICE_MODEL_LIST_FAILURE: "GET_DEVICE_MODEL_LIST_FAILURE",

  GET_FULL_DEVICE_MODEL_LIST: "GET_FULL_DEVICE_MODEL_LIST",
  GET_FULL_DEVICE_MODEL_LIST_SUCCESS: "GET_FULL_DEVICE_MODEL_LIST_SUCCESS",
  GET_FULL_DEVICE_MODEL_LIST_FAILURE: "GET_FULL_DEVICE_MODEL_LIST_FAILURE",

  GET_DEVICE_MODEL_INFO: "GET_DEVICE_MODEL_INFO",
  GET_DEVICE_MODEL_INFO_SUCCESS: "GET_DEVICE_MODEL_INFO_SUCCESS",
  GET_DEVICE_MODEL_INFO_FAILURE: "GET_DEVICE_MODEL_INFO_FAILURE",

  UPLOAD_ASSET_IMAGE_TEMP: "UPLOAD_ASSET_IMAGE_TEMP",
  UPLOAD_ASSET_IMAGE_TEMP_SUCCESS: "UPLOAD_ASSET_IMAGE_TEMP_SUCCESS",
  UPLOAD_ASSET_IMAGE_TEMP_FAILURE: "UPLOAD_ASSET_IMAGE_TEMP_FAILURE",

  UPDATE_DEVICE_MODEL_INFO: "UPDATE_DEVICE_MODEL_INFO",
  UPDATE_DEVICE_MODEL_INFO_SUCCESS: "UPDATE_DEVICE_MODEL_INFO_SUCCESS",
  UPDATE_DEVICE_MODEL_INFO_FAILURE: "UPDATE_DEVICE_MODEL_INFO_FAILURE",

  RESET_DEVICE_DATA: "RESET_DEVICE_DATA",

  UPDATE_DEVICE_CONTRACT_INFO: "UPDATE_DEVICE_CONTRACT_INFO",
  UPDATE_DEVICE_CONTRACT_INFO_SUCCESS: "UPDATE_DEVICE_CONTRACT_INFO_SUCCESS",
  UPDATE_DEVICE_CONTRACT_INFO_FAILURE: "UPDATE_DEVICE_CONTRACT_INFO_FAILURE",

  GET_CLIENT_COST_CENTER_LIST: "GET_CLIENT_COST_CENTER_LIST",
  GET_CLIENT_COST_CENTER_LIST_SUCCESS: "GET_CLIENT_COST_CENTER_LIST_SUCCESS",
  GET_CLIENT_COST_CENTER_LIST_FAILURE: "GET_CLIENT_COST_CENTER_LIST_FAILURE",

  UPDATE_DEVICE_BASIC_INFO: "UPDATE_DEVICE_BASIC_INFO",
  UPDATE_DEVICE_BASIC_INFO_SUCCESS: "UPDATE_DEVICE_BASIC_INFO_SUCCESS",
  UPDATE_DEVICE_BASIC_INFO_FAILURE: "UPDATE_DEVICE_BASIC_INFO_FAILURE",

  GET_DEVICE_USAGE_INFO: "GET_DEVICE_USAGE_INFO",
  GET_DEVICE_USAGE_INFO_SUCCESS: "GET_DEVICE_USAGE_INFO_SUCCESS",
  GET_DEVICE_USAGE_INFO_FAILURE: "GET_DEVICE_USAGE_INFO_FAILURE",

  SET_DEVICE_IN_STOAGE: "SET_DEVICE_IN_STOAGE",
  SET_DEVICE_IN_STOAGE_SUCCESS: "SET_DEVICE_IN_STOAGE_SUCCESS",
  SET_DEVICE_IN_STOAGE_FAILURE: "SET_DEVICE_IN_STOAGE_FAILURE",

  GET_CONTRACT_DETAILS: "GET_CONTRACT_DETAILS",
  GET_CONTRACT_DETAILS_SUCCESS: "GET_CONTRACT_DETAILS_SUCCESS",
  GET_CONTRACT_DETAILS_FAILURE: "GET_CONTRACT_DETAILS_FAILURE,",

  UPLOAD_DEVICE_CONTRACT_FILE: "UPLOAD_DEVICE_CONTRACT_FILE",
  UPLOAD_DEVICE_CONTRACT_FILE_SUCCESS: "UPLOAD_DEVICE_CONTRACT_FILE_SUCCESS",
  UPLOAD_DEVICE_CONTRACT_FILE_FAILURE: "UPLOAD_DEVICE_CONTRACT_FILE_FAILURE",

  UPDATE_DEVICE_USAGE_INFO: "UPDATE_DEVICE_USAGE_INFO",
  UPDATE_DEVICE_USAGE_INFO_SUCCESS: "UPDATE_DEVICE_USAGE_INFO_SUCCESS",
  UPDATE_DEVICE_USAGE_INFO_FAILURE: "UPDATE_DEVICE_USAGE_INFO_FAILURE",

  UPDATE_CONTRACT_DETAILS: "UPDATE_CONTRACT_DETAILS",
  UPDATE_CONTRACT_DETAILS_SUCCESS: "UPDATE_CONTRACT_DETAILS_SUCCESS",
  UPDATE_CONTRACT_DETAILS_FAILURE: "UPDATE_CONTRACT_DETAILS_FAILURE",

  GET_DEVICE_IMAGES: "GET_DEVICE_IMAGES",
  GET_DEVICE_IMAGES_SUCCESS: "GET_DEVICE_IMAGES_SUCCESS",
  GET_DEVICE_IMAGES_FAILURE: "GET_DEVICE_IMAGES_FAILURE",

  UPLOAD_DEVICE_IMAGE: "UPLOAD_DEVICE_IMAGE",
  UPLOAD_DEVICE_IMAGE_SUCCESS: "UPLOAD_DEVICE_IMAGE_SUCCESS",
  UPLOAD_DEVICE_IMAGE_FAILURE: "UPLOAD_DEVICE_IMAGE_FAILURE",

  DOWNLOAD_CONTRACT_FILE: "DOWNLOAD_CONTRACT_FILE",
  DOWNLOAD_CONTRACT_FILE_SUCCESS: "DOWNLOAD_CONTRACT_FILE_SUCCESS",
  DOWNLOAD_CONTRACT_FILE_FAILURE: "DOWNLOAD_CONTRACT_FILE_FAILURE",

  GET_DEVICE_USAGE_CHART_INFO: "GET_DEVICE_USAGE_CHART_INFO",
  GET_DEVICE_USAGE_CHART_INFO_SUCCESS: "GET_DEVICE_USAGE_CHART_INFO_SUCCESS",
  GET_DEVICE_USAGE_CHART_INFO_FAILURE: "GET_DEVICE_USAGE_CHART_INFO_FAILURE",

  UNMOUNT_UPLOADED_FILE_STATUS: "UNMOUNT_UPLOADED_FILE_STATUS",

  REMOVE_DEVICE_IMAGE: "REMOVE_DEVICE_IMAGE",
  REMOVE_DEVICE_IMAGE_SUCCESS: "REMOVE_DEVICE_IMAGE_SUCCESS",
  REMOVE_DEVICE_IMAGE_FAILURE: "REMOVE_DEVICE_IMAGE_FAILURE",

  UNMOUNT_DOWNLOAD_CONTRACT_FILE: "UNMOUNT_DOWNLOAD_CONTRACT_FILE",
  SAVE_FILTERS_LOG_OUT:"SAVE_FILTERS_LOG_OUT",
  SAVE_FILTERS_LOG_OUT_SUCCESS:"SAVE_FILTERS_LOG_OUT_SUCCESS",
  SAVE_FILTERS_LOG_OUT_FAILURE:"SAVE_FILTERS_LOG_OUT_FAILURE",

  GET_SAVE_FILTER_STORED:"GET_SAVE_FILTER_STORED",
  GET_SAVE_FILTER_STORED_SUCCESS:"GET_SAVE_FILTER_STORED_SUCCESS",
  GET_SAVE_FILTER_STORED_FAILURE:"GET_SAVE_FILTER_STORED_FAILURE"
};
