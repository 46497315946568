/**
 * @Desc Wrapper for world view data manipulation & pasisng it to the store.
 */

import {
    worldviewConstants
} from '../_constants/worldview.constant';
import _ from 'lodash';

const initialState = {
    clientGeoJsonList: undefined,
    clientList: [],
    locationList: []
}

export function worldviewReducer(state = initialState, action) {
    switch (action.type) {
        case worldviewConstants.REQUEST:
            return {
                ...state,
                loading: true
            };
        case worldviewConstants.UNMOUNT_WORLD_VIEW:
            return {
                clientList: [],
                locationList: [],
                clientGeoJsonList: undefined,
                machineList: undefined,
                loading: false
            };
        case worldviewConstants.GET_CLIENT_GEOJSON_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                clientGeoJsonList: action.payload.payload.geoData,
                clientList: [],
                locationList: []
            };
        case worldviewConstants.GET_CLIENT_GEOJSON_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case worldviewConstants.GET_WV_CLIENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                clientList: formatClientList(action.payload)
            };
        case worldviewConstants.GET_WV_CLIENT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case worldviewConstants.GET_WV_LOCATION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                locationList: formatLocationList(action.payload)
            };
        case worldviewConstants.GET_WV_LOCATION_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case worldviewConstants.GET_CLIENT_LOCATION_MACHINES_SUCCESS:
            return {
                ...state,
                loading: false,
                machineList: action.payload.payload.machines,
                clientList: [],
                locationList: []
            };
        case worldviewConstants.GET_CLIENT_LOCATION_MACHINES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state
    }
}

function formatClientList(clientList) {
    let clients = clientList.payload.client;

    _.map(clients, (client) => {
        client['value'] = client['clientId'];
        client['text'] = client['clientName'];
        client['clientid'] = client['clientId'];
        client['clientname'] = client['clientName'];
        delete client['clientId'];
        delete client['clientName'];
        delete client['managed'];
    });
    return clients;
}

function formatLocationList(locationList) {
    let locations = locationList.payload.client;

    _.map(locations, (location) => {
        location['value'] = location['locationId'];
        if (location['location']['addressLine2'] === '') {
            location['text'] = location['location']['addressLine1'] + ' ' + location['location']['city'] + ' ' + location['location']['state'] + ',' + location['location']['country'] + ' ' + location['location']['zipCode'];
        } else {
            location['text'] = location['location']['addressLine1'] + ' ' + location['location']['addressLine2'] + ' ' + location['location']['city'] + ' ' + location['location']['state'] + ',' + location['location']['country'] + ' ' + location['location']['zipCode'];
        }
        delete location['locationId'];
        delete location['location'];
    });
    return locations;

}
