import { categoryConstants } from '../_constants/category.constant';
import _ from "lodash";

const initialState = {
    totalPages: 0,
    categoryList: [],
    categoryDetails: "",
    categoryName: "",
    categoryDescription: "",
    categoryInfoField: [],
    clientName:"",
    clientId:"",
}

export function categoryReducer(state=initialState, action) {
    switch(action.type) {
        case categoryConstants.REQUEST:
            return {
                ...state,
                updateRes: undefined,
                loading: true,
            };
        case categoryConstants.GET_CATEGORY_LIST_SUCCESS:
            console.log("from categoryList success", action.payload.payload)
            return {
                ...state,
                updateRes: undefined,
                loading: false,
                totalPages: action.payload.payload.totalPages,
                categoryList: action.payload.payload.categoryList,
            };
        case categoryConstants.GET_CATEGORY_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case categoryConstants.CREATE_CATEGORY_FAILURE:
            console.log("from categoryReducer", action.error)
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case categoryConstants.CREATE_CATEGORY_SUCCESS:
            console.log("from categoryReducer", action.payload)
            return {
                ...state,
                updateRes: action.payload,
                loading: false,
                message: action.payload.payload.message,
                
            }
        case categoryConstants.GET_CATEGORY_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case categoryConstants.GET_CATEGORY_DETAILS_SUCCESS:
            console.log("from getCategoryDetails success",action.payload.payload)
            return {
                ...state,
                updateRes: action.payload,
                loading: false,
                categoryName: action.payload.payload.categoryName,
                categoryDescription: action.payload.payload.categoryDescription,
                categoryInfoField: action.payload.payload.categoryInfoField,
                clientName: action.payload.payload.clientName,
                clientId: action.payload.payload.clientId,
            }
        case categoryConstants.EDIT_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        case categoryConstants.EDIT_CATEGORY_SUCCESS:
            return {
                ...state,
                updateRes: action.payload,
                loading: false,
                message: action.payload.payload.message
            }
        default:
            return state
    }
}