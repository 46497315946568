/**
 * @Desc Wrapper for all saga API calls for world view functionality.
 */

import {
    call,
    put,
    takeLatest
} from "redux-saga/effects";
import {
    worldviewConstants
} from "../_constants/worldview.constant";
import {
    worldviewApi
} from "../_apiCall/worldview.api";
import {
    request
} from "../_helpers/request";
import {
    toaster
} from "../_common/toaster/toaster";
import {
    exceptionHandlerService
} from "../_helpers/exceptionHandler";

export function* worldviewSaga() {
    yield takeLatest(worldviewConstants.GET_CLIENT_GEOJSON_LIST, getClientGeoJsonList);
    yield takeLatest(worldviewConstants.GET_WV_CLIENT_LIST, getClientList);
    yield takeLatest(worldviewConstants.GET_WV_LOCATION_LIST, getLocationList);
    yield takeLatest(worldviewConstants.GET_CLIENT_LOCATION_MACHINES, getClientLocationMachine);
}

/**
 * @Desc Wrapper for all saga function call with the common functionality being defined here.
 */
/* for all request flag= true */
function* sagaFunc(api, action, failureConstant, successConstant, flag, msg) {
    yield put(request.isFetching(worldviewConstants.REQUEST));
    try {
        const {
            response,
            error
        } = flag === true ?
            yield call(api, action.payload): yield call(api);
        if (response !== undefined) {
            if (response.success === true) {
                yield put(request.success(successConstant, response));
            } else if (response.success === false) {
                toaster.notifyError(msg);
                yield put(request.failure(failureConstant, msg));
            }
        } else if (error) {
            exceptionHandlerService.exceptionService(error, msg);
            yield put(request.failure(failureConstant, error));
        }
    } catch (error) {
        exceptionHandlerService.exceptionService(error, msg);
        yield put(request.failure(failureConstant, error.toString()));
    }
}

// worker Saga: will be fired on GET_CLIENT_GEOJSON_LIST actions
function* getClientGeoJsonList(action) {
    const api = worldviewApi.getClientGeoJsonList;
    const failureConstant = worldviewConstants.GET_CLIENT_GEOJSON_LIST_FAILURE;
    const successConstant = worldviewConstants.GET_CLIENT_GEOJSON_LIST_SUCCESS;
    const flag = true;
    const msg = "unable to get client geocodes list. Please try again.";
    yield call(sagaFunc, api, action, failureConstant, successConstant, flag, msg);
}

function* getClientLocationMachine(action) {
    const api = worldviewApi.getClientLocationMachines;
    const failureConstant = worldviewConstants.GET_CLIENT_LOCATION_MACHINES_FAILURE;
    const successConstant = worldviewConstants.GET_CLIENT_LOCATION_MACHINES_SUCCESS;
    const flag = true;
    const msg = "unable to get client machine list. Please try again.";
    yield call(sagaFunc, api, action, failureConstant, successConstant, flag, msg);
}

function* getClientList(action) {
    const api = worldviewApi.getClientList;
    const failureConstant = worldviewConstants.GET_WV_CLIENT_LIST_FAILURE;
    const successConstant = worldviewConstants.GET_WV_CLIENT_LIST_SUCCESS;
    const flag = false;
    const msg = "unable to get client list";
    yield call(sagaFunc, api, action, failureConstant, successConstant, flag, msg);
}

function* getLocationList(action) {
    const api = worldviewApi.getLocationList;
    const failureConstant = worldviewConstants.GET_WV_LOCATION_LIST_FAILURE;
    const successConstant = worldviewConstants.GET_WV_LOCATION_LIST_SUCCESS;
    const flag = true;
    const msg = "unable to get location list";
    yield call(sagaFunc, api, action, failureConstant, successConstant, flag, msg);
}
