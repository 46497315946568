export const categoryConstants = {
    REQUEST: "CLIENT_REQUEST",
    SUCCESS: "CLIENT_SUCCESS",
    FAILURE: "CLIENT_FAILURE",

    CREATE_CATEGORY: "CREATE_CATEGORY",
    CREATE_CATEGORY_SUCCESS: "CREATE_CATEGORY_SUCCESS",
    CREATE_CATEGORY_FAILURE: "CREATE_CATEGORY_FAILURE",

    GET_CATEGORY_LIST : "GET_CATEGORY_LIST",
    GET_CATEGORY_LIST_SUCCESS: "GET_CATEGORY_LIST_SUCCESS",
    GET_CATEGORY_LIST_FAILURE: "GET_CATEGORY_LIST_FAILURE",

    GET_CATEGORY_DETAILS: "GET_CATEGORY_DETAILS",
    GET_CATEGORY_DETAILS_SUCCESS: "GET_CATEGORY_DETAILS_SUCESS",
    GET_CATEGORY_DETAILS_FAILURE: "GET_CATEGORY_DETAILS_FAILURE",

    EDIT_CATEGORY: "EDIT_CATEGORY",
    EDIT_CATEGORY_SUCCESS: "EDIT_CATEGORY_SUCCESS",
    EDIT_CATEGORY_FAILURE: "EDIT_CATEGORY_FAILURE",
}