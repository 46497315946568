/**
 * @Desc Wrapper for client constans. For all client management API calls related contants being defiend here.
 */

export const clientConstants = {
  REQUEST: "CLIENT_REQUEST",
  SUCCESS: "CLIENT_SUCCESS",
  FAILURE: "CLIENT_FAILURE",

  UNMOUNT_CLIENT_MANAGEMENT: 'UNMOUNT_CLIENT_MANAGEMENT',

  UNMOUNT_VIEW_CLIENT: 'UNMOUNT_VIEW_CLIENT',

  GET_CLIENT_LOCATION_LIST: "GET_CLIENT_LOCATION_LIST",
  GET_CLIENT_LOCATION_LIST_SUCCESS: "GET_CLIENT_LOCATION_LIST_SUCCESS",
  GET_CLIENT_LOCATION_LIST_FAILURE: "GET_CLIENT_LOCATION_LIST_FAILURE",

  GET_CLIENT_DETAILS: "GET_CLIENT_DETAILS",
  GET_CLIENT_DETAILS_SUCCESS: "GET_CLIENT_DETAILS_SUCCESS",
  GET_CLIENT_DETAILS_FAILURE: "GET_CLIENT_DETAILS_FAILURE",

  GET_CM_CLIENT_LIST: "GET_CM_CLIENT_LIST",
  GET_CM_CLIENT_LIST_SUCCESS: "GET_CM_CLIENT_LIST_SUCCESS",
  GET_CM_CLIENT_LIST_FAILURE: "GET_CM_CLIENT_LIST_FAILURE",

  GET_CM_LOCATION_LIST: "GET_CM_LOCATION_LIST",
  GET_CM_LOCATION_LIST_SUCCESS: "GET_CM_LOCATION_LIST_SUCCESS",
  GET_CM_LOCATION_LIST_FAILURE: "GET_CM_LOCATION_LIST_FAILURE",

  UPLOAD_CLIENT_LOGO_TEMP: "UPLOAD_CLIENT_LOGO_TEMP",
  UPLOAD_CLIENT_LOGO_TEMP_SUCCESS: "UPLOAD_CLIENT_LOGO_TEMP_SUCCESS",
  UPLOAD_CLIENT_LOGO_TEMP_FAILURE: "UPLOAD_CLIENT_LOGO_TEMP_FAILURE",

  UPDATE_CM_CONTRACT_INFO: "UPDATE_CM_CONTRACT_INFO",
  UPDATE_CM_CONTRACT_INFO_SUCCESS: "UPDATE_CM_CONTRACT_INFO_SUCCESS",
  UPDATE_CM_CONTRACT_INFO_FAILURE: "UPDATE_CM_CONTRACT_INFO_FAILURE"
};
