/**
 * @Desc Wrapper for storing API urls for Green Report.
 */

import {
    GlobalVariable
} from "../_constants/url.constants";
const baseUrl = GlobalVariable.baseUrl;
const projectName = GlobalVariable.projectName;
const ssl = GlobalVariable.ssl;

export const GreenReportResources = {
    getGreenReportDetailsUrl
};

function getGreenReportDetailsUrl() {
    console.log("greenreport resource url");
    return {
        url: ssl +
            "://" +
            baseUrl +
            "/" +
            projectName +
            "/kbt/bridge/api/v2/greenreport/get"
        // url: "https://run.mocky.io/v3/3fc29ebc-ce0e-4dbd-82aa-d7614483de2e"
        // url: ssl + "://" + baseUrl + "/" + projectName + "/kbt/bridge/api/v1/clients"
    };
}