import _ from "lodash";
import { faqConstants } from "../_constants/faq.constant";

let initialState = {};

export function faqReducer(state = initialState, action) {
  switch (action.type) {
    case faqConstants.REQUEST:
      return {
        ...state,
        faqList: undefined,
      };
    case faqConstants.UNMOUNT_FAQ_LIST:
      return {
        ...state,
        loading: true,
      };
    case faqConstants.GET_FAQ_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        faqCategoryList: action.payload.payload,
      };
    case faqConstants.GET_FAQ_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case faqConstants.SET_FAQ_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        faqCategoryAddStatus: action.payload,
      };
    case faqConstants.SET_FAQ_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case faqConstants.REMOVE_FAQ_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        faqCategoryRemoveStatus: action.payload,
      };
    case faqConstants.REMOVE_FAQ_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case faqConstants.UPDATE_FAQ_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        faqCategoryUpdateStatus: action.payload,
      };
    case faqConstants.UPDATE_FAQ_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case faqConstants.ADD_FAQ_TO_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        faqAddStatus: action.payload,
      };
    case faqConstants.ADD_FAQ_TO_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case faqConstants.GET_FAQ_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        faqList: action.payload.payload,
      };
    case faqConstants.GET_FAQ_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case faqConstants.UPDATE_FAQ_SUCCESS:
      return {
        ...state,
        loading: false,
        faqUpdateStatus: action.payload,
      };
    case faqConstants.UPDATE_FAQ_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case faqConstants.REMOVE_FAQ_SUCCESS:
      return {
        ...state,
        loading: false,
        faqRemoveStatus: action.payload,
      };
    case faqConstants.REMOVE_FAQ_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case faqConstants.GET_ALL_QUESTION_ANSWER_SUCCESS:
      return {
        ...state,
        loading: false,
        allQuestionAnswerList: action.payload.payload,
      };
    case faqConstants.GET_ALL_QUESTION_ANSWER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
