/**
 * @Desc Wrapper for users constans. For all world view API calls related contants being defined here.
 */

export const worldviewConstants = {
    REQUEST: "WORLDVIEW_REQUEST",
    SUCCESS: "WORLDVIEW_SUCCESS",
    FAILURE: "WORLDVIEW_FAILURE",

    UNMOUNT_WORLD_VIEW: "UNMOUNT_WORLD_VIEW",

    GET_WV_CLIENT_LIST: "GET_WV_CLIENT_LIST",
    GET_WV_CLIENT_LIST_SUCCESS: "GET_WV_CLIENT_LIST_SUCCESS",
    GET_WV_CLIENT_LIST_FAILURE: "GET_WV_CLIENT_LIST_FAILURE",

    GET_WV_LOCATION_LIST: "GET_WV_LOCATION_LIST",
    GET_WV_LOCATION_LIST_SUCCESS: "GET_WV_LOCATION_LIST_SUCCESS",
    GET_WV_LOCATION_LIST_FAILURE: "GET_WV_LOCATION_LIST_FAILURE",

    GET_CLIENT_GEOJSON_LIST: 'GET_CLIENT_GEOJSON_LIST',
    GET_CLIENT_GEOJSON_LIST_SUCCESS: "GET_CLIENT_GEOJSON_LIST_SUCCESS",
    GET_CLIENT_GEOJSON_LIST_FAILURE: "GET_CLIENT_GEOJSON_LIST_FAILURE",

    GET_CLIENT_LOCATION_MACHINES: 'GET_CLIENT_LOCATION_MACHINES',
    GET_CLIENT_LOCATION_MACHINES_SUCCESS: "GET_CLIENT_LOCATION_MACHINES_SUCCESS",
    GET_CLIENT_LOCATION_MACHINES_FAILURE: "GET_CLIENT_LOCATION_MACHINES_FAILURE"
}