import React from 'react';
import {AppRouteComponent} from '../app.routing';
import './App.scss';
import "react-toastify/dist/ReactToastify.css";

/**
 * @Desc For application flow/clarity go through these points.
 */

/* This will the initial file to load and all routes will pass through this
mentioned in the app-routing file. */
/* _action will contain all the action Creator functions used in this application. */
/* _apiCall contain all the api call function which is being linked to requested handler to handle request(axios) being made by user. */
/* _common contain all the components common to whole application. */
/* _contants contains all the contant mapped to a variales and info of global varibles  used in the application. */
/* _helpers contian all the helper files being used in the application globally & not attached to a single functionality like exception handler, security service etc. */
/* _reducers contain all the reducers to manipulate data coming from the api call in order to send them to the store to send them to the respected containers. */
/* _resources contain the wrapper function for all api url used in the application. */
/* _routing contain   all the routing files required for the application. */
/* assets contain all the assets/imges/icons being used the application. */
/* components contains all the dummy components/ stateless components. */
/* containers contains all the smart components being used the application. */
/* sagas contains all the saga for making API call in the application for the redux flow. */
/* services contain other sservice files for manipulating/ sharing the data. */
/* styles contains all the styles available in the application. */
/* app.routing.jsx contain initial level of routing for the application. */

const App = () => {
    return (
        <React.Fragment>
            <AppRouteComponent/>
        </React.Fragment>
    );
}
export default App;
