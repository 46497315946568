import { ReportResources } from "../_resources/report.resources";
import { requestHandler } from "../_helpers/requestHandler";

import { GlobalVariable } from "../_constants/url.constants";

export const reportApi = {
  checkTcoReport,
  getDeviceDetails,
  generateTcoReport,
  savePowerUsage,
  generateQBReportApi,
  uploadContractSheetApi,
  getMissingDeviceDetailsApi,
  generateMonthlyUsageReportApi,
  getSupplyOrderDeviceDetailsApi,
  viewSupplyOrderDeviceDetailsApi,
  getCustomReportsApi,
  createCustomReporApi,
  saveCustomReportsApi,
  updateCustomReportsApi,
  deleteCustomReportsApi,
  getReportTypeApi,
  getMeasuresDimensionsApi,
  viewCustomReportsApi,
  getServiceHistoryListApi,
  getServiceHistoryDetailsApi,
  getBasicTCOReportApi,
  getTCOChartApi,
  importMacdFileApi,
  getPageUsageReportApi,
  // getGreenReportList,
  getQBRReportApi,
  getQBRChartApi,
  getFullQBRReportApi,
};

function checkTcoReport(data) {
  let url = ReportResources.checkTcoReport().url;
  return requestHandler.postService(url, data);
}

function savePowerUsage(data) {
  let url = ReportResources.savePowerUsage().url;
  return requestHandler.postService(url, data);
}

function getDeviceDetails(data) {
  let url = ReportResources.getDeviceDetails().url;
  return requestHandler.postService(url, data);
}

function generateTcoReport(data) {
  let url = ReportResources.generateTcoReport().url;
  return requestHandler.postService(url, data);
}

function generateQBReportApi(data) {
  let url = ReportResources.generateQBReportUrl().url;
  return requestHandler.fileUploaderPostService(url, data);
}

function uploadContractSheetApi(data) {
  let url = ReportResources.uploadContractSheetUrl().url;
  return requestHandler.fileUploaderPostService(url, data);
}

function generateMonthlyUsageReportApi(data) {
  let url = ReportResources.generateMonthlyUsageReportUrl().url;
  return requestHandler.fileUploaderPostService(url, data);
}

function getMissingDeviceDetailsApi(data) {
  let url = ReportResources.getMissingDeviceDetailsUrl().url;
  return requestHandler.postService(url, data);
}

function getSupplyOrderDeviceDetailsApi(data) {
  let url = ReportResources.getSupplyOrderDeviceDetailsUrl().url;
  return requestHandler.postService(url, data);
}

//view supply orders api
function viewSupplyOrderDeviceDetailsApi(data) {
  let url = ReportResources.viewSupplyOrderDeviceDetailsUrl().url;
  return requestHandler.postService(url, data);
}

function getCustomReportsApi(data) {
  let url = ReportResources.getCustomReportsUrl().url;
  return requestHandler.postService(url, data);
}

function createCustomReporApi(data) {
  let url = GlobalVariable.pentahoVariables.customReportsUrl;
  return requestHandler.createCustomReportPostService(url, data);
}

function saveCustomReportsApi(data) {
  let url = ReportResources.saveCustomReportsUrl().url;
  return requestHandler.postService(url, data);
}

function updateCustomReportsApi(data) {
  let url = ReportResources.updateCustomReportsUrl().url;
  return requestHandler.postService(url, data);
}

function deleteCustomReportsApi(data) {
  let url = ReportResources.deleteCustomReportsUrl().url;
  return requestHandler.postService(url, data);
}

function getReportTypeApi() {
  let url = ReportResources.getReportTypeUrl().url;
  return requestHandler.getService(url);
}

function getMeasuresDimensionsApi(data) {
  let url = ReportResources.getMeasuresDimensionsUrl().url;
  return requestHandler.postService(url, data);
}

function viewCustomReportsApi(data) {
  let url = ReportResources.viewCustomReportsUrl().url;
  return requestHandler.postService(url, data);
}

function getServiceHistoryListApi(data) {
  let url = ReportResources.getServiceHistoryListUrl().url;
  return requestHandler.postService(url, data);
}

function getServiceHistoryDetailsApi(ticketId) {
  let url = ReportResources.getServiceHistoryDetailsUrl(ticketId).url;
  return requestHandler.getService(url);
}

function getBasicTCOReportApi(data) {
  let url = ReportResources.getBasicTCOReportUrl().url;
  return requestHandler.postService(url, data);
}

function getTCOChartApi(data) {
  let url = ReportResources.getTCOChartUrl().url;
  return requestHandler.postService(url, data);
}

function importMacdFileApi(data) {
  let url = ReportResources.importMacdFileUrl().url;
  return requestHandler.fileUploaderPostService(url, data);
}

function getPageUsageReportApi(data) {
  let url = ReportResources.getPageUsageReportUrl().url;
  return requestHandler.postService(url, data);
}

// function getGreenReportList() {
//   console.log("green url");

//   let url = ReportResources.getGreenReportDetailsUrl().url;
//   console.log("green url", url);
//   return requestHandler.getService(url);
// }

function getQBRReportApi(data) {
  let url = ReportResources.getQBRReportUrl().url;
  return requestHandler.postService(url, data);
}

function getQBRChartApi(data) {
  let url = ReportResources.getQBRChartUrl().url;
  return requestHandler.postService(url, data);
}
function getFullQBRReportApi(data) {
  let url = ReportResources.getFullQBRReportUrl().url;
  return requestHandler.postService(url, data);
}
