import React from "react";
import { Route, Switch, Router } from "react-router-dom";
import PrivateRoute from "./components/private.routing";
import ForgotPasswordPrivateRoute from "./components/forgot_password.routing";
import CreatePasswordPrivateRoute from "./components/forgot_password.routing";

import { history } from "./_helpers/history";

import Loadable from 'react-loadable';
import LoadingComponent from './components/loadingComponent';

// dynamically importing components
const AsyncLogin = Loadable({
                      loader: () => import("./containers/Login/login.container"),
                      loading: LoadingComponent
                    });
const AsyncHome = Loadable({
                      loader: () => import("./containers/Home/home.container"),
                      loading: LoadingComponent
                    });
const AsyncForgotPassword = Loadable({
                      loader: () => import("./containers/Forgot_Password/forgot_password.container"),
                      loading: LoadingComponent
                    });
const AsyncCreatePassword = Loadable({
                      loader: () => import("./containers/Create_Password/create_password.container"),
                      loading: LoadingComponent
                    });
const AsyncPageNotFound = Loadable({
                      loader: () => import("./components/page_not_found/page_note_found.component"),
                      loading: LoadingComponent
                    });


// for all the routes in the application. looping through these routes.
export const AppRouteComponent = () => {
  const samePaths = [
    "/",
    "/home",
    "/home/dashboard",
    "/home/worldview",
    "/home/siteview",
    "/home/reports",
    "/home/support",
    "/home/admin",
    "/home/admin/manage-user",
    "/home/admin/manage-client",
    "/home/admin/manage-asset",
    "/home/admin/manage-floor",
    "/home/admin/admin-tools",
    "/home/admin/email_notifications",
    "/home/admin/manage-cost-center",
    "/home/admin/manage-category"
  ];

  const forgotPassPaths = [
    "/forgot-password",
    "/forgot-password/",
    "/forgot-password/:passToken"
  ];

  const createPassPaths = [
    "/set-password",
    "/set-password/",
    "/set-password/:passToken"
  ];

  const pageNotFoundPath = ["/404", "*"];

  return (
    <Router history={history}>
      <Switch>
        {samePaths.map((path, index) => (
          <PrivateRoute exact path={path} component={AsyncHome} key={index} />
        ))}
        <Route exact path={`/login`} component={AsyncLogin} />{" "}
        {forgotPassPaths.map((path, index) => (
          <ForgotPasswordPrivateRoute
            exact
            path={path}
            component={AsyncForgotPassword}
            key={index}
          />
        ))}
          {createPassPaths.map((path, index) => (
          <CreatePasswordPrivateRoute
            exact
            path={path}
            component={AsyncCreatePassword}
            key={index}
          />
        ))}
        {pageNotFoundPath.map((path, index) => (
          <Route path={path} component={AsyncPageNotFound} key={index} />
        ))}
      </Switch>
    </Router>
  );
};
