// import axios from "axios";
import {
    WorldViewResources
} from "../_resources/worldview.resources";
import {
    requestHandler
} from "../_helpers/requestHandler";

export const worldviewApi = {
    getClientGeoJsonList,
    getClientLocationMachines,
    getClientList,
    getLocationList
}

function getClientGeoJsonList(data) {
    let url = WorldViewResources.getClientGeoJSONListUrl().url;
    return requestHandler.postService(url, data);
}

function getClientLocationMachines(data) {
    let url = WorldViewResources.getClientLocationMachinesUrl(data.locationId, data.clientId).url;
    return requestHandler.getService(url);
}

function getClientList() {
    let url = WorldViewResources.getClientListUrl().url;
    return requestHandler.getService(url);
}

function getLocationList(data) {
    let url = WorldViewResources.getLocationListUrl().url;
    return requestHandler.postService(url, data)
}
