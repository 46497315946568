/**
 * @Desc For creating a store and connecting it with middleware, saga, reducers.
 */

import {
    createStore,
    applyMiddleware
} from 'redux';
import {
    rootReducer
} from '../_reducers/index';

import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from "redux-thunk";
// import {
//     createLogger
// } from "redux-logger";
import {
    rootSaga
} from '../sagas/index';

// const logger = createLogger();

const sagaMiddleware = createSagaMiddleware();

// const confirmationMiddleware = store => next => action => {
//     // console.log("action, next, store: ", action, next, store);
//     if (
//         action.type === ""
//     ) {
//         let user_input = window.confirm("are you sure?");
//         if (user_input === true) {
//             next(action);
//         }
//     } else {
//         next(action);
//     }
// }

const middlewares = applyMiddleware(sagaMiddleware, thunkMiddleware)

export const store = createStore(rootReducer, middlewares);

sagaMiddleware.run(rootSaga);