export const emailNotificationConstants = {

    REQUEST: "EMAIL_NOTIFICATION_REQUEST",
    SUCCESS: "EMAIL_NOTIFICATION_SUCCESS",
    FAILURE: "EMAIL_NOTIFICATION_FAILURE",

    UNMOUNT_EMAIL_NOTIFICATION_USER_LIST: 'UNMOUNT_EMAIL_NOTIFICATION_USER_LIST',
    UNMOUNT_EMAIL_NOTIFICATION_CLIENT_LIST: 'UNMOUNT_EMAIL_NOTIFICATION_CLIENT_LIST',

    GET_EMAIL_NOTIFICATION_USER_LIST: "GET_EMAIL_NOTIFICATION_USER_LIST",
    GET_EMAIL_NOTIFICATION_USER_LIST_SUCCESS: "GET_EMAIL_NOTIFICATION_USER_LIST_SUCCESS",
    GET_EMAIL_NOTIFICATION_USER_LIST_FAILURE: "GET_EMAIL_NOTIFICATION_USER_LIST_FAILURE",

    GET_EMAIL_NOTIFICATION_CLIENT_LIST: "GET_EMAIL_NOTIFICATION_CLIENT_LIST",
    GET_EMAIL_NOTIFICATION_CLIENT_LIST_SUCCESS: "GET_EMAIL_NOTIFICATION_CLIENT_LIST_SUCCESS",
    GET_EMAIL_NOTIFICATION_CLIENT_LIST_FAILURE: "GET_EMAIL_NOTIFICATION_CLIENT_LIST_FAILURE",

    TOGGLE_EMAIL_NOTIFICATION_STATUS: "TOGGLE_EMAIL_NOTIFICATION_STATUS",
    TOGGLE_EMAIL_NOTIFICATION_STATUS_SUCCESS: "TOGGLE_EMAIL_NOTIFICATION_STATUS_SUCCESS",
    TOGGLE_EMAIL_NOTIFICATION_STATUS_FAILURE: "TOGGLE_EMAIL_NOTIFICATION_STATUS_FAILURE",

    GET_EMAIL_NOTIFICATION_STATUS_LIST: "GET_EMAIL_NOTIFICATION_STATUS_LIST",
    GET_EMAIL_NOTIFICATION_STATUS_LIST_SUCCESS: "GET_EMAIL_NOTIFICATION_STATUS_LIST_SUCCESS",
    GET_EMAIL_NOTIFICATION_STATUS_LIST_FAILURE: "GET_EMAIL_NOTIFICATION_STATUS_LIST_FAILURE",

    
}