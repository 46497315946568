/**
 * @Desc Wrapper for site view & device management data manipulation & pasisng it to the store.
 */

import { deviceConstants } from "../_constants/device.constant";
import _ from "lodash";

let initialState = {
  clientDeviceList: [],
  clientList: [],
  locationList: [],
  modelList: [],
  totalPages: 0,
  modelTotalPages: 0,
  updateModelInfoRes: undefined,
  tempAssetImageData: undefined,
  clientCostCenter: [],
  loading: false,
  deviceUsageChartList: [],
  savedFilterStored:[]
};

export function deviceReducer(state = initialState, action) {
  switch (action.type) {
    case deviceConstants.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case deviceConstants.UNMOUNT_SITE_VIEW:
      return {
        ...state,
        //clientList: undefined,
        //locationList: undefined,
        totalPages: undefined,
        clientDeviceList: undefined,
        modelTotalPages: undefined,
        modelList: undefined,
        fullClientDeviceList: undefined,
        fullModelList: undefined,
        updateModelInfoRes: undefined,
        tempAssetImageData: undefined,
        updateContractInfoRes: undefined,
        loading: false,
      };
    case deviceConstants.UNMOUNT_EDIT_MODEL_VIEW:
      return {
        ...state,
        updateModelInfoRes: undefined,
        tempAssetImageData: undefined,
        modelData: undefined,
      };
    case deviceConstants.UNMOUNT_DEVICE_DETAIL_VIEW:
      return {
        ...state,
        loading: false,
        deviceDetails: undefined,
        updateContractInfoRes: undefined,
      };
    case deviceConstants.UNMOUNT_DEVICE_DETAIL_INFO:
      return {
        ...state,
        loading: false,
        inStorageDeviceUpdateStatus: undefined,
        deviceDetails: undefined,
        updateContractInfoRes: undefined,
        contractDetails: undefined,
        deviceUsageInfo: undefined,
      };
    case deviceConstants.UNMOUNT_EDIT_DEVICE_VIEW:
      return {
        ...state,
        loading: false,
        updateContractInfoRes: undefined,
      };
    case deviceConstants.UNMOUNT_FULL_DEVICE_LIST:
      return {
        ...state,
        loading: false,
        fullClientDeviceList: undefined,
        exportTo: undefined,
      };
    case deviceConstants.UNMOUNT_FULL_DEVICE_MODEL_LIST:
      return {
        ...state,
        loading: false,
        fullModelList: undefined,
        exportTo: undefined,
      };
    case deviceConstants.GET_CLIENT_DEVICE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        totalPages: action.payload.payload.totalPages,
        clientDeviceList: action.payload.payload.device,
        deviceDetails: undefined,
        updateModelInfoRes: undefined,
        tempAssetImageData: undefined,
        modelList: [],
      };
    case deviceConstants.GET_CLIENT_DEVICE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case deviceConstants.GET_FULL_CLIENT_DEVICE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        fullClientDeviceList: action.payload.payload.device,
        exportTo: action.payload.exportTo,
        deviceDetails: undefined,
        updateModelInfoRes: undefined,
        tempAssetImageData: undefined,
        modelList: [],
      };
    case deviceConstants.GET_FULL_CLIENT_DEVICE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        exportTo: undefined,
        error: action.error,
      };
    case deviceConstants.GET_SV_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        //loading: false,
        clientList: formatClientList(action.payload),
        tempAssetImageData: undefined,
      };
    case deviceConstants.GET_SV_CLIENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case deviceConstants.GET_SV_LOCATION_LIST_SUCCESS:
      return {
        ...state,
        //loading: false,
        locationList: formatLocationList(action.payload),
        tempAssetImageData: undefined,
      };
    case deviceConstants.GET_SV_LOCATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case deviceConstants.GET_CLIENT_DEVICE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        deviceDetails: action.payload.payload,
        updateModelInfoRes: undefined,
        tempAssetImageData: undefined,
        updateContractInfoRes: undefined,
      };
    case deviceConstants.GET_CLIENT_DEVICE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case deviceConstants.GET_DEVICE_MODEL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        updateModelInfoRes: undefined,
        tempAssetImageData: undefined,
        modelTotalPages: action.payload.payload.totalPages,
        modelList: action.payload.payload.modelList,
        clientDeviceList: [],
      };
    case deviceConstants.GET_DEVICE_MODEL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case deviceConstants.GET_FULL_DEVICE_MODEL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        updateModelInfoRes: undefined,
        tempAssetImageData: undefined,
        fullModelList: action.payload.payload.modelList,
        exportTo: action.payload.exportTo,
        clientDeviceList: [],
      };
    case deviceConstants.GET_FULL_DEVICE_MODEL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        exportTo: undefined,
        error: action.error,
      };
    case deviceConstants.GET_DEVICE_MODEL_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        updateModelInfoRes: undefined,
        tempAssetImageData: undefined,
        modelData: action.payload.payload.modelInfo,
      };
    case deviceConstants.GET_DEVICE_MODEL_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case deviceConstants.UPLOAD_ASSET_IMAGE_TEMP_SUCCESS:
      return {
        ...state,
        loading: false,
        tempAssetImageData: action.payload,
        updateModelInfoRes: undefined,
      };
    case deviceConstants.UPLOAD_ASSET_IMAGE_TEMP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case deviceConstants.UPDATE_DEVICE_MODEL_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        updateModelInfoRes: action.payload,
        tempAssetImageData: undefined,
      };
    case deviceConstants.UPDATE_DEVICE_MODEL_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case deviceConstants.UPDATE_DEVICE_CONTRACT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        updateContractInfoRes: action.payload,
      };
    case deviceConstants.UPDATE_DEVICE_CONTRACT_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case deviceConstants.RESET_DEVICE_DATA:
      return {
        ...state,
        locationList: [],
      };
    case deviceConstants.GET_CLIENT_COST_CENTER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        // clientCostCenter: action.payload.payload.costCenterList
        clientCostCenter: formatClientCostCenter(action.payload),
      };
    case deviceConstants.GET_CLIENT_COST_CENTER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case deviceConstants.UPDATE_DEVICE_BASIC_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedDeviceBasicInfo: action.payload,
      };
    case deviceConstants.UNMOUNT_UPDATED_DEVICE_STATUS:
      return {
        ...state,
        loading: false,
        updatedDeviceBasicInfo: undefined,
        inStorageDeviceUpdateStatus: undefined,
        updatedDeviceBasicInfo: undefined,
      };
    case deviceConstants.UPDATE_DEVICE_BASIC_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case deviceConstants.GET_DEVICE_USAGE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        deviceUsageInfo: action.payload.payload,
      };
    case deviceConstants.GET_DEVICE_USAGE_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case deviceConstants.SET_DEVICE_IN_STOAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        inStorageDeviceUpdateStatus: action.payload.payload,
      };
    case deviceConstants.SET_DEVICE_IN_STOAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case deviceConstants.GET_CONTRACT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        contractDetails: action.payload.payload,
      };
    case deviceConstants.GET_CONTRACT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case deviceConstants.UPLOAD_DEVICE_CONTRACT_FILE_SUCCESS:
      console.log("from reducer file upload", action.payload);
      return {
        ...state,
        loading: false,
        uploadDeviceContractFile: action.payload,
      };
    case deviceConstants.UPLOAD_DEVICE_CONTRACT_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case deviceConstants.UPDATE_DEVICE_USAGE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        updateDeviceUsageInfo: action.payload,
      };
    case deviceConstants.UPDATE_DEVICE_USAGE_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case deviceConstants.UPDATE_CONTRACT_DETAILS_SUCCESS:
      console.log("file upload", action.payload);
      return {
        ...state,
        loading: false,
        updateContractDetails: action.payload,
      };
    case deviceConstants.UPDATE_CONTRACT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case deviceConstants.GET_DEVICE_USAGE_CHART_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        deviceUsageChartList: action.payload.payload,
      };
    case deviceConstants.GET_DEVICE_USAGE_CHART_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case deviceConstants.GET_DEVICE_IMAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case deviceConstants.GET_DEVICE_IMAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        deviceImagesUrl: action.payload.payload,
      };
    case deviceConstants.UPLOAD_DEVICE_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        uploadDviceImage: action.payload,
      };
    case deviceConstants.UPLOAD_DEVICE_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case deviceConstants.DOWNLOAD_CONTRACT_FILE_FAILURE:
      console.log("from reducer failue", action.error);
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case deviceConstants.DOWNLOAD_CONTRACT_FILE_SUCCESS:
      console.log("from reducer download", action.payload);
      return {
        ...state,
        loading: false,
        downloadSuccess: action.payload,
        downloadContractFile: action.payload.payload.fileUrl,
        //downloadContractFile: action.payload,
      };
    case deviceConstants.UNMOUNT_UPLOADED_FILE_STATUS:
      return {
        ...state,
        uploadDeviceContractFile: undefined,
      };
    case deviceConstants.REMOVE_DEVICE_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        removeDeviceImage: action.payload,
      };
    case deviceConstants.REMOVE_DEVICE_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case deviceConstants.UNMOUNT_DOWNLOAD_CONTRACT_FILE:
      return {
        ...state,
        loading: false,
        downloadContractFile: undefined,
      };
      case deviceConstants.SAVE_FILTERS_LOG_OUT_SUCCESS:
        return {
          ...state,
          loading: false,
          // contractDetails: action.payload.payload,
        };
      case deviceConstants.SAVE_FILTERS_LOG_OUT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
        case deviceConstants.GET_SAVE_FILTER_STORED_SUCCESS:
          return {
            ...state,
            loading: false,
            savedFilterStored: action.payload.payload,
          };
        case deviceConstants.GET_SAVE_FILTER_STORED_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error,
          };
    default:
      return state;
  }
}

function formatClientList(clientList) {
  let clients = clientList.payload.client;

  _.map(clients, (client) => {
    client["value"] = client["clientId"];
    client["text"] = client["clientName"];
    client["clientid"] = client["clientId"];
    client["clientname"] = client["clientName"];
    delete client["clientId"];
    delete client["clientName"];
    delete client["managed"];
  });
  return clients;
}

function formatLocationList(locationList) {
  let locations = locationList.payload.client;

  _.map(locations, (location) => {
    location["value"] = location["locationId"];
    location["city"] = location["location"]["city"];
    if (location["location"]["addressLine2"] === "") {
      location["text"] =
        location["location"]["addressLine1"] +
        " " +
        location["location"]["city"] +
        " " +
        location["location"]["state"] +
        "," +
        location["location"]["country"] +
        " " +
        location["location"]["zipCode"];
    } else {
      location["text"] =
        location["location"]["addressLine1"] +
        " " +
        location["location"]["addressLine2"] +
        " " +
        location["location"]["city"] +
        " " +
        location["location"]["state"] +
        "," +
        location["location"]["country"] +
        " " +
        location["location"]["zipCode"];
    }
    delete location["locationId"];
    delete location["location"];
  });
  return locations;
}

function formatClientCostCenter(clientCostCenter) {
  let costCenters = clientCostCenter.payload.costCenterList;

  _.map(costCenters, (costCenter) => {
    costCenter["value"] = costCenter["cost_center_id"];
    costCenter["text"] = costCenter["cost_center_name"];
    delete costCenter["cost_center_id"];
    delete costCenter["cost_center_name"];
  });
  return costCenters;
}
