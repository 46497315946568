/**
 * @Desc Wrapper for users constans. For all user management API calls related contants being defined here.
 */

export const userConstants = {
  REQUEST: "USER_REQUEST",
  SUCCESS: "USER_SUCCESS",
  FAILURE: "USER_FAILURE",

  UNMOUNT_USER: "UNMOUNT_USER",

  UNMOUNT_EDIT_USER: "UNMOUNT_EDIT_USER",

  UNMOUNT_CREATE_USER: "UNMOUNT_CREATE_USER",

  UNMOUNT_CHANGE_PASSWORD_USER: "UNMOUNT_CHANGE_PASSWORD_USER",

  TOGGLE_USER_STATUS: "TOGGLE_USER_STATUS",
  TOGGLE_USER_STATUS_SUCCESS: "TOGGLE_USER_STATUS_SUCCESS",
  TOGGLE_USER_STATUS_FAILURE: "TOGGLE_USER_STATUS_FAILURE",

  GET_USER_LIST: "GET_USER_LIST",
  GET_USER_LIST_SUCCESS: "GET_USER_LIST_SUCCESS",
  GET_USER_LIST_FAILURE: "GET_USER_LIST_FAILURE",

  GET_USER_DETAILS: "GET_USER_DETAILS",
  GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
  GET_USER_DETAILS_FAILURE: "GET_USER_DETAILS_FAILURE",

  CHANGE_USER_STATUS: "CHANGE_USER_STATUS",
  CHANGE_USER_STATUS_SUCCESS: "CHANGE_USER_STATUS_SUCCESS",
  CHANGE_USER_STATUS_FAILURE: "CHANGE_USER_STATUS_FAILURE",

  GET_USER_ROLE_LIST: "GET_USER_ROLE_LIST",
  GET_USER_ROLE_LIST_SUCCESS: "GET_USER_ROLE_LIST_SUCCESS",
  GET_USER_ROLE_LIST_FAILURE: "GET_USER_ROLE_LIST_FAILURE",

  GET_UM_CLIENT_LIST: "GET_UM_CLIENT_LIST",
  GET_UM_CLIENT_LIST_SUCCESS: "GET_UM_CLIENT_LIST_SUCCESS",
  GET_UM_CLIENT_LIST_FAILURE: "GET_UM_CLIENT_LIST_FAILURE",

  GET_UM_LOCATION_LIST: "GET_UM_LOCATION_LIST",
  GET_UM_LOCATION_LIST_SUCCESS: "GET_UM_LOCATION_LIST_SUCCESS",
  GET_UM_LOCATION_LIST_FAILURE: "GET_UM_LOCATION_LIST_FAILURE",

  UPLOAD_IMAGE_TEMP: "UPLOAD_IMAGE_TEMP",
  UPLOAD_IMAGE_TEMP_SUCCESS: "UPLOAD_IMAGE_TEMP_SUCCESS",
  UPLOAD_IMAGE_TEMP_FAILURE: "UPLOAD_IMAGE_TEMP_FAILURE",

  CREATE_USER: "CREATE_USER",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE: "CREATE_USER_FAILURE",

  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

  FORGOT_PASSWORD_SEND_EMAIL: "USER_FORGOT_PASSWORD_SEND_EMAIL",
  FORGOT_PASSWORD_SEND_EMAIL_SUCCESS: "USER_FORGOT_PASSWORD_SEND_EMAIL_SUCCESS",
  FORGOT_PASSWORD_SEND_EMAIL_FAILURE: "USER_FORGOT_PASSWORD_SEND_EMAIL_FAILURE",

  FORGOT_PASSWORD_VERIFY_TOKEN: "FORGOT_PASSWORD_VERIFY_TOKEN",
  FORGOT_PASSWORD_VERIFY_TOKEN_SUCCESS: "FORGOT_PASSWORD_VERIFY_TOKEN_SUCCESS",
  FORGOT_PASSWORD_VERIFY_TOKEN_FAILURE: "FORGOT_PASSWORD_VERIFY_TOKEN_FAILURE",

  FORGOT_PASSWORD_UPDATE_PASSWORD: "FORGOT_PASSWORD_UPDATE_PASSWORD",
  FORGOT_PASSWORD_UPDATE_PASSWORD_SUCCESS:
    "FORGOT_PASSWORD_UPDATE_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_UPDATE_PASSWORD_FAILURE:
    "FORGOT_PASSWORD_UPDATE_PASSWORD_FAILURE",

  GET_CHANGE_ROLE_LIST: "GET_CHANGE_ROLE_LIST",
  GET_CHANGE_ROLE_LIST_SUCCESS: "GET_CHANGE_ROLE_LIST_SUCCESS",
  GET_CHANGE_ROLE_LIST_FAILURE: "GET_CHANGE_ROLE_LIST_FAILURE",
};
