/**
 * @Desc Wrapper for global exception inorder to have a single point for exception handling in the application.
 *
 * All the error being processed by exception handler to show appropriate message to the user to make it more user friendly for user perspective.
 */

import { toaster } from "../_common/toaster/toaster";
import { history } from "../_helpers/history";
import { store } from '../_helpers/store';
import { authActions } from "../_actions/auth.action";

export const exceptionHandlerService = {
  exceptionService
};

function exceptionService(error, msg) {
  console.log("====================================");
  console.log("error in exception handler: ", error, msg, error.status);
  console.log("====================================");
  if (error !== undefined) {
    if (error.status !== undefined) {
      let status = Number(error.status);
      console.log("====================================");
      console.log("error msg: ", msg, status);
      console.log("====================================");
      switch (true) {
        case status === 400 || status === 500:
          if (error.data.hasOwnProperty("payload")) {
            if (Number(error.data.payload.errorCode) === 201) {
              toaster.notifyError("Data already exist.");
            } else if (Number(error.data.payload.errorCode) === 215) {
              store.dispatch(authActions.logout());
              toaster.notifyError("API key not available.");
              localStorage.clear();
              sessionStorage.clear();
              history.push("/login");
            } else if (Number(error.data.payload.errorCode) === 216) {
              toaster.notifyError("Token not passed in request");
            }
          } else {
            toaster.notifyError(
              "Internal server error. Please try again after some time."
            );
          }
          break;
        case status === 0:
          toaster.notifyError("Please check your internet connection.");
          break;
        case status === 408:
          toaster.notifyError(
            "Request timeout. Please try again after some time."
          );
          break;
        case status === 401:
          toaster.notifyError(
            "Something went wrong. Please try again after some time."
          );
          break;
        case status === 422:
          if (Number(error.data.payload.errorCode) === 450) {
            toaster.notifyError(
              "Error in generating QB report."
            );
          } else if (Number(error.data.payload.errorCode) === 451) {
            toaster.notifyError("Invalid file format.");
          }
          break;
        case status === 404:
        console.log("error block",error.data)
          if (error.data.hasOwnProperty("payload")) {
            if (Number(error.data.payload.errorCode) === 210) {
              toaster.notifyError(
                "No Device detail found for specified serial number."
              );
            } else if (Number(error.data.payload.errorCode) === 211) {
              toaster.notifyError(
                "No Model information found for this model name."
              );
            } else if (Number(error.data.payload.errorCode) === 206) {
              toaster.notifyError("User with id not found.");
            } else if (Number(error.data.payload.errorCode) === 600) {
              toaster.notifyError("User with email id does not exist.");
            }
          }else{
            toaster.notifyError(
              "Something went wrong. Please try again after some time."
            );
          }
          break;
        case status === 403:
          if (error.data.hasOwnProperty("payload")) {
            if (Number(error.data.payload.errorCode) === 205) {
              toaster.notifyError("Invalid username or password");
            } else if (Number(error.data.payload.errorCode) === 301) {
              toaster.notifyError("Password token expired");
            }
            else if (Number(error.data.payload.errorCode) === 204 || Number(error.data.payload.errorCode) === 302) {
              store.dispatch(authActions.logout());
              toaster.notifyError("Session Expired");
              localStorage.clear();
              sessionStorage.clear();
              history.push("/login");
            } else if (Number(error.data.payload.errorCode) === 203) {
              store.dispatch(authActions.logout());
              toaster.notifyError("Invalid API key");
              localStorage.clear();
              sessionStorage.clear();
              history.push("/login");
            } else if (Number(error.data.payload.errorCode) === 202) {
              store.dispatch(authActions.logout());
              toaster.notifyError("Reset token is Invalid.");
              localStorage.clear();
              sessionStorage.clear();
              history.push("/login");
            }
          }
          break;
        default:
          console.log('msg: ', msg);
          if (msg != null) {
            toaster.notifyError(msg);
          }
          break;
      }
    } else {
      switch (true) {
        // case String(error).indexOf("Error: Network Error") !== -1:
        //   toaster.notifyInternetFail();
        //   break;
        case String(error).indexOf("timeout") !== -1:
          console.log("request timeout.");
          toaster.notifyError(
            "Your connection seems to be slow. Your request is timed out. Please try again."
          );
          break;
        case (String(error).indexOf('Error: Request failed with status code 404') !== -1):
          toaster.notifyError(
            'Service Not Available. Please try again after some time.'
          );
          break;
        case (String(error).indexOf('Error: Request failed with status code 500') !== -1):
          toaster.notifyError(
            'Internal server error'
          );
        break;
        // case String(error).indexOf('401') !== -1:
        //     console.log('unauthorized access.');
        //     toaster.notifyError(
        //         "Something went wrong. Please try again after some time."
        //     );
        //     break;
        // case (String(error).indexOf('500') !== -1) || (String(error).indexOf('400') !== -1):
        //     toaster.notifyError(
        //         "Internal server error. Please try again after some time."
        //     );
        //     break;
        // case String(error).indexOf('403') !== -1:
        //     console.log('unauthorized access.');
        //     toaster.notifyError(
        //         "Something went wrong. Please try again after some time."
        //     );
        //     break;
        default:
        console.log('msg: ', msg);
          if (msg != null) {
            // if(msg === 'Session Expired.'){
            //   store.dispatch(authActions.logout());
            //   toaster.notifyError(msg);
            //   localStorage.clear();
            //   sessionStorage.clear();
            //   history.push("/login");
            // }else{
            //   toaster.notifyError(msg);
            // }
            toaster.notifyError(msg);
          }
          break;
      }
    }
  }
}
