import { DeviceResources } from "../_resources/device.resources";
import { requestHandler } from "../_helpers/requestHandler";

export const deviceApi = {
  getSearchDeviceList,
  getClientDeviceList,
  getClientList,
  getLocationList,
  getDeviceDetails,
  getDeviceModelList,
  getDeviceModelInfo,
  updateDeviceModelInfo,
  uploadAssetImageApi,
  updateDeviceContractInfoApi,
  getFullClientDeviceList,
  getFullDeviceModelList,
  getCostCenterList,
  updateDeviceBasicInfo,
  getDeviceUsageInfo,
  setDeviceInStorage,
  getContractDetailsApi,
  uploadDeviceContractFileApi,
  updateDeviceUsageInfoApi,
  updateContractDetailsApi,
  getDeviceImagesApi,
  uploadDeviceImageApi,
  downloadContratFileApi,
  getDeviceUsageChartDetailsApi,
  removeDeviceImageApi,
  saveFiltersLogOutApi,
  getSaveFilterStoredApi
};

function getSearchDeviceList(query) {
  let url = DeviceResources.getSearchDeviceListUrl(query).url;
  return requestHandler.getService(url);
}

function getClientDeviceList(data) {
  let url = DeviceResources.getClientDevicesList().url;
  return requestHandler.postService(url, data);
}

function getFullClientDeviceList(data) {
  let url = DeviceResources.getClientDevicesList().url;
  return requestHandler.postFullService(url, data);
}

function getClientList() {
  let url = DeviceResources.getClientListUrl().url;
  return requestHandler.getService(url);
}

function getLocationList(data) {
  let url = DeviceResources.getLocationListUrl().url;
  return requestHandler.postService(url, data);
}

function getDeviceDetails(serialNumber) {
  let url = DeviceResources.getDeviceDetailsUrl(serialNumber).url;
  return requestHandler.getService(url);
}

function getDeviceModelList(data) {
  let url = DeviceResources.getDeviceModelListUrl().url;
  return requestHandler.postService(url, data);
}

function getFullDeviceModelList(data) {
  let url = DeviceResources.getDeviceModelListUrl().url;
  return requestHandler.postFullService(url, data);
}

function getDeviceModelInfo(modelName) {
  let url = DeviceResources.getDeviceModelInfoUrl(modelName).url;
  return requestHandler.getService(url);
}

function updateDeviceModelInfo(data) {
  let url = DeviceResources.getUpdateDeviceModelInfoUrl().url;
  return requestHandler.postService(url, data);
}

function uploadAssetImageApi(data) {
  let url = DeviceResources.getUploadAssetImageUrl().url;
  return requestHandler.postService(url, data);
}

function updateDeviceContractInfoApi(data) {
  let url = DeviceResources.getUpdateDeviceContractInfoUrl().url;
  return requestHandler.postService(url, data);
}

function getCostCenterList(data) {
  let url = DeviceResources.getCostCenterListUrl().url;
  return requestHandler.postService(url, data);
}

function updateDeviceBasicInfo(data) {
  let url = DeviceResources.updateDeviceBasicInfoUrl().url;
  return requestHandler.postService(url, data);
}

function getDeviceUsageInfo(serialNumber) {
  let url = DeviceResources.getDeviceUsageInfoUrl(serialNumber).url;
  return requestHandler.getService(url);
}

function setDeviceInStorage(req) {
  let url = DeviceResources.setDeviceInStorageUrl(req).url;
  return requestHandler.getService(url);
}

function getContractDetailsApi(serialNumber, data) {
  let url = DeviceResources.getContractDetailsUrl(serialNumber).url;
  return requestHandler.getService(url);
}

function uploadDeviceContractFileApi(data) {
  let url = DeviceResources.postDeviceContractFileUrl().url;
  return requestHandler.postService(url, data);
}

function updateDeviceUsageInfoApi(data) {
  let url = DeviceResources.updateDeviceUsageInfoUrl().url;
  return requestHandler.postService(url, data);
}

function updateContractDetailsApi(data) {
  let url = DeviceResources.updateContractDetailsUrl().url;
  return requestHandler.postService(url, data);
}

function getDeviceImagesApi(serialNumber) {
  let url = DeviceResources.getDeviceImagesUrl(serialNumber).url;
  return requestHandler.getService(url);
}

function uploadDeviceImageApi(data) {
  let url = DeviceResources.uploadDeviceImageUrl().url;
  return requestHandler.postService(url, data);
}

function downloadContratFileApi(req) {
  let url = DeviceResources.downloadContractFileUrl(req).url;
  return requestHandler.getService(url);
}
function getDeviceUsageChartDetailsApi(serialNumber) {
  let url = DeviceResources.getDeviceUsageChartInfoUrl(serialNumber).url;

  return requestHandler.getService(url);
}

function removeDeviceImageApi(imgUrl) {
  let url = DeviceResources.removeDeviceImageUrl(imgUrl).url;

  return requestHandler.getService(url);
}

function saveFiltersLogOutApi(data){
  let url = DeviceResources.saveFiltersLogOutUrl().url;
  return requestHandler.postService(url, data); 
}


function getSaveFilterStoredApi(userId) {
  let url = DeviceResources.getSaveFilterStoredUrl(userId).url;
  return requestHandler.getService(url);
}
