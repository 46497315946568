/**
 * @Desc Wrapper for costCenter constans. For all cost center management API calls related contants being defiend here.
 */
export const costCenterConstants = {
    REQUEST: "COST_CENTER_REQUEST",
    SUCCESS: "COST_CENTER_SUCCESS",
    FAILURE: "COST_CENTER_FAILURE",
  
    GET_COST_CENTER_LIST: "GET_COST_CENTER_LIST",
    GET_COST_CENTER_LIST_SUCCESS: "GET_COST_CENTER_LIST_SUCCESS",
    GET_COST_CENTER_LIST_FAILURE: "GET_COST_CENTER_LIST_FAILURE",

    GET_COST_CENTER_UNALLOCATED_DEVICE_LIST: "GET_COST_CENTER_UNALLOCATED_DEVICE_LIST",
    GET_COST_CENTER_UNALLOCATED_DEVICE_LIST_SUCCESS: "GET_COST_CENTER_UNALLOCATED_DEVICE_LIST_SUCCESS",
    GET_COST_CENTER_UNALLOCATED_DEVICE_LIST_FAILURE: "GET_COST_CENTER_UNALLOCATED_DEVICE_LIST_FAILURE",

    CREATE_COST_CENTER: "CREATE_COST_CENTER",
    CREATE_COST_CENTER_SUCCESS: "CREATE_COST_CENTER_SUCCESS",
    CREATE_COST_CENTER_FAILURE: "CREATE_COST_CENTER_FAILURE",

    VIEW_COST_CENTER: "VIEW_COST_CENTER",
    VIEW_COST_CENTER_SUCCESS: "VIEW_COST_CENTER_SUCCESS",
    VIEW_COST_CENTER_FAILURE: "VIEW_COST_CENTER_FAILURE",

    UPDATE_COST_CENTER: "UPDATE_COST_CENTER",
    UPDATE_COST_CENTER_SUCCESS: "UPDATE_COST_CENTER_SUCCESS",
    UPDATE_COST_CENTER_FAILURE: "UPDATE_COST_CENTER_FAILURE",
}