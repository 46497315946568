/**
 * @Desc Wrapper for storing API urls for device management & site view
 */

import { GlobalVariable } from "../_constants/url.constants";

const baseUrl = GlobalVariable.baseUrl;
const projectName = GlobalVariable.projectName;
const ssl = GlobalVariable.ssl;



export const CostCenterResources = {
    getCostCenterListUrl,
    getCostCenterUnAllocatedDeviceListUrl,
    createCostCenterUrl,
    viewCostCenterUrl,
    updateCostCenterUrl
}


function getCostCenterListUrl() {
    return {
      url:
        ssl +
        "://" +
        baseUrl +
        "/" +
        projectName +
        "/kbt/bridge/api/v1/cost/center/lists"
    };
  }

  function getCostCenterUnAllocatedDeviceListUrl(){
    return {
          url:
            ssl +
            "://" +
            baseUrl +
            "/" +
            projectName +
            "/kbt/bridge/api/v1/cost/center/devices"
        };
  }


  function createCostCenterUrl(){
    return {
          url:
            ssl +
            "://" +
            baseUrl +
            "/" +
            projectName +
            "/kbt/bridge/api/v1/cost/center/create"
        };
  }

  function viewCostCenterUrl(){
    return {
          url:
            ssl +
            "://" +
            baseUrl +
            "/" +
            projectName +
            "/kbt/bridge/api/v1/cost/center/view"
        };
  }


  function updateCostCenterUrl(){
    return {
          url:
            ssl +
            "://" +
            baseUrl +
            "/" +
            projectName +
            "/kbt/bridge/api/v1/cost/center/update"
        };
  }