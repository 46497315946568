/**
 * @Desc Wrapper for storing API urls for user management.
 */

import { GlobalVariable } from "../_constants/url.constants";
const baseUrl = GlobalVariable.baseUrl;
const projectName = GlobalVariable.projectName;
const ssl = GlobalVariable.ssl;

export const EmailNotificationResources = {
  getEmailNotificationUserListUrl,
  getEmailNotificationClientListUrl,
  getEmailNotificationStatusUrl,
  getEmailNotificationStatusListUrl
};

function getEmailNotificationUserListUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/user/listAll"
  };
}

function getEmailNotificationClientListUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/clients/locations"
  };
}

function getEmailNotificationStatusUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/email/enable-disable"
      
  };
}

function getEmailNotificationStatusListUrl() {
  return {
    url:
      ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v1/email/notification-status/list"
  };
}
