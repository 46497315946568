/**
 * @Desc Wrapper for saga API call for site view & device related.
 */
import {
  call,
  put,
  takeLatest
} from "redux-saga/effects";
import {
  toaster
} from "../_common/toaster/toaster";
import {
  exceptionHandlerService
} from "../_helpers/exceptionHandler";
import {
  reportConstants
} from "../_constants/report.constant";
import {
  reportApi
} from "../_apiCall/report.api";
import {
  request
} from "../_helpers/request";

export function* reportSaga() {
  yield takeLatest(reportConstants.CHECK_TCO_REPORT, checkTcoReport);
  yield takeLatest(reportConstants.SAVE_POWER_USAGE, savePowerUsage);
  yield takeLatest(reportConstants.GET_DEVICE_DETAILS, getDeviceDetails);
  yield takeLatest(reportConstants.GENERATE_TCO_REPORT, generateTcoReport);
  yield takeLatest(reportConstants.GENERATE_QB_REPORT, generateQBReport);
  yield takeLatest(
    reportConstants.UPLOAD_CONTRACT_SHEET,
    uploadContractSheetSaga
  );
  yield takeLatest(
    reportConstants.GET_MISSING_DEVICE_DETAILS,
    getMissingDeviceDetails
  );
  yield takeLatest(
    reportConstants.GET_FULL_MISSING_DEVICE_DETAILS,
    getFullMissingDeviceDetails
  );
  yield takeLatest(
    reportConstants.GET_SUPPLY_ORDER_DEVICE_DETAILS,
    getSupplyOrderDeviceDetails
  );
  yield takeLatest(
    reportConstants.GET_FULL_SUPPLY_ORDER_DEVICE_DETAILS,
    getFullSupplyOrderDeviceDetails
  );
  yield takeLatest(
    reportConstants.VIEW_SUPPLY_ORDER_DEVICE_DETAILS,
    viewSupplyOrderDeviceDetails
  );
  yield takeLatest(
    reportConstants.GENERATE_MONTHLY_USAGE_REPORT,
    generateMonthlyUsageReport
  );
  yield takeLatest(reportConstants.GET_CUSTOM_REPORTS, getCustomReportsAction);
  yield takeLatest(
    reportConstants.CREATE_CUSTOM_REPORT,
    createCustomReportAction
  );
  yield takeLatest(reportConstants.SAVE_CUSTOM_REPORT, saveCustomReportsAction);
  yield takeLatest(
    reportConstants.UPDATE_CUSTOM_REPORT,
    updateCustomReportsAction
  );
  yield takeLatest(
    reportConstants.DELETE_CUSTOM_REPORT,
    deleteCustomReportsAction
  );
  yield takeLatest(reportConstants.GET_REPORT_TYPE, getReportTypeAction);
  yield takeLatest(
    reportConstants.GET_MEASURES_DIMENSIONS,
    getMeasuresDimensionsAction
  );
  yield takeLatest(reportConstants.VIEW_CUSTOM_REPORT, viewCustomReportsAction);
  yield takeLatest(
    reportConstants.SERVICE_HISTORY_REPORT,
    getServiceHistoryList
  );
  yield takeLatest(
    reportConstants.GET_SERVICE_HISTORY_DETAILS,
    getServiceHistoryDetails
  );
  yield takeLatest(
    reportConstants.FULL_SERVICE_HISTORY_REPORT,
    getFullServiceHistoryReportList
  );
  yield takeLatest(
    reportConstants.GET_BASIC_TCO_REPORT,
    getBasicTcoReportAction
  );
  yield takeLatest(reportConstants.GET_TCO_CHART, getTcoChartAction);
  yield takeLatest(
    reportConstants.GET_FULL_BASIC_TCO_REPORT,
    getFullBasicTcoReportList
  );
  yield takeLatest(reportConstants.IMPORT_MACD_CSV_FILE, importMacdFileUrlSaga);

  yield takeLatest(
    reportConstants.GET_PAGE_USAGE_REPORT,
    getPageUsageReportList
  );

  yield takeLatest(
    reportConstants.GET_FULL_PAGE_USAGE_REPORT,
    getFullPageUsageReportList
  );

  yield takeLatest(
    reportConstants.GET_QBR_REPORT,
    getQBRReportList
  );


  yield takeLatest(
    reportConstants.GET_QBR_CHART,
    getQBRChartAction
  );


  yield takeLatest(reportConstants.GET_FULL_QBR_REPORT, getFullQbrReport);
}

/**
 * @Desc Wrapper for all saga function call with the common functionality being defined here.
 */
function* sagaFunc(api, action, failureConstant, successConstant, flag, msg) {
  yield put(request.isFetching(reportConstants.REQUEST));
  try {
    const {
      response,
      error
    } =
    flag === true ? yield call(api, action.payload): yield call(api);
    console.log("report saga", response)
    if (response !== undefined) {
      if (response.success === true || response.status === 200) {
        yield put(request.success(successConstant, response));
      } else if (response.success === false) {
        toaster.notifyError(msg);
        yield put(request.failure(failureConstant, msg));
      }
    } else if (error) {
      exceptionHandlerService.exceptionService(error, msg);
      yield put(request.failure(failureConstant, error));
    }
  } catch (error) {
    exceptionHandlerService.exceptionService(error, msg);
    yield put(request.failure(failureConstant, error.toString()));
  }
}

// worker Saga: will be fired on Chect_Tco_Report actions
function* checkTcoReport(action) {
  const api = reportApi.checkTcoReport;
  const failureConstant = reportConstants.CHECK_TCO_REPORT_FAILURE;
  const successConstant = reportConstants.CHECK_TCO_REPORT_SUCCESS;
  const flag = true;
  const msg = "unable to get tco status. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

// worker Saga: will be fired on Save_Power_Usage actions
function* savePowerUsage(action) {
  const api = reportApi.savePowerUsage;
  const failureConstant = reportConstants.SAVE_POWER_USAGE_FAILURE;
  const successConstant = reportConstants.SAVE_POWER_USAGE_SUCCESS;
  const flag = true;
  const msg = "unable to save power usage . Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

// worker Saga: will be fired on get_device_details actions
function* getDeviceDetails(action) {
  const api = reportApi.getDeviceDetails;
  const failureConstant = reportConstants.GET_DEVICE_DETAILS_FAILURE;
  const successConstant = reportConstants.GET_DEVICE_DETAILS_SUCCESS;
  const flag = true;
  const msg = "unable to save power usage . Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

// worker Saga: will be fired on generate_tco_report actions
function* generateTcoReport(action) {
  const api = reportApi.generateTcoReport;
  const failureConstant = reportConstants.GENERATE_TCO_REPORT_FAILURE;
  const successConstant = reportConstants.GENERATE_TCO_REPORT_SUCCESS;
  const flag = true;
  const msg = "unable generate tco report . Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

// worker Saga: will be fired on generate_QB_report actions
function* generateQBReport(action) {
  const api = reportApi.generateQBReportApi;
  const failureConstant = reportConstants.GENERATE_QB_REPORT_FAILURE;
  const successConstant = reportConstants.GENERATE_QB_REPORT_SUCCESS;
  const flag = true;
  const msg = "unable to generate QB file. Please try after some time.";
  yield put(request.isFetching(reportConstants.REQUEST));
  try {
    const {
      response,
      error
    } =
    flag === true ? yield call(api, action.payload): yield call(api);
    if (response !== undefined) {
      if (response.success === true) {
        yield put(request.success(successConstant, response));
      } else if (response.success === false) {
        toaster.notifyError(msg);
        yield put(request.failure(failureConstant, msg));
      }
    } else if (error) {
      exceptionHandlerService.exceptionService(error, msg);
      yield put(request.failure(failureConstant, error));
    }
  } catch (error) {
    exceptionHandlerService.exceptionService(error, msg);
    yield put(request.failure(failureConstant, error));
  }
}

// worker Saga: will be fired on generate_QB_report actions
function* uploadContractSheetSaga(action) {
  const api = reportApi.uploadContractSheetApi;
  const failureConstant = reportConstants.UPLOAD_CONTRACT_SHEET_FAILURE;
  const successConstant = reportConstants.UPLOAD_CONTRACT_SHEET_SUCCESS;
  const flag = true;
  const msg = "unable to upload contact file. Please try after some time.";
  yield put(request.isFetching(reportConstants.REQUEST));
  try {
    const {
      response,
      error
    } =
    flag === true ? yield call(api, action.payload): yield call(api);
    if (response !== undefined) {
      if (response.success === true) {
        yield put(request.success(successConstant, response));
      } else if (response.success === false) {
        toaster.notifyError(msg);
        yield put(request.failure(failureConstant, msg));
      }
    } else if (error) {
      exceptionHandlerService.exceptionService(error, msg);
      yield put(request.failure(failureConstant, error));
    }
  } catch (error) {
    exceptionHandlerService.exceptionService(error, msg);
    yield put(request.failure(failureConstant, error));
  }
}

// worker Saga: will be fired on generate_monthly_usage_report actions
function* generateMonthlyUsageReport(action) {
  const api = reportApi.generateMonthlyUsageReportApi;
  const failureConstant = reportConstants.GENERATE_MONTHLY_USAGE_REPORT_FAILURE;
  const successConstant = reportConstants.GENERATE_MONTHLY_USAGE_REPORT_SUCCESS;
  const flag = true;
  const msg =
    "unable generate monthly usage report . Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

// worker Saga: will be fired on get_missing_device_details actions
function* getMissingDeviceDetails(action) {
  console.log("@saga normal", action);

  const api = reportApi.getMissingDeviceDetailsApi;
  const failureConstant = reportConstants.GET_MISSING_DEVICE_DETAILS_FAILURE;
  const successConstant = reportConstants.GET_MISSING_DEVICE_DETAILS_SUCCESS;
  const flag = true;
  const msg = "unable get missing device details . Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

// worker Saga: will be fired on get_full_missing_device_details export actions

function* getFullMissingDeviceDetails(action) {
  console.log("@saga full");
  const api = reportApi.getMissingDeviceDetailsApi;
  const failureConstant =
    reportConstants.GET_FULL_MISSING_DEVICE_DETAILS_FAILURE;
  const successConstant =
    reportConstants.GET_FULL_MISSING_DEVICE_DETAILS_SUCCESS;
  const flag = true;
  const msg = "unable to get client device list. Please try after some time.";
  yield put(request.isFetching(reportConstants.REQUEST));
  try {
    const {
      response,
      error
    } =
    flag === true ? yield call(api, action.payload): yield call(api);
    if (response !== undefined) {
      if (response.success === true) {
        response["exportTo"] = action.exportTo;
        yield put(request.success(successConstant, response));
      } else if (response.success === false) {
        toaster.notifyError(msg);
        yield put(request.failure(failureConstant, msg));
      }
    } else if (error) {
      exceptionHandlerService.exceptionService(error, msg);
      yield put(request.failure(failureConstant, error));
    }
  } catch (error) {
    exceptionHandlerService.exceptionService(error, msg);
    yield put(request.failure(failureConstant, error.toString()));
  }
}

// worker Saga: will be fired on get_missing_device_details actions
function* getSupplyOrderDeviceDetails(action) {
  console.log("@saga normal");

  const api = reportApi.getSupplyOrderDeviceDetailsApi;
  const failureConstant =
    reportConstants.GET_SUPPLY_ORDER_DEVICE_DETAILS_FAILURE;
  const successConstant =
    reportConstants.GET_SUPPLY_ORDER_DEVICE_DETAILS_SUCCESS;
  const flag = true;
  const msg = "unable get missing device details . Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* viewSupplyOrderDeviceDetails(action) {
  console.log("@saga normal view supply order report");
  const api = reportApi.viewSupplyOrderDeviceDetailsApi;
  const failureConstant =
    reportConstants.VIEW_SUPPLY_ORDER_DEVICE_DETAILS_FAILURE;
  const successConstant =
    reportConstants.VIEW_SUPPLY_ORDER_DEVICE_DETAILS_SUCCESS;
  const flag = true;
  const msg = "unable view supply order reports. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

// worker Saga: will be fired on get_full_missing_device_details export actions

function* getFullSupplyOrderDeviceDetails(action) {
  console.log("@saga full");
  const api = reportApi.getSupplyOrderDeviceDetailsApi;
  const failureConstant =
    reportConstants.GET_FULL_SUPPLY_ORDER_DEVICE_DETAILS_FAILURE;
  const successConstant =
    reportConstants.GET_FULL_SUPPLY_ORDER_DEVICE_DETAILS_SUCCESS;
  const flag = true;
  const msg = "unable to get client device list. Please try after some time.";
  yield put(request.isFetching(reportConstants.REQUEST));
  try {
    const {
      response,
      error
    } =
    flag === true ? yield call(api, action.payload): yield call(api);
    if (response !== undefined) {
      if (response.success === true) {
        response["exportTo"] = action.exportTo;
        yield put(request.success(successConstant, response));
      } else if (response.success === false) {
        toaster.notifyError(msg);
        yield put(request.failure(failureConstant, msg));
      }
    } else if (error) {
      exceptionHandlerService.exceptionService(error, msg);
      yield put(request.failure(failureConstant, error));
    }
  } catch (error) {
    exceptionHandlerService.exceptionService(error, msg);
    yield put(request.failure(failureConstant, error.toString()));
  }
}

// worker Saga: will be fired on getCustomReportsAction actions
function* getCustomReportsAction(action) {
  console.log("@saga normal");
  const api = reportApi.getCustomReportsApi;
  const failureConstant = reportConstants.GET_CUSTOM_REPORTS_FAILURE;
  const successConstant = reportConstants.GET_CUSTOM_REPORTS_SUCCESS;
  const flag = true;
  const msg = "unable get custom reports. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* createCustomReportAction(action) {
  console.log("@saga  create", action);
  const api = reportApi.createCustomReporApi;
  const failureConstant = reportConstants.CREATE_CUSTOM_REPORT_FAILURE;
  const successConstant = reportConstants.CREATE_CUSTOM_REPORT_SUCCESS;
  const flag = true;
  const msg = "unable create custom reports. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* saveCustomReportsAction(action) {
  console.log("@saga normal save report");
  const api = reportApi.saveCustomReportsApi;
  const failureConstant = reportConstants.SAVE_CUSTOM_REPORT_FAILURE;
  const successConstant = reportConstants.SAVE_CUSTOM_REPORT_SUCCESS;
  const flag = true;
  const msg = "unable save custom reports. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* updateCustomReportsAction(action) {
  console.log("@saga normal save report");
  const api = reportApi.updateCustomReportsApi;
  const failureConstant = reportConstants.UPDATE_CUSTOM_REPORT_FAILURE;
  const successConstant = reportConstants.UPDATE_CUSTOM_REPORT_SUCCESS;
  const flag = true;
  const msg = "unable update custom reports. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* deleteCustomReportsAction(action) {
  console.log("@saga normal save report");
  const api = reportApi.deleteCustomReportsApi;
  const failureConstant = reportConstants.DELETE_CUSTOM_REPORT_FAILURE;
  const successConstant = reportConstants.DELETE_CUSTOM_REPORT_SUCCESS;
  const flag = true;
  const msg = "unable to delete custom reports. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getReportTypeAction(action) {
  console.log("@saga normal report type");
  const api = reportApi.getReportTypeApi;
  const failureConstant = reportConstants.GET_REPORT_TYPE_FAILURE;
  const successConstant = reportConstants.GET_REPORT_TYPE_SUCCESS;
  const flag = true;
  const msg = "unable get report type. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getMeasuresDimensionsAction(action) {
  console.log("@saga normal dimensions and measures");
  const api = reportApi.getMeasuresDimensionsApi;
  const failureConstant = reportConstants.GET_MEASURES_DIMENSIONS_FAILURE;
  const successConstant = reportConstants.GET_MEASURES_DIMENSIONS_SUCCESS;
  const flag = true;
  const msg = "unable get measures and dimensions. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* viewCustomReportsAction(action) {
  console.log("@saga normal view report");
  const api = reportApi.viewCustomReportsApi;
  const failureConstant = reportConstants.VIEW_CUSTOM_REPORT_FAILURE;
  const successConstant = reportConstants.VIEW_CUSTOM_REPORT_SUCCESS;
  const flag = true;
  const msg = "unable view custom reports. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getServiceHistoryList(action) {
  const api = reportApi.getServiceHistoryListApi;
  const failureConstant = reportConstants.SERVICE_HISTORY_REPORT_FAILURE;
  const successConstant = reportConstants.SERVICE_HISTORY_REPORT_SUCCESS;
  const flag = true;
  const msg =
    "unable to get service history report. please try after some time";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getFullServiceHistoryReportList(action) {
  const api = reportApi.getServiceHistoryListApi;
  const failureConstant = reportConstants.FULL_SERVICE_HISTORY_REPORT_FAILURE;
  const successConstant = reportConstants.FULL_SERVICE_HISTORY_REPORT_SUCCESS;
  const flag = true;
  const msg =
    "unable to get service history report. please try after some time";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getBasicTcoReportAction(action) {
  const api = reportApi.getBasicTCOReportApi;
  const failureConstant = reportConstants.GET_BASIC_TCO_REPORT_FAILURE;
  const successConstant = reportConstants.GET_BASIC_TCO_REPORT_SUCCESS;
  const flag = true;
  const msg = "unable get basic tco reports. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getServiceHistoryDetails(action) {
  const api = reportApi.getServiceHistoryDetailsApi;
  const failureConstant = reportConstants.GET_SERVICE_HISTORY_DETAILS_FAILURE;
  const successConstant = reportConstants.GET_SERVICE_HISTORY_DETAILS_SUCCESS;
  const flag = true;
  const msg = "unable to get service derails. please try after some time";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getTcoChartAction(action) {
  const api = reportApi.getTCOChartApi;
  const failureConstant = reportConstants.GET_TCO_CHART_FAILURE;
  const successConstant = reportConstants.GET_TCO_CHART_SUCCESS;
  const flag = true;
  const msg = "unable  tco charts. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getFullBasicTcoReportList(action) {
  const api = reportApi.getBasicTCOReportApi;
  const failureConstant = reportConstants.GET_FULL_BASIC_TCO_REPORT_FAILURE;
  const successConstant = reportConstants.GET_FULL_BASIC_TCO_REPORT_SUCCESS;
  const flag = true;
  const msg = "unable get basic tco reports. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* importMacdFileUrlSaga(action) {
  const api = reportApi.importMacdFileApi;
  const failureConstant = reportConstants.IMPORT_MACD_CSV_FILE_FAILURE;
  const successConstant = reportConstants.IMPORT_MACD_CSV_FILE_SUCCESS;
  const flag = true;
  const msg = "unable to upload contact file. Please try after some time.";
  yield put(request.isFetching(reportConstants.REQUEST));
  try {
    const {
      response,
      error
    } =
    flag === true ? yield call(api, action.payload): yield call(api);
    if (response !== undefined) {
      if (response.success === true) {
        yield put(request.success(successConstant, response));
      } else if (response.success === false) {
        toaster.notifyError(msg);
        yield put(request.failure(failureConstant, msg));
      }
    } else if (error) {
      exceptionHandlerService.exceptionService(error, msg);
      yield put(request.failure(failureConstant, error));
    }
  } catch (error) {
    exceptionHandlerService.exceptionService(error, msg);
    yield put(request.failure(failureConstant, error));
  }
}

function* getPageUsageReportList(action) {
  const api = reportApi.getPageUsageReportApi;
  const failureConstant = reportConstants.GET_PAGE_USAGE_REPORT_FAILURE;
  const successConstant = reportConstants.GET_PAGE_USAGE_REPORT_SUCCESS;
  const flag = true;
  const msg = "unable get page usage reports. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getFullPageUsageReportList(action) {
  const api = reportApi.getPageUsageReportApi;
  const failureConstant = reportConstants.GET_FULL_PAGE_USAGE_REPORT_FAILURE;
  const successConstant = reportConstants.GET_FULL_PAGE_USAGE_REPORT_SUCCESS;
  const flag = true;
  const msg = "unable get page usage reports. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getQBRReportList(action) {
  const api = reportApi.getQBRReportApi;
  const failureConstant = reportConstants.GET_QBR_REPORT_FAILURE;
  const successConstant = reportConstants.GET_QBR_REPORT_SUCCESS;
  const flag = true;
  const msg = "unable get page usage reports. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getQBRChartAction(action) {
  const api = reportApi.getQBRChartApi;
  const failureConstant = reportConstants.GET_QBR_CHART_FAILURE;
  const successConstant = reportConstants.GET_QBR_CHART_SUCCESS;
  const flag = true;
  const msg = "unable  qbr charts. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getFullQbrReport(action) {
  const api = reportApi.getFullQBRReportApi;
  const failureConstant = reportConstants.GET_FULL_QBR_REPORT_FAILURE;
  const successConstant = reportConstants.GET_FULL_QBR_REPORT_SUCCESS;
  const flag = true;
  const msg = "unable get page usage reports. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}