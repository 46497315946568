/**
 * @Desc Wrapper for Securing (Encrypting & Decrypting) to the data inorder to store it in the local storage or while passing the data if necessary.
 */

import CryptoJS from 'crypto-js';
import {
    GlobalVariable
} from '../_constants/url.constants';

export const SecurityService = {
    decrypt,
    encrypt,
    get,
    store,
    getFromSession,
    storeToSession
}

// code to decrypt
function decrypt(encryptedData) {
    let secretKey = GlobalVariable.key;
    let keySize = 256;
    let iterations = 100;

    let salt = CryptoJS.enc.Hex.parse(encryptedData.substr(0, 32));
    let iv = CryptoJS.enc.Hex.parse(encryptedData.substr(32, 32))
    let encrypted = encryptedData.substring(64);

    let key = CryptoJS.PBKDF2(secretKey, salt, {
        keySize: keySize / 32,
        iterations: iterations
    });

    let decrypted = CryptoJS.AES.decrypt(encrypted, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    })
    return decrypted.toString(CryptoJS.enc.Utf8);
}

// Code to encrypt
function encrypt(msg) {

    let secretKey = GlobalVariable.key;
    let keySize = 256;
    let iterations = 100;
    let encryptedData;

    let salt = CryptoJS.lib.WordArray.random(128 / 8);
    let key = CryptoJS.PBKDF2(secretKey, salt, {
        keySize: keySize / 32,
        iterations: iterations
    });

    let iv = CryptoJS.lib.WordArray.random(128 / 8);
    let encrypted = CryptoJS.AES.encrypt(msg.toString(), key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    });

    // salt, iv will be hex 32 in length
    // append them to the ciphertext for use  in decryption
    encryptedData = salt.toString() + iv.toString() + encrypted.toString();
    return encryptedData;

}

// function to get data from local storage and decrypt
function get(key) {
    let encryptedData = window.localStorage.getItem(key);

    if (encryptedData !== null) {
        let decrptedData = decrypt(encryptedData);
        return decrptedData;
    }
    return null;
}

// function to encrypt data and store it into local storage
function store(key, value) {
    let encryptedData;
    if (value !== null || value !== undefined) {
        encryptedData = encrypt(value.toString());
    }
    window.localStorage.setItem(key, encryptedData);

}

// function to get session data from session storage and decrypt
function getFromSession(key) {
    let encryptedData = window.sessionStorage.getItem(key);

    if (encryptedData !== null) {
        let decrptedData = decrypt(encryptedData);
        return decrptedData;
    }
    return null;
}

// function to encrypt session data and store it into session storage
function storeToSession(key, value) {
    let encryptedData;
    if (value !== null || value !== undefined) {
        encryptedData = encrypt(value.toString());
    }
    window.sessionStorage.setItem(key, encryptedData);

}