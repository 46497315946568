/**
 * @Desc Wrapper for user management data manipulation & pasisng it to the store.
 */

import { userConstants } from "../_constants/user.constant";
import _ from "lodash";

const initialState = {
  totalPages: 0,
  roleList: [],
  userList: [],
  clientList: [],
  locationList: [],
  enableStatus: undefined,
  updateUserRes: undefined,
  userData: "",
  loading: false,
  tempImageData: undefined,
  emailSend: undefined,
  tokenVerify: undefined,
  updatePassword: undefined,
  userLocationIdList: [],
  changeRoleList: [],
};

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case userConstants.REQUEST:
      return {
        ...state,
        loading: true,
        userData: "",
      };
    case userConstants.UNMOUNT_USER:
      return {
        ...state,
        enableStatus: undefined,
        clientList: undefined,
        locationList: undefined,
        userList: undefined,
        createUserRes: undefined,
        updateUserRes: undefined,
        tempImageData: undefined,
        passwordChangeRes: undefined,
      };
    case userConstants.UNMOUNT_EDIT_USER:
      return {
        ...state,
        clientList: undefined,
        locationList: undefined,
        userData: undefined,
        createUserRes: undefined,
        updateUserRes: undefined,
        tempImageData: undefined,
        passwordChangeRes: undefined,
      };
    case userConstants.UNMOUNT_CREATE_USER:
      return {
        ...state,
        clientList: undefined,
        locationList: undefined,
        createUserRes: undefined,
        updateUserRes: undefined,
        tempImageData: undefined,
        passwordChangeRes: undefined,
      };
    case userConstants.UNMOUNT_CHANGE_PASSWORD_USER:
      return {
        ...state,
        passwordChangeRes: undefined,
      };
    case userConstants.GET_USER_LIST_SUCCESS:
      console.log("from user reducer", action.payload.payload.user);
      return {
        ...state,
        loading: false,
        totalPages: action.payload.payload.totalPages,
        roleList: [],
        clientList: [],
        locationList: [],
        userList: action.payload.payload.user,
        enableStatus: undefined,
        createUserRes: undefined,
        updateUserRes: undefined,
        tempImageData: undefined,
        userData: undefined,
        passwordChangeRes: undefined,
      };
    case userConstants.GET_USER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.GET_USER_DETAILS_SUCCESS:
      console.log("user locationidList", action.payload.payload.locationId);
      return {
        ...state,
        totalPages: state.totalPages,
        userList: state.userList,
        loading: false,
        userData: getFormattedData(action.payload),
        clientList: [],
        locationList: [],
        enableStatus: undefined,
        updateUserRes: undefined,
        tempImageData: undefined,
        passwordChangeRes: undefined,
        userLocationIdList: action.payload.payload.locationId,
      };
    case userConstants.GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.GET_USER_ROLE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        userRoleList: formatRoleList(action.payload),
        enableStatus: undefined,
        updateUserRes: undefined,
        tempImageData: undefined,
        passwordChangeRes: undefined,
      };
    case userConstants.GET_USER_ROLE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.GET_UM_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        clientList: formatClientList(action.payload),
        enableStatus: undefined,
        updateUserRes: undefined,
        tempImageData: undefined,
        passwordChangeRes: undefined,
      };
    case userConstants.GET_UM_CLIENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.GET_UM_LOCATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        locationList: formatLocationList(action.payload),
        enableStatus: undefined,
        updateUserRes: undefined,
        tempImageData: undefined,
        passwordChangeRes: undefined,
      };
    case userConstants.GET_UM_LOCATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.TOGGLE_USER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        enableStatus: action.payload,
        updateUserRes: undefined,
        tempImageData: undefined,
        passwordChangeRes: undefined,
      };
    case userConstants.TOGGLE_USER_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.UPLOAD_IMAGE_TEMP_SUCCESS:
      return {
        ...state,
        loading: false,
        tempImageData: action.payload,
        updateUserRes: undefined,
        passwordChangeRes: undefined,
      };
    case userConstants.UPLOAD_IMAGE_TEMP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        createUserRes: action.payload,
        enableStatus: undefined,
        updateUserRes: undefined,
        tempImageData: undefined,
        passwordChangeRes: undefined,
      };
    case userConstants.CREATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        enableStatus: undefined,
        tempImageData: undefined,
        passwordChangeRes: undefined,
        updateUserRes: action.payload,
      };
    case userConstants.UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        updateUserRes: undefined,
        tempImageData: undefined,
        passwordChangeRes: action.payload,
      };
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.FORGOT_PASSWORD_SEND_EMAIL_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        loading: false,
        emailSend: action.payload,
        tokenVerify: undefined,
        updatePassword: undefined,
      };
    case userConstants.FORGOT_PASSWORD_SEND_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.FORGOT_PASSWORD_VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        loading: false,
        emailSend: undefined,
        updatePassword: undefined,
        tokenVerify: action.payload,
      };
    case userConstants.FORGOT_PASSWORD_VERIFY_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.FORGOT_PASSWORD_UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        loading: false,
        emailSend: undefined,
        tokenVerify: undefined,
        updatePassword: action.payload,
      };
    case userConstants.FORGOT_PASSWORD_UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.GET_CHANGE_ROLE_LIST_SUCCESS:
      console.log("from reducer success", action);
      return {
        ...state,
        loading: false,
        changeRoleList: formatChangeRoleList(action.payload),
        enableStatus: undefined,
        updateUserRes: undefined,
        tempImageData: undefined,
        passwordChangeRes: undefined,
      };
    case userConstants.GET_CHANGE_ROLE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

function getFormattedData(userData) {
  let data = userData.payload;
  let phone_part = data.phone.split("-");
  let code = phone_part[0].substring(1);
  let phone = phone_part[1];

  userData.payload["code"] = code;
  userData.payload["phone"] = phone;

  return userData;
}

function formatRoleList(roleList) {
  let roles = roleList.payload.role;

  _.map(roles, (role) => {
    role["value"] = role["roleId"];
    role["text"] = role["roleName"];
    role["roleid"] = role["roleId"];
    role["rolename"] = role["roleName"];
    delete role["roleId"];
    delete role["roleName"];
  });

  return roles;
}

function formatClientList(clientList) {
  let clients = clientList.payload.client;

  _.map(clients, (client) => {
    client["value"] = client["clientId"];
    client["text"] = client["clientName"];
    client["clientid"] = client["clientId"];
    client["clientname"] = client["clientName"];
    delete client["clientId"];
    delete client["clientName"];
    delete client["managed"];
  });

  return clients;
}

function formatLocationList(locationList) {
  let locations = locationList.payload.client;

  _.map(locations, (location) => {
    location["value"] = location["locationId"];
    if (location["location"]["addressLine2"] === "") {
      location["text"] =
        location["location"]["addressLine1"] +
        " " +
        location["location"]["city"] +
        " " +
        location["location"]["state"] +
        "," +
        location["location"]["country"] +
        " " +
        location["location"]["zipCode"];
    } else {
      location["text"] =
        location["location"]["addressLine1"] +
        " " +
        location["location"]["addressLine2"] +
        " " +
        location["location"]["city"] +
        " " +
        location["location"]["state"] +
        "," +
        location["location"]["country"] +
        " " +
        location["location"]["zipCode"];
    }
    delete location["locationId"];
    delete location["location"];
  });

  return locations;
}

function formatChangeRoleList(roleList) {
  let roles = roleList.payload.role;

  _.map(roles, (role) => {
    role["value"] = role["roleId"];
    role["text"] = role["roleName"];
    role["roleid"] = role["roleId"];
    role["rolename"] = role["roleName"];
    delete role["roleId"];
    delete role["roleName"];
  });

  return roles;
}
