/**
 * @Desc Wrapper for API calls get & post call rather than directly exposing axios each time.
 */

import {
    http
} from './apiInterceptor';
import {
    GlobalVariable
} from '../_constants/url.constants';
import axios from 'axios';
/*  returning response and error as an object in order to get
    error as a server response not string response.
    always reject promise error as Promise.reject(error) in order
    to catch it in a catch block rather than getting it a response.
 */
export const requestHandler = {
    getService,
    postService,
    fileUploaderPostService,
    postFullService,
    createCustomReportPostService,
    getsupplyLevelService,
    getDeviceStatusService,
    getDeviceColorProportionService,
    getServiceStatusService,
    getMonthlyPageUsageByClientService,
    getMonthlyPageUsageByLocationService
};

/**
 * @Desc Wrapper for get requests.
 */
async function getService(url) {
    console.log("inside get method")

    return await http
        .get(url, {
            timeout: GlobalVariable.getServiceTimeout
        })
        .then(response => ({
            response: response.data,

        }))
        .catch(error => {
            console.log('error in get: ', error);
            return Promise.reject(error)
        });

}

/**
 * @Desc Wrapper for post requests.
 */
async function postService(url, request) {
    console.log("inside post method")
    return await http
        .post(url, request, {
            timeout: GlobalVariable.postServiceTimeout
        })
        .then(response => ({
            response: response.data
        }))
        .catch(error => {
            console.log('error in post: ', error);
            return Promise.reject(error);
        });
}

/**
 * @Desc Wrapper for post requests.
 */
async function postFullService(url, request) {
    return await http
        .post(url, request)
        .then(response => ({
            response: response.data
        }))
        .catch(error => {
            console.log('error in post: ', error);
            return Promise.reject(error);
        });
}

/**
 * @Desc Wrapper for post requests.
 */
async function fileUploaderPostService(url, request) {
    return await http
        .post(url, request, {
            timeout: GlobalVariable.fileUploaderPostServiceTimeout
        })
        .then(response => ({
            response: response.data
        }))
        .catch(error => {
            // console.log('error in post: ', error);
            return Promise.reject(error);
        });
}



const authorization = GlobalVariable.pentahoVariables.authorization
const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Basic ' + authorization,
    'Accept': 'application/json',
}


async function createCustomReportPostService(url, request) {
    return await axios.post(url, request, {
        headers: headers
    }).then(response => ({
        response: response
    })).catch(error => {
        console.log('error in post: ', error);
        return Promise.reject(error);
    });
}

async function getsupplyLevelService(url) {
    return await axios.get(url, {
            headers: headers
        })
        .then(response => (

            {
                response: response,
            }))
        .catch(error => {
            console.log('error in get: ', error);
            return Promise.reject(error)
        });

}
async function getDeviceStatusService(url) {
    return await axios.get(url, {
            headers: headers
        })
        .then(response => (

            {
                response: response,
            }))
        .catch(error => {
            console.log('error in get: ', error);
            return Promise.reject(error)
        });

}
async function getDeviceColorProportionService(url) {
    return await axios.get(url, {
            headers: headers
        })
        .then(response => (


            {
                response: response,

            }))
        .catch(error => {
            console.log('error in get: ', error);
            return Promise.reject(error)
        });

}
async function getServiceStatusService(url) {
    return await axios.get(url, {
            headers: headers
        })
        .then(response => (

            {
                response: response,
            }))
        .catch(error => {
            console.log('error  service in get: ', error);
            return Promise.reject(error)
        });

}
async function getMonthlyPageUsageByClientService(url) {
    return await axios.get(url, {
            headers: headers
        })
        .then(response => (

            {
                response: response,
            }))
        .catch(error => {
            console.log('error in get: ', error);
            return Promise.reject(error)
        });

}
async function getMonthlyPageUsageByLocationService(url) {
    return await axios.get(url, {
            headers: headers
        })
        .then(response => (

            {
                response: response,
            }))
        .catch(error => {
            console.log('error in get: ', error);
            return Promise.reject(error)
        });

}