
import 'react-app-polyfill/ie11';
import 'babel-polyfill';
import 'core-js/fn/array/find';
import 'core-js/fn/array/includes';

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/App";
import { Provider } from "react-redux";
import { store } from "./_helpers/store";
// import * as serviceWorker from "./serviceWorker";
import "semantic-ui-css/semantic.min.css";
// import Offline from 'offline-js';
// import './offline/offline.scss';
import { Detector } from "react-detect-offline";
import { toaster, ToasterView } from "./_common/toaster/toaster";
import "react-toastify/dist/ReactToastify.css";

// Offline.options = {
//     // Should we check the connection status immediately on page load.
//     checkOnLoad: true,

//     // Should we monitor AJAX requests to help decide if we have a connection.
//     interceptRequests: true,

//     // Should we automatically retest periodically when the connection is down (set
//     // to false to disable).
//     reconnect: {
//         // How many seconds should we wait before rechecking.
//         initialDelay: 3,

//         // How long should we wait between retries.
//         delay: (10)
//     },

//     // Should we store and attempt to remake requests which fail while the
//     // connection is down.
//     requests: true,

//     // Should we show a snake game while the connection is down to keep the user
//     // entertained? It's not included in the normal build, you should bring in
//     // js/snake.js in addition to offline.min.js.
//     game: false
// }
/**
 * @description
 */

const RenderApp = (
  <Provider store={store}>
    <React.Fragment>
      <Detector
        render={({ online }) => {
          if (online) {
            return toaster.notifyInternetSuccess();
          } 
          else {
            return toaster.notifyInternetFail();
          }
        }}
      />
      <ToasterView />
      <App />
    </React.Fragment>
  </Provider>
);

ReactDOM.render(RenderApp, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls. Learn
// more about service workers: http://bit.ly/CRA - PWA
// serviceWorker.unregister();
