/**
 * @Desc Saga to make all API calls related to the client management view.
 */

import { call, put, takeLatest } from "redux-saga/effects";
import { clientConstants } from "../_constants/client.constant";

import { clientApi } from "../_apiCall/client.api";

import { request } from "../_helpers/request";
import { toaster } from "../_common/toaster/toaster";
import { exceptionHandlerService } from "../_helpers/exceptionHandler";

export function* clientSaga() {
  yield takeLatest(
    clientConstants.GET_CLIENT_LOCATION_LIST,
    getClientLocationList
  );
  yield takeLatest(clientConstants.GET_CLIENT_DETAILS, getClientDetails);
  yield takeLatest(clientConstants.GET_CM_CLIENT_LIST, getCMClientList);
  yield takeLatest(clientConstants.GET_CM_LOCATION_LIST, getCMLocationList);
  yield takeLatest(
    clientConstants.UPDATE_CM_CONTRACT_INFO,
    updateClientContractInfo
  );
  yield takeLatest(clientConstants.UPLOAD_CLIENT_LOGO_TEMP, uploadClientLogo);
}

/**
 * @Desc Wrapper for all saga function call with the common functionality being defined here.
 */
function* sagaFunc(api, action, failureConstant, successConstant, flag, msg) {
  yield put(request.isFetching(clientConstants.REQUEST));
  try {
    const { response, error } =
      flag === true ? yield call(api, action.payload) : yield call(api);
    if (response !== undefined) {
      if (response.success === true) {
        yield put(request.success(successConstant, response));
      } else if (response.success === false) {
        toaster.notifyError(msg);
        yield put(request.failure(failureConstant, msg));
      }
    } else if (error) {
      exceptionHandlerService.exceptionService(error, msg);
      yield put(request.failure(failureConstant, error));
    }
  } catch (error) {
    exceptionHandlerService.exceptionService(error, msg);
    yield put(request.failure(failureConstant, error.toString()));
  }
}

function* getClientLocationList(action) {
  const api = clientApi.getClientLocationList;
  const failureConstant = clientConstants.GET_CLIENT_LOCATION_LIST_FAILURE;
  const successConstant = clientConstants.GET_CLIENT_LOCATION_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get clients list. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getClientDetails(action) {
  const api = clientApi.getClientDetails;
  const failureConstant = clientConstants.GET_CLIENT_DETAILS_FAILURE;
  const successConstant = clientConstants.GET_CLIENT_DETAILS_SUCCESS;
  const flag = true;
  const msg = "unable to get clients details. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getCMClientList(action) {
  const api = clientApi.getCMClientList;
  const failureConstant = clientConstants.GET_CM_CLIENT_LIST_FAILURE;
  const successConstant = clientConstants.GET_CM_CLIENT_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get clients list. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* getCMLocationList(action) {
  const api = clientApi.getCMLocationList;
  const failureConstant = clientConstants.GET_CM_LOCATION_LIST_FAILURE;
  const successConstant = clientConstants.GET_CM_LOCATION_LIST_SUCCESS;
  const flag = true;
  const msg = "unable to get location list. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* updateClientContractInfo(action) {
  const api = clientApi.updateCMContractInfo;
  const failureConstant = clientConstants.UPDATE_CM_CONTRACT_INFO_FAILURE;
  const successConstant = clientConstants.UPDATE_CM_CONTRACT_INFO_SUCCESS;
  const flag = true;
  const msg =
    "unable to update client contract info. Please try after some time.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}

function* uploadClientLogo(action) {
  const api = clientApi.uploadClientLogoApi;
  const failureConstant = clientConstants.UPLOAD_CLIENT_LOGO_TEMP_FAILURE;
  const successConstant = clientConstants.UPLOAD_CLIENT_LOGO_TEMP_SUCCESS;
  const flag = true;
  const msg = "unable to upload client logo. Please try again.";
  yield call(
    sagaFunc,
    api,
    action,
    failureConstant,
    successConstant,
    flag,
    msg
  );
}
