/**
 * @Desc // import axios from "axios";
 */
import {
  assetResources
} from "../_resources/asset.resources";
import {
  requestHandler
} from "../_helpers/requestHandler";
import {
  assetActions
} from "../_actions/asset.action";

export const assetApi = {
  getAssetDeviceList,
  getAssetCategoryList,
  getAssetCategoryInfo,
  getAssetTagList,
  createAssetApi,
  getViewAssetInfo,
  updateAssetApi,
};

function getAssetDeviceList(data) {
  let url = assetResources.getAssetDeviceListUrl().url;
  console.log("url aset", url);

  return requestHandler.postService(url, data);
}

function getAssetCategoryList(clientId) {
  let url = assetResources.getAssetCategoryListUrl(clientId).url;

  return requestHandler.getService(url);
}

function getAssetCategoryInfo(categoryId) {
  let url = assetResources.getAssetCategoryInfoUrl(categoryId).url;
  return requestHandler.getService(url);
}

function getAssetTagList() {
  let url = assetResources.getAssetTagListUrl().url;
  return requestHandler.getService(url);
}

function createAssetApi(data) {
  let url = assetResources.getCreateAssetUrl().url;
  return requestHandler.postService(url, data);
}

function getViewAssetInfo(assetId) {
  let url = assetResources.getAssetInfoUrl(assetId).url;
  return requestHandler.getService(url);
}

function updateAssetApi(data) {
  let url = assetResources.getUpdateAssetUrl().url;
  return requestHandler.postService(url, data);
}