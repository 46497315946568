/**
 * @Desc Wrapper for asset management data manipulation & pasisng it to the store.
 */

import { assetConstants } from "../_constants/asset.constant";
//  import _ from "lodash";

let initialState = {
  assetDeviceList: [],
  assetCategoryList: [],
  assetCategoryInfo: [],
  assetTagList: [],
  viewAssetInfo: [],
};

export function assetReducer(state = initialState, action) {
  switch (action.type) {
    case assetConstants.GET_ASSET_DEVICE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        assetDeviceList: action.payload.payload,
      };
    case assetConstants.GET_ASSET_DEVICE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case assetConstants.UNMOUNT_ASSET_CATEGORY_LIST:
      return {
        ...state,
        loading: false,
        assetCategoryList: undefined,
        assetCategoryInfo: undefined,
      };
    case assetConstants.GET_ASSET_CATEGORY_LIST_SUCCESS:
      console.log("categoryList according to client", action.payload);
      return {
        ...state,
        loading: false,
        assetCategoryList: action.payload.payload.categoryList,
      };
    case assetConstants.GET_ASSET_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case assetConstants.GET_ASSET_CATEGORY_INFO_SUCCESS:
      console.log("from asset", action.payload);
      return {
        ...state,
        loading: false,
        assetCategoryInfo: action.payload.payload.categoryInfo[0],
      };
    case assetConstants.GET_ASSET_CATEGORY_INFO_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case assetConstants.GET_ASSET_TAG_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        assetTagList: action.payload.payload,
      };
    case assetConstants.GET_ASSET_TAG_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case assetConstants.CREATE_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
        createAssetRes: action.payload,
      };
    case assetConstants.CREATE_ASSET_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case assetConstants.GET_VIEW_ASSET_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        viewAssetInfo: action.payload.payload,
      };
    case assetConstants.GET_VIEW_ASSET_INFO_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case assetConstants.UPDATE_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
        updateAssetRes: action.payload,
      };
    case assetConstants.UPDATE_ASSET_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
