/**
 * @Desc Wrapper for storing the API urls for dashboard.
 */

import {
  GlobalVariable
} from "../_constants/url.constants";
import {
  GlobalEnvironment
} from "../_constants/environment";
const baseUrl = GlobalVariable.baseUrl;
const projectName = GlobalVariable.projectName;
const ssl = GlobalVariable.ssl;
const pentahoUserId = GlobalVariable.pentahoVariables.userId;
const pentahoPassword = GlobalVariable.pentahoVariables.password;
const pentahoPath = GlobalVariable.pentahoVariables.path;
const inScope = "";
const isManaged = "";

// export let GlobalVariable;

export const DashboardResources = {
  getSupplyLevelStatusListUrl,
  getDashboardClientListUrl,
  getDashboardLocationListUrl,
  getLastUpdatedDateUrl,
  getDeviceStatusListUrl,
  getDeviceColorProportionListUrl,
  getServiceStatusListUrl,
  getMonthlyPageUsageByClientListUrl,
  getMonthlyPageUsageByLocationListUrl,
  getGreenReportDetailsUrl
};



function getSupplyLevelStatusListUrl(data) {
  // console.log("")

  return {
    url: ssl + "://" + baseUrl + "/" + `/pentaho/plugin/cda/api/doQuery?paramclientParameter=${data.client}&paramlocationParameter=${data.location}&path=${pentahoPath}SupplyLevel.cda&dataAccessId=supply_level_above_50_datasource&outputIndexId=1&pageSize=0&pageStart=0&sortBy=&paramsearchBox=&userid=${pentahoUserId}&password=${pentahoPassword}` + inScope + isManaged
  }
}

function getDeviceStatusListUrl(data) {
  return {
    url: ssl + "://" + baseUrl + "/" + `/pentaho/plugin/cda/api/doQuery?paramclientParameter=${data.client}&paramlocationParameter=${data.location}&path=${pentahoPath}DeviceStatus.cda&dataAccessId=deviceStatusDataSource&outputIndexId=1&pageSize=0&pageStart=0&sortBy=&paramsearchBox=&userid=${pentahoUserId}&password=${pentahoPassword}` + inScope + isManaged
  }
}

function getDeviceColorProportionListUrl(data) {

  return {
    url: ssl + "://" + baseUrl + "/" + `/pentaho/plugin/cda/api/doQuery?paramclientParameter=${data.client}&paramlocationParameter=${data.location}&path=${pentahoPath}ColorCapableDeviceProportion.cda&dataAccessId=monoColorDataSource&outputIndexId=1&pageSize=0&pageStart=0&sortBy=&paramsearchBox=&userid=${pentahoUserId}&password=${pentahoPassword}` + inScope + isManaged

  }
}

function getServiceStatusListUrl(data) {
  return {
    url: ssl + "://" + baseUrl + "/" + `/pentaho/plugin/cda/api/doQuery?paramclientParameter=${data.client}&paramlocationParameter=${data.location}&path=${pentahoPath}ServiceStatus.cda&dataAccessId=ServiceStatusDataSource&outputIndexId=1&pageSize=0&pageStart=0&sortBy=&paramsearchBox=&userid=${pentahoUserId}&password=${pentahoPassword}` + inScope + isManaged
  }
}

function getMonthlyPageUsageByClientListUrl(data) {
  return {
    url: ssl + "://" + baseUrl + "/" + `/pentaho/plugin/cda/api/doQuery?paramclientParameter=${data.client}&paramlocationParameter=[Location].[All Locations]&paramyearParameter=${data.year}&parammonthParameter=${data.month}&path=${pentahoPath}pageUsageClientLevel.cda&dataAccessId=printUsageDataSource&outputIndexId=1&pageSize=0&pageStart=0&sortBy=&paramsearchBox=&userid=${pentahoUserId}&password=${pentahoPassword}` + inScope + isManaged
  }
}

function getMonthlyPageUsageByLocationListUrl(data) {
  return {
    url: ssl + "://" + baseUrl + "/" + `/pentaho/plugin/cda/api/doQuery?paramclientParameter=${data.client}&paramlocationParameter=${data.location}&paramyearParameter=${data.year}&parammonthParameter=${data.month}&path=${pentahoPath}pageUsage.cda&dataAccessId=printUsageDataSource&outputIndexId=1&pageSize=0&pageStart=0&sortBy=&paramsearchBox=&userid=${pentahoUserId}&password=${pentahoPassword}` + inScope + isManaged
  }
}

function getDashboardClientListUrl() {
  return {
    url: ssl + "://" + baseUrl + "/" + projectName + "/kbt/bridge/api/v1/clients"
  };
}

function getDashboardLocationListUrl() {
  return {
    url: ssl + "://" + baseUrl + "/" + projectName + "/kbt/bridge/api/v1/locations"
  };
}

function getLastUpdatedDateUrl() {
  return {
    url: ssl + "://" + baseUrl + "/" + projectName + "/kbt/bridge/api/v1/last/polled"
  }
}

function getGreenReportDetailsUrl() {
  console.log("greenreport resource url");
  return {
    url: ssl +
      "://" +
      baseUrl +
      "/" +
      projectName +
      "/kbt/bridge/api/v2/greenreport/get"
    // url: "https://run.mocky.io/v3/3fc29ebc-ce0e-4dbd-82aa-d7614483de2e"
    // url: ssl + "://" + baseUrl + "/" + projectName + "/kbt/bridge/api/v1/clients"
  };
}